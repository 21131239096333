import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Search from '../../../assets/Iconsearch.svg';
import backArrow from '../../../assets/backArrow.svg'
import { Badge } from 'reactstrap';
import { useSelector } from 'react-redux';
import instance from '../../../helpers/axiosInstance';

const CallStatus = ({ item, show, handleClose, handleSave }) => {
    const [enableSaveBtn, setEnableSaveBtn] = useState(false);
    const [enableDecline, setEnableDecline] = useState(false);
    const [enableOther, setEnableOther] = useState(false);
    const [enableDeclineOther, setEnableDeclineOther] = useState(false);
    const [showAdvancedToggle, setShowAdvancedToggle] = useState(false);
    const [quickAccessplaceHolders, setQuickAccessplaceHolders] = useState('');
    const [nextQuickAccessModal, setNextQuickAccessModal] = useState(false)

    const [callstatusData, setCallStatusData] = useState({ status: "", other: "" });
    const [callData, setCallData] = useState({ fromNumber: item?.customer_phone, toNumber: "9514315599", order_id: item.order_id });
    const [calcenStatus, setCalcenStatus] = useState({ order_ids: item.order_id ? item?.order_id : item?.map(i => i.order_id).toString() || '', cancel_reason: "", cancel_comments: "" });
    const [returnStatus, setReturnStatus] = useState({ orderId: item.id ? item?.id : item?.map(i => i.id).toString() || '', returnReason: "", cancel_comments: "" });
    const [showOther, setShowOther] = useState(false);

    const [formData, setFormData] = useState({
        quickSelect: '',
        quickInput: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })

        if (name === 'quickSelect') {
            quickAccessChange(value)
        }

    }
    const userClaim = useSelector((state) => state.user);
    const [werehouseList, setWerehouseList] = useState([]);
    const [selectedItem, setSelectedItem] = useState("");
    const [selectedServiceList, setSelectedServiceList] = useState([]);
    const [selectedService, setSelectedService] = useState("");
    useEffect(() => {
        if (userClaim.token !== "") {
            getWerehouseList();
        }
    }, [userClaim]);
    const getWerehouseList = () => {
        try {
            instance.get("app/location").then((res) => {

                if (res.status === 200 && Array.isArray(res.data?.data)) {
                    setWerehouseList(res.data?.data || []);
                }

            }).catch(e => {
                console.error(e);
                setWerehouseList([]);
            })
        }
        catch (e) {
            console.error(e);
            setWerehouseList([]);
        }
    }

    const changeStatus = (e) => {
        console.log(e.target.value);
        var value = e.target.value;
        setCallStatusData({ other: "", status: value })
        if (value === 'accepted') {
            setEnableSaveBtn(true)
            setEnableDecline(false)
            setEnableOther(false)
            setEnableDeclineOther(false)
        }
        if (value === 'declined') {
            setEnableDecline(true)
            setEnableSaveBtn(true)
            setEnableOther(false);
            setCallStatusData({ other: "Order wrongly placed", status: value })
        }

        if (value === 'other') {
            setEnableOther(true)
            setEnableDecline(false)
            setEnableSaveBtn(false)
        }

        if (value === 'select') {
            setEnableSaveBtn(false);
            setEnableDecline(false)
            setEnableOther(false)
            setEnableDeclineOther(false)
        }
    }

    const changeStatusDecline = (e) => {
        var value = e.target.value;
        setCallStatusData({ ...callstatusData, other: value })
        if (value === 'others') {
            setEnableOther(true)
            setEnableDecline(true)
            setEnableDeclineOther(true)
            setEnableSaveBtn(false)
        }
        else {
            setEnableOther(false)
            setEnableDeclineOther(false)
            setEnableSaveBtn(true)
        }
    }

    const inputChange = (e) => {
        var value = e.target.value;
        console.log(value.length)
        setCallStatusData({ ...callstatusData, other: value })
        if (value.length > 0) {
            setEnableSaveBtn(true)
        }
        else {
            setEnableSaveBtn(false)
        }
    }

    const cancelUpdate = (e) => {
        if (e.target.value === "Others") {
            setShowOther(true);
        }
        else {
            setShowOther(false);
        }
        setCalcenStatus({ ...calcenStatus, cancel_reason: e.target.value })
    }

    const returnUpdate = (e) => {
        if (e.target.value === "Others") {
            setShowOther(true);
        }
        else {
            setShowOther(false);
        }
        setReturnStatus({ ...returnStatus, returnReason: e.target.value })
    }


    const quickAccessChange = (value) => {

        if (value === '') {
            setQuickAccessplaceHolders('Search')
        }

        if (value === 'orderId') {
            setQuickAccessplaceHolders('Search for label')
        }

        if (value === 'email') {
            setQuickAccessplaceHolders('Search for email')
        }

        if (value === 'phoneNumber') {
            setQuickAccessplaceHolders('Search for phone number')
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};

        if (!formData.quickSelect) {
            newErrors.quickSelect = "Select the option";
        }

        if (!formData.quickInput) {
            newErrors.quickInput = "Please enter the value";
        }

        if (Object.keys(newErrors).length === 0) {
            console.log('Form data is valid:', formData);
            setNextQuickAccessModal(true)
        } else {
            setErrors(newErrors);
        }
    }

    return (
        <>
            <Modal
                size='sm'
                show={show === 'CallUpdate'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >
                <div className='modal-content modal-lg'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Update Call Status</h5>
                    </div>
                    <div className="modal-body modal-lg">
                        <label className='userLabel'>Call Status</label>
                        <select className='call-status form-select mb-3'
                            onChange={changeStatus}
                        >
                            <option value="select">Select</option>
                            <option value="accepted">Accepted</option>
                            <option value="declined">Declined</option>
                            <option value="other">Other</option>
                        </select>
                        {!enableDecline ? '' :
                            <>
                                <label className='userLabel'>Reason for Decline?</label>
                                <select className='call-status form-select mb-3'
                                    onChange={changeStatusDecline}
                                >
                                    <option value="Order wrongly placed">Order wrongly placed</option>
                                    <option value="Too expensive">Too expensive</option>
                                    <option value="Changed my mind">Changed my mind</option>
                                    <option value="others">Others</option>
                                </select>
                            </>

                        }

                        {!enableOther ? '' : <>
                            <label className='userLabel'>{!enableDeclineOther ? 'Call Description' : 'Describe Others'}</label>
                            <input type='text' maxLength={30} className='form-control max-length' onChange={inputChange} />
                            <span className='userLabel mb-3'>Max 30 characters</span>
                        </>}

                    </div>

                </div>

                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex justify-content-end">
                        <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm btn-primary" onClick={() => handleSave(callstatusData, item)} type="submit" disabled={enableSaveBtn === false ? true : false}>Save</button>

                    </div>

                </div>

            </Modal>

            <Modal
                size='sm'
                show={show === 'Call'}
                onHide={handleClose}
                centered
                scrollable
            >
                <div className='modal-content modal-lg'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Make a Call</h5>
                    </div>
                    <div className="modal-body modal-lg">
                        <label className='userLabel'>Dial</label>
                        <input type='text' className='form-control mb-3' value={callData.fromNumber} onChange={(e) => setCallData({ ...callData, fromNumber: e.target.value })} defaultValue={item?.customer_phone} />
                        <hr style={{ color: '#EAECF0', opacity: '1' }} />
                        <Link to={''} className='mb-3 userLabel' onClick={() => { setShowAdvancedToggle(!showAdvancedToggle) }} style={{ color: '#0086C9' }}>
                            <i className={!showAdvancedToggle ? 'bi bi-plus-circle' : 'bi bi-dash-circle'}></i>&nbsp;<span style={{ textDecoration: 'underline' }}>Advanced</span>
                        </Link>
                        <br />
                        {!showAdvancedToggle ? '' :
                            <>
                                <label className='userLabel'>Through</label>
                                <select className='call-status form-select mb-3'

                                >
                                    <option value="cod">COD Verify Group</option>
                                </select>

                                <label className='userLabel'>From</label>
                                <select className="call-status form-select mb-3" onChange={(e) => setCallData({ ...callData, toNumber: e.target.value })}>
                                    <option value="9047315599">9047315599</option>
                                    <option value="9159815599">9159815599</option>
                                    <option value="9655175599">9655175599</option>
                                    <option value="9626635599">9626635599</option>
                                    <option selected value="9514315599">9514315599</option>
                                </select>

                            </>

                        }

                    </div>

                </div>

                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex justify-content-end">
                        <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm btn-primary" onClick={() => handleSave(callData, item)} type="submit" >Call Now</button>

                    </div>

                </div>

            </Modal>
            <Modal
                size='md'
                show={show === 'quickAccess'}
                onHide={handleClose}
                centered
                scrollable
            >
                <form onSubmit={handleSubmit}>
                    <div className='modal-content modal-md'>
                        <div className="modal-header border-bottom-0 justify-content-between">
                            <h5 className="modal-title">Quick Access</h5>
                            {nextQuickAccessModal === true ? (<>
                                <Link to={''} onClick={handleClose}><i className='bi bi-x-lg'></i></Link>
                            </>) : ''}
                        </div>
                        <div className="modal-body modal-md">
                            {nextQuickAccessModal === true ? (<>
                                <Badge className='blueLight d-flex'>
                                    <span>Order ID: 1548</span>&nbsp;&nbsp;
                                </Badge>

                                <div className='mt-3 mb-5'>
                                    <Link to={''} className="quickAccess-btn"><i className="bi bi-box-arrow-up-right" /> Show Label</Link>
                                    <Link to={''} className="quickAccess-btn"><i className="bi bi-box-arrow-up-right" /> Show tracking</Link>
                                    <Link to={''} className="quickAccess-btn"><i className="bi bi-box-arrow-up-right" /> Show order Details</Link>
                                    <Link to={''} className="quickAccess-btn"><i className="bi bi-box-arrow-up-right" /> Show Customer Details</Link>
                                </div>

                                <button className="btn btn-sm cancel-btn" onClick={() => { setNextQuickAccessModal(false) }} type="button"><img src={backArrow} /> Go Back</button> &nbsp;&nbsp;

                            </>) : (<>

                                <label className='userLabel mb-4'>Labels help organize projects.</label>

                                <div className='row mb-3'>
                                    <div className='col-md-3'>
                                        <select className='call-status form-select' name='quickSelect' onChange={handleChange} defaultValue={formData.quickSelect}>
                                            <option value="">Select</option>
                                            <option value="orderId">Order ID</option>
                                            <option value="email">Email</option>
                                            <option value="phoneNumber">Phone number</option>
                                        </select>
                                        {errors.quickSelect && <p className="error">{errors.quickSelect}</p>}
                                    </div>
                                    <div className='col-md-9'>
                                        <div className="form-group">
                                            <div style={{ position: 'relative' }}>
                                                <span className="icon"><img alt='' src={Search} /></span>
                                            </div>
                                            <input
                                                type="text"
                                                id="filter-text-box"
                                                className='form-control inputWithIcon'
                                                placeholder={quickAccessplaceHolders}
                                                name='quickInput'
                                                onChange={handleChange}
                                                value={formData.quickInput}

                                            />
                                            {errors.quickInput && <p className="error">{errors.quickInput}</p>}
                                        </div>
                                    </div>
                                </div>
                                {/* <label className='userLabel mb-3'>Filters</label>
                        <div className='d-flex'>
                            <Badge className='blueLight d-flex'>
                                <span>In Progress</span>&nbsp;&nbsp;
                                <Link to={''}><i className='bi bi-x-lg'></i></Link>
                            </Badge>
                            <Badge className='Indigo d-flex'>
                                <span>Design</span>&nbsp;&nbsp;
                                <Link to={''}><i className='bi bi-x-lg'></i></Link>
                            </Badge>
                            <Badge className='Pink d-flex'>
                                <span>Web</span>&nbsp;&nbsp;
                                <Link to={''}><i className='bi bi-x-lg'></i></Link>
                            </Badge>
                        </div> */}

                            </>)}


                        </div>

                    </div>

                    <div className="modal-footer">

                        <div className="col-12 mb-4 d-flex">
                            {nextQuickAccessModal === true ? (<></>) : (<>
                                <button className="btn btn-sm w-100 cancel-btn" onClick={handleClose} type="button">Clear All</button> &nbsp;&nbsp;
                                <button className="btn btn-sm w-100 btn-primary" type="submit" >Apply</button>
                            </>)}


                        </div>

                    </div>
                </form>

            </Modal>

            <Modal
                size='sm'
                show={show === 'courierPartner'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >

                <div className='modal-content modal-md'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Assign Courier Partner</h5>
                    </div>
                    <div className="modal-body modal-md">


                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='userLabel'>Select Warehouse</label>
                                <select className='call-status form-select mb-3' onChange={
                                    (e) => {
                                        let val = JSON.parse(e.target.value);
                                        setSelectedItem(val)
                                        setSelectedServiceList(val.shipping_carrier_name)
                                    }}>
                                    <option value="">Select</option>
                                    {werehouseList.map((i, ind) => <option key={ind} value={JSON.stringify(i)}>{i.name}</option>)}

                                </select>
                            </div>
                            {selectedItem !== "" && selectedServiceList.length > 0 && <div className='col-md-12'>
                                <label className='userLabel'>Select Courier</label>
                                <select className='call-status form-select mb-3' onChange={
                                    (e) => {
                                        setSelectedService(e.target.value)
                                    }}>
                                    <option value="">Select</option>
                                    {selectedServiceList.map((i, ind) => <option key={ind} value={i}>{i.toUpperCase()}</option>)}

                                </select>
                            </div>}
                        </div>
                    </div>
                </div>

                <div className="modal-footer">

                    <div className="col md-12 mb-4">
                        <div className='row'>
                            <div className='col-md-6'>
                                <button className="btn btn-sm w-100 cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                            </div>
                            <div className='col-md-6'>
                                <button className="btn btn-sm w-100 btn-primary" disabled={selectedItem !== "" && selectedService !== "" ? false : true} type="submit" onClick={() => handleSave(item, selectedItem, selectedService)} >Assign</button>
                            </div>
                        </div>
                    </div>

                </div>

            </Modal>

            <Modal
                size='sm'
                show={show === 'scanawb'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >

                <div className='modal-content modal-md'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Scan/Paste AWBs to Manifest</h5>
                    </div>
                    <div className="modal-body modal-md">


                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='userLabel'>Scan/Paste AWBs to Manifest</label>
                                <textarea className='form-control' style={{ height: '84px', width: '350px' }}></textarea>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex">
                        <button className="btn btn-sm w-100 cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm w-100 btn-primary" type="submit" >Download Manifest</button>

                    </div>

                </div>

            </Modal>

            <Modal
                size='sm'
                show={show === 'cancelPopup'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >

                <div className='modal-content modal-md'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Cancellation Reasons</h5>
                    </div>
                    <div className="modal-body modal-md">


                        <div className='row'>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck1" value='Wrong contact Information' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck1">Wrong contact Information</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck2" value='High Price' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck2">High Price</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck3" value='COD to Prepaid Conversion' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck3">COD to Prepaid Conversion</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck4" value='Unserviceable pin code' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck4">Unserviceable pin code</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck5" value='Ordered twice' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck5">Ordered twice</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck6" value='Merge Orders' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck6">Merge Orders</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck7" value='No reason: Customer wants to cancel' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck7">No reason: Customer wants to cancel</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={cancelUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck8" value='Others' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck8">Others</label>
                            </div>
                            {showOther && <div className='col-md-12 mb-2'>
                                <textarea className='form-control' onChange={e => { setCalcenStatus({ ...calcenStatus, cancel_comments: e.target.value }) }} style={{ height: '84px', width: '350px' }}></textarea>
                            </div>}
                        </div>
                    </div>

                </div>

                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex">
                        <button className="btn btn-sm w-100 cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm w-100 btn-primary" disabled={calcenStatus.cancel_reason === "" || (calcenStatus.cancel_reason === "Others" && calcenStatus.cancel_comments === "")} type="submit" onClick={() => handleSave(calcenStatus, item)}  >Submit</button>
                    </div>

                </div>

            </Modal>

            <Modal
                size='sm'
                show={show === 'returnReason'}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
                className='return-request-modal'
            >

                <div className='modal-content modal-md'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Return Reasons</h5>
                    </div>
                    <div className="modal-body modal-md">


                        <div className='row'>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck1" value='Damaged Product' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck1">Damaged Product</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck2" value='Received Wrong Product' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck2">Received Wrong Product</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck3" value='Late Delivery' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck3">Late Delivery</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck4" value='Changed Mind' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck4">Changed Mind</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck5" value='Did Not Meet Expectations' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck5">Did Not Meet Expectations</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck6" value='Item Does Not Fit' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck6">Item Does Not Fit</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck7" value='Product No Longer required' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck7">Product No Longer required</label>
                            </div>
                            <div className='col-md-12 mb-2'>
                                <input type="radio" onChange={returnUpdate} name='cancelReason' className="form-check-input me-1" id="validationFormCheck8" value='Others' />
                                <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck8">Others</label>
                            </div>
                            {showOther && <div className='col-md-12 mb-2'>
                                <textarea className='form-control' onChange={e => { setReturnStatus({ ...returnStatus, cancel_comments: e.target.value }) }} style={{ height: '84px', width: '350px' }}></textarea>
                            </div>}
                        </div>
                    </div>

                </div>

                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex">
                        <button className="btn btn-sm w-100 cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                        <button className="btn btn-sm w-100 btn-primary" disabled={returnStatus.returnReason === "" || (returnStatus.returnReason === "Others" && returnStatus.cancel_comments === "")} type="submit" onClick={() => handleSave(returnStatus, item)}  >Submit</button>
                    </div>

                </div>

            </Modal>
        </>
    )
}

export default CallStatus;