import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import menuBtn from '../../../../assets/menuBtn.svg'
import { Link } from 'react-router-dom';
import EditCustomerContact from '../../../global/popupModal/editCustomerContact';
import customer from '../../../../assets/customerIcon.svg'

const CustomerInformations = () => {
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })

    const showModal = (popupName, data, callBackMethod) => {
        setPopupData({ popupName, data, callBackMethod })
    }
    const closeModal = () => {
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }

    return (
        <>
            <div className="card">
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-md-10 d-flex'>
                            <span className='card-icons'>
                                <img src={customer} alt='customer' />
                            </span>
                            <h5 className='orderDetailTitle'>Customer</h5>
                        </div>

                        <div className='col-md-2 no-padding'>
                            <div className="d-flex justify-content-end space-between">
                                <Dropdown>
                                    <Dropdown.Toggle className="menu-btn" variant="" id="dropdown-basic">
                                        <img src={menuBtn} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => showModal('editShipingAddress', 'editShipingAddress', '')}>Edit Shipping Address</Dropdown.Item>
                                        {/* <Dropdown.Item href={''}>Edit Billing Address</Dropdown.Item> */}
                                        <Dropdown.Item onClick={() => showModal('editInfoAddress', 'editInfoAddress', '')}>Edit Contact Information</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card-body customer'>
                    <h5 className='deatils-header'>Contact Information</h5>
                    <span className='customer-content'>name@domain.com</span>
                    <div className='row mt-3'>
                        <div className='col-md-9'>
                            <h5 className='deatils-header'>Default Address</h5>
                            <span className='customer-content'>2972 Westheimer Rd. Santa Ana, Illinois 85486 </span>
                        </div>
                        <div className='col-md-3'>
                            <Link to={''} className='customer-link'>Edit</Link>
                        </div>
                    </div>
                </div>
            </div>
            {popupData.popupName !== "" &&
                <EditCustomerContact
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closeModal}
                />
            }

        </>
    )
}

export default CustomerInformations
