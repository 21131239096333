import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import { useSelector } from 'react-redux';
import instance from '../../../helpers/axiosInstance';
import { Link } from 'react-router-dom';
import Search from '../../../assets/Iconsearch.svg';
import Calandar from '../../../assets/Icondate.svg';
// import AllFilter from '../../../assets/Iconall-filter.svg';
import DataGridPagination from '../../global/dataGridPagination';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import FilterMenu from "../../global/filterComponent/FilterMenu";
import CallStatus from '../../global/popupModal/CallStatus';
// import { filterOptions } from './filterObj';
import './customers.scss';
import { checkAccess, commonServiceMethod } from '../../../helpers/helper';
import Loader from '../../global/loader';
import SuccessToast from '../../customToast/SuccessToast';
import { Slide } from 'react-toastify';
// import refreshIcon from '../../../assets/dashboard/refreshIcon.png';
import OrderCount from "../../global/filterComponent/customerOrderCount";
import SearchTags from "../../global/filterComponent/customerTags";
import DropDown from '../../../assets/dropDown.svg'

const CustomersBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const dropdownRef = useRef(null);
    const [rowData, setRowData] = useState();
    const [originalData, setOriginalData] = useState({});
    const [selectedObject, setSelectedObject] = useState([]);
    const [open, setOpen] = useState(false);

    // pagination and Filtr variables 
    const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");

    const [callDetails, setCallDetails] = useState('');
    const [callRowID, setCallRowID] = useState('');
    const [loader, setLoader] = useState(false);
    const [columnData, setComlumnData] = useState([]);

    const [isOpenOrder, setIsOpenOrder] = useState(false);
    const [isOpenTags, setIsOpenTags] = useState(false);

    const toggleOrderDropdown = () => {
        setIsOpenOrder(true);
        setIsOpenTags(false);
        // console.log(isOpenOrder);
    };

    const toggleTagsDropdown = () => {
        setIsOpenTags(true);
        setIsOpenOrder(false);
        //  console.log(isOpenTags);
    };

    const columnDefs = [
        {
            headerName: 'Name',
            field: 'first_name',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            width: 200,
            cellClass: "check-box-container",
            cellRenderer: (param) => {
                return (<div>
                    <Link className='grid-link customer-name' to={`/customers/customer-details/${btoa(param.data.id)}`}>{param.value} {param.data.last_name}</Link>

                </div>)
            }
        },
        {
            field: 'email',
            width: 250,
            wrapText: true,
            autoHeight: true,
            headerName: 'Email',
            cellClass: "",
            cellRenderer: (params) => {
                return (<div className={`customer-email`}>
                    {params.value}
                </div>)
            }
        },
        {
            width: 150,
            field: 'phone',
            wrapText: true,
            autoHeight: true,
            headerName: 'Phone',
            cellClass: "d-flex-center",
            cellRenderer(params) {
                return (
                    <span className='customer-phone'>{params.value}</span>
                )
            }
        },
        {
            headerName: 'Address',
            field: 'addresses',
            width: 250,
            cellClass: "d-flex-center",
            wrapText: true,
            autoHeight: true,
            cellRenderer(params) {
                let address = JSON.parse(params.value);

                return (
                    <div className={"customer-address"}>{address[0]?.address1}, {address[0]?.address2}, {address[0]?.city},
                        {address[0]?.province}-{address[0]?.zip} </div>
                )
            }
        },
        {
            width: 120,
            field: 'title',
            wrapText: true,
            autoHeight: true,
            headerName: 'Total Order',
            cellClass: '',
            cellRenderer(param) {
                return (<span className={`total-order ${param.value}`}>{param.value}</span>)
            }
        },
        {
            width: 150,
            field: 'total_spent',
            wrapText: true,
            autoHeight: true,
            headerName: 'Amount Spent',
            cellRenderer(param) {
                return (<span className={`amount-spent ${param.value}`}>Rs. {param.value}</span>)
            }
        },
    ];

    const cancelOrder = (obj, item) => {
        const config = {};
        commonServiceMethod("app/orders/cancel", "post", obj, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={'This order is ' + item.order_name + ' cancelled'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
                closeModal();
            }
        });
    }

    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
            if (!checkAccess(11)) {
                // columnDefs.splice(5, 1);
                setComlumnData([...columnDefs]);
            }
            else
                setComlumnData([...columnDefs]);
        }

        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpenOrder(false);
                setIsOpenTags(false);
            }
        };

        // Attach the event listener
        document.addEventListener('click', handleOutsideClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };


    }, [userClaim]);

    // const onClearFilter = () => {
    //     getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), "");
    // }

    const showModal = (string, value) => {
        setCallDetails(string);
        setCallRowID(value)
    }

    const closeModal = () => {
        setCallDetails('');
    }

    const getData = (
        page = 1,
        // startDate,
        // endDate,
        // searchText,
        // perams = ""
    ) => {
        var rowData = [];
        try {
            instance.get(`app/customer/getcustomer?per_page=${per_page}&page=${page}`).then((res) => {

                if (res?.status === 200 && Array.isArray(res?.data?.customers)) {
                    setRowData(res?.data?.customers || []);
                    setOriginalData(res?.data)
                }

            }).catch(e => {
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        console.log(selectedITem.length > 0)
        // setSelectedObject((selectedObject) => [...selectedObject]);
        selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setIdCount([]));
    }
    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        getData(
            pageNo,
            date.startDate.format('YYYY-MM-DD'),
            date.endDate.format('YYYY-MM-DD'),
            searchText
        )
    }
    // const searchTextChangeEvent = (e) => {
    //     e.preventDefault();
    //     getData(
    //         pageNo,
    //         moment(startDate).format('YYYY-MM-DD'),
    //         moment(endDate).format('YYYY-MM-DD'),
    //         searchText
    //     );
    // }


    const printInvoiceMethod = (obj = []) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        var objdata = {};
        objdata.order_id = obj.map((i) => i.order_id).join();
        commonServiceMethod("app/pritnlabel", "post", objdata, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
            closeExportPopup();
        });
    }
    // const onApplyFilter = (selectionOption, searchTextval = "") => {
    //     console.log(selectionOption)
    //     let url = "";
    //     Object.keys(selectionOption).map(i => {

    //         url += `&${i}=${selectionOption[i]}`
    //     })
    //     getData(
    //         pageNo,
    //         moment(startDate).format('YYYY-MM-DD'),
    //         moment(endDate).format('YYYY-MM-DD'),
    //         searchTextval || searchText,
    //         url
    //     )
    // }
    return (
        <>
            {/* {open ? <Popup selecteObject={selectedObject} closePopup={() => { setOpen(false); gridRef.current.api.deselectAll(); }} /> : null} */}
            <Loader showHide={loader} />
            <div className="parent-div customer-table">
                <div className="topview">
                    <h4 className='fw-600' >Customers</h4>
                </div>
                {/* <div className='quick-buttons'>
                    <button className='syncBtn'> <img src={refreshIcon} className="refreshIcon"></img> Sync Orders</button>
                </div> */}
                {open && <div className="customer-popup popup-containers grid-checkbox-popuop">
                    <div className="popup-body">
                        <div className='row'>
                            <div className='col-md-3 selected-item'>
                                {selectedObject.length} items Seleted
                            </div>
                            <div className='col-md-9 d-flex'>
                                <button type='button' onClick={() => showModal('courierPartner', 'courierPartner')} className='btn btn-sm w-100 asign-courier filter-btn'>Update Inventory</button>
                                <button type='button' onClick={() => printInvoiceMethod([param?.data])} className='btn btn-sm w-100 print-invoice filter-btn'>Add tags</button>
                                <button type='button' onClick={() => showModal('cancelPopup', param?.data, cancelOrder)} className='btn btn-sm w-100 cancel-order filter-btn'>Remove tags</button>
                                <Link to={''} className='close-icon' onClick={() => { setOpen(false); gridRef.current.api.deselectAll(); }} >X</Link>
                            </div>
                        </div>
                    </div>
                </div>}
                {/* <div className='row mb-2 mt-4'>
                    <div className='col-md-6'>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <form onSubmit={searchTextChangeEvent}>
                                    <div style={{ position: 'relative' }}>
                                        <span className="icon"><img alt='' src={Search} /></span>
                                    </div>
                                    <input
                                        type="text"
                                        id="filter-text-box"
                                        placeholder="Search"
                                        className='form-control inputWithIcon'
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </form>
                            </div>

                        </div>

                    </div>
                    <div className='col-md-3 mt-1'>
                        <select>
                            <option>Order Count</option>
                        </select>
                        <select>
                            <option>Tag Filter</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Calandar} /></span>
                            </div>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: startDate,
                                        endDate: endDate,
                                        showDropdowns: true,
                                        locale: {
                                            format: 'MMM DD, YYYY',
                                        },
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, 'days').toDate(),
                                                moment().subtract(1, 'days').toDate(),
                                            ],
                                            'Last 7 Days': [
                                                moment().subtract(6, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'Last 30 Days': [
                                                moment().subtract(30, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'This Month': [
                                                moment().startOf('month').toDate(),
                                                moment().endOf('month').toDate(),
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                            ],
                                        },
                                    }}
                                onApply={(e, val) => dateChangeEvent(val, e)}
                            >
                                <input type="text" className="form-control datepicker-range" />
                            </DateRangePicker>
                        </div>

                    </div>
                </div > */}
                <div className='row mb-2 mt-4'>
                    <div className='col-md-6'>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <form>
                                    <div style={{ position: 'relative' }}>
                                        <span className="icon"><img alt='' src={Search} /></span>
                                    </div>
                                    <input
                                        type="text"
                                        id="filter-text-box"
                                        placeholder="Search"
                                        className='form-control inputWithIcon'
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </form>
                            </div>

                        </div>

                    </div>
                    <div className='col-md-6' ref={dropdownRef}>
                        <div className='d-flex'>
                            <div className={`me-3 dropdown-dialog ${isOpenOrder ? 'open' : ''}`}>
                                <button type='button' className='btn btn-sm w-100 filter-btn' onClick={toggleOrderDropdown} style={{ padding: '6px 10px' }}> <span style={{ color: '#667085', fontSize: '14px' }}>Order Count</span> &nbsp; <img alt='' style={{ width: 15 }} src={DropDown} /></button>
                                {isOpenOrder && (
                                    <>
                                        <OrderCount ></OrderCount>
                                    </>
                                )}
                            </div>
                            <div className={`me-3 dropdown-dialog ${isOpenTags ? 'open' : ''}`}>
                                <button type='button' className='btn btn-sm w-100 filter-btn' onClick={toggleTagsDropdown} style={{ padding: '6px 10px' }}> <span style={{ color: '#667085', fontSize: '14px' }}>Search tag</span> &nbsp; <img alt='' style={{ width: 15 }} src={DropDown} /></button>
                                {isOpenTags && (
                                    <>
                                        <SearchTags ></SearchTags>
                                    </>
                                )}
                            </div>
                            <div className="me-3 form-group">
                                <div style={{ position: 'relative' }}>
                                    <span className="icon"><img alt='' src={Calandar} /></span>
                                </div>
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: startDate,
                                            endDate: endDate,
                                            showDropdowns: true,
                                            maxDate: moment().toDate(),
                                            locale: {
                                                format: 'MMM DD, YYYY',
                                            },
                                            ranges: {
                                                Today: [moment().toDate(), moment().toDate()],
                                                Yesterday: [
                                                    moment().subtract(1, 'days').toDate(),
                                                    moment().subtract(1, 'days').toDate(),
                                                ],
                                                'Last 7 Days': [
                                                    moment().subtract(6, 'days').toDate(),
                                                    moment().toDate(),
                                                ],
                                                'Last 30 Days': [
                                                    moment().subtract(30, 'days').toDate(),
                                                    moment().toDate(),
                                                ],
                                                'This Month': [
                                                    moment().startOf('month').toDate(),
                                                    moment().endOf('month').toDate(),
                                                ],
                                                'Last Month': [
                                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                                ],
                                            },
                                        }}
                                    onApply={(e, val) => dateChangeEvent(val, e)}
                                >
                                    <input type="text" className="form-control datepicker-range" />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                </div>
                <DataGridPagination
                    rowData={rowData}
                    columnDefs={columnData}
                    onSelectionChanged={onCellClick}
                    gridref={gridRef}
                    rowSelection={"multiple"}
                    domLayout="autoHeight"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={originalData.per_page}
                    pageCount={originalData.count}
                    topPagination={false}
                />
                {callDetails !== "" && <CallStatus
                    item={callRowID}
                    show={callDetails}
                    handleClose={closeModal}
                />
                }

            </div>
        </>
    );
}

export default CustomersBody;