import React from 'react';
import PageLayout from '../../../global/pageLayout';
import Body from './body';

const Index = () => {
    return (
        <>
            <PageLayout tabMenu={false} body={<Body />} />
        </>
    )
}

export default Index
