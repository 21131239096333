import React, { useState, useEffect } from 'react';
// import HeaderLogo from '../../../assets/sidebar/logo.png';
import Loader from '../../global/loader';
import ErrorToast from '../../customToast/ErrorToast';
import { Slide, toast } from 'react-toastify';
import { commonServiceMethod } from '../../../helpers/helper';
import TrackingDetailsPage from './trackingDetailsPage';
import { useParams } from 'react-router-dom';
const TrackYourOrderBody = () => {
    const [selectWay, setSelectWay] = useState("ONUM");
    const [trackForm, setTrackForm] = useState({OrderNo:"",emailPhone:"",awbNo:""});
    const [loader, setLoader] = useState(false);
    const [tractSubmitted, setTrackSubmitted] = useState(false);
    const [tractData, setTractData] = useState({});
    const {storeId} = useParams();
    const [storeData, setStoreData] = useState({});
    const [trackData, setTrackeData] = useState({});
    useEffect(()=>{
        getTrackDetails()
        getStoreDetails();

    },[]);

    const getStoreDetails = async () =>{
        commonServiceMethod(`app/get/settingbystoreid?storeId=${storeId}`, "get", {}, {}, setLoader, (res) => {
            console.log(res?.data?.data)            
            if(res?.data?.statusCode === 200){
                setStoreData(res?.data?.data);
            }
            else{
                setStoreData({})
            }
          },
          ()=>{
            toast(<ErrorToast body={'Data not found, please try again'} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
              });
          });
    } 
    const getTrackDetails = async () =>{
        commonServiceMethod(`app/get/tracking/settingbystoreid?storeId=${storeId}`, "get", {}, {}, setLoader, (res) => {
            console.log(res?.data?.data)            
            if(res?.data?.statusCode === 200){
                setTrackeData(res?.data?.data);
            }
            else{
                setTrackeData({})
            }
          },
          ()=>{
            setTrackeData({})
            toast(<ErrorToast body={'Data not found, please try again'} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
              });
          });
    } 
    const tractOrder = () =>{
        let data = {};
        if(selectWay === "ONUM"){
            data = {
                "orderNumber": trackForm.OrderNo,
                "emailOrNumber": trackForm.emailPhone,
                "awsNo": null,
                "isTrackingNumber":false,
                "storeId":storeId
            }
        }
        else{
            data = {
                "awsNo": trackForm.awbNo,
                "isTrackingNumber":true,
                "storeId":storeId
            }
        }
        
        console.log(trackForm, data);
        commonServiceMethod(`app/getTrackingDetails`, "post", data, {}, setLoader, (res) => {
            console.log(res?.data)            
            if (res?.data?.statusCode === 200 && res?.data?.message ==="SUCCESS") {
                setTrackSubmitted(true);
                setTractData(res?.data.data)
            }
            else {
                toast(<ErrorToast header={"Status"} body={res?.data?.message || 'Data not found, please try again'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                  });
            }
          },
          ()=>{
            toast(<ErrorToast body={'Data not found, please try again'} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
              });
          });
    }
    
    return (
        <>
            <Loader showHide={loader} />
            <div className='track-order'>
                <header>
                    <img alt="logo" className="" src={storeData?.logoUrl} />
                </header>
                {!tractSubmitted ? <section className='order-or-tracking-container'>
                    <div className='tracking-container'>
                        <h1 className='title'>Track Your Order</h1>
                        <div className='container-item'>
                            <div className='toggle-button'>
                                <button style={selectWay === 'ONUM' ? {background:trackData?.buttomFontColor,color:trackData?.fontColor} : {}} className={`order-number ${selectWay === 'ONUM' && 'toggle-click'}`} onClick={() => setSelectWay("ONUM")} >Order Number</button>
                                <button style={selectWay === 'TNUM' ? {background:trackData?.buttomFontColor,color:trackData?.fontColor} : {}}  className={`tracking-number ${selectWay === 'TNUM' && 'toggle-click'}`} onClick={() => setSelectWay("TNUM")}>Tracking Number</button>
                            </div>
                            <p className='intro'>{trackData?.buttionDesc}</p>
                            {
                                selectWay === 'ONUM' ? <>
                                    <div className='input-group-item'>
                                        <input type='input' placeholder='Order No' value={trackForm.OrderNo}  onChange={(e)=>{setTrackForm({...trackForm, OrderNo:e.target.value})}}/>
                                    </div>
                                    <div className='input-group-item'>
                                        <input type='input' placeholder='Email or Phone Number' value={trackForm.emailPhone}  onChange={(e)=>{setTrackForm({...trackForm, emailPhone:e.target.value})}}/>
                                    </div>
                                </>
                                    : <>
                                        <div className='input-group-item'>
                                            <input type='input' placeholder='AWB No.'value={trackForm.awbNo}  onChange={(e)=>{setTrackForm({...trackForm, awbNo:e.target.value})}} />
                                        </div>
                                    </>
                            }

                            <div>
                                <button  style={{background:trackData?.buttomFontColor,color:trackData?.fontColor}}  className='submit-button' disabled={
                                    selectWay === 'ONUM' ? 
                                    (trackForm.OrderNo === "" || trackForm.emailPhone === "") : trackForm.awbNo === ""
                                } onClick={tractOrder} >{trackData?.buttonText}</button>
                            </div>
                        </div>

                    </div>

                </section>
                : <TrackingDetailsPage data = {tractData}/>
                }
            </div>

        </>
    )
}

export default TrackYourOrderBody
