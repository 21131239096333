const storage_constant = {token: 'token',user_data: 'user_data'}

export const getUserData = () => {
  const data = localStorage.getItem(storage_constant.user_data);
  const token = localStorage.getItem(storage_constant.token);

  return { data, token }
}
const initialState = {
  user:{
    details:getUserData(),
    permissions:[]
  }
};

export default initialState;
