import React from 'react';
import PageLayout from '../../../global/pageLayout';
import ReturnsRequestBody from './body';

const ReturnPickupSchedule = () => {
    return (
        <div>
            <PageLayout tabMenu ={false} returnTabMenu={true} selectedItem="Return pickup & Scheduled" body={<ReturnsRequestBody />} />
        </div>
    )
}

export default ReturnPickupSchedule;