import React from 'react';
import moment from 'moment';
import LeftBtn from '../../../../assets/leftIcon.svg';
import RightBtn from '../../../../assets/rightIcon.svg'
import activityIcon from '../../../../assets/activityIcon.svg'

const ActivityLogs = () => {

    const events = [
        { id: 1, title: 'Order Manifested by John', date: '11 Aug 2024', time: '13:01:32' },
        { id: 2, title: 'Order Printed by John', date: '11 Aug 2024', time: '13:01:32' },
        { id: 3, title: 'Order labelled by John', date: '11 Aug 2024', time: '13:01:32' },
        { id: 3, title: 'Order confirmation email sent', date: '11 Aug 2024', time: '13:01:32' },
    ];

    return (
        <>
            <div className="card activity-card">
                <div className='card-header'>
                    <div className="row">
                        <div className="topview">
                            <div className='row'>
                                <div className="col-md-8 d-flex">
                                    <span className='card-icons'>
                                        <img src={activityIcon} alt='activityIcon' />
                                    </span>
                                    <h5 className='orderDetailTitle'>Activity logs</h5>
                                </div>
                                <div className="col-md-4 d-flex">
                                    <button className='btn btn-sm move-btns'><img src={LeftBtn} /></button>
                                    <button className='btn btn-sm move-btns'> <img src={RightBtn} /></button>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className='card-body'>
                    <div className="timeline">
                        {events?.map((event) => (
                            <div key={event.id} className="timeline-event">
                                <div className="timeline-circle"></div>
                                <div className="timeline-content">
                                    <div className="timeline-title">{event.title}</div>
                                    <div className="timeline-date">{moment(event.date).format('DD MMM YYYY')} {moment(event.time).format('hh:mm:ss')}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivityLogs
