import React, { useState } from 'react';
import { Modal } from "react-bootstrap";

const CreateWareHouse = ({ item, show, handleClose, handleSave }) => {

    console.log(item, handleSave);

    //const [enableSaveBtn, setEnableSaveBtn] = useState(false);

    const [shippingData, setShippingData] = useState({
        country: '',
        firstName: '',
        lastName: '',
        company: '',
        address: '',
        apartment: '',
        city: '',
        pincode: '',
        state: '',
        phone: '',
    });

    const [shippingErrors, setShippingErrors] = useState({});

    const [contactEmail, setContactEmail] = useState({
        email: ''
    });
    const [contactErrors, setContactErrors] = useState({});


    const handleChange = (e) => {
        const { name, value } = e.target;
        setShippingData({ ...shippingData, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let newErrors = {};

        if (!shippingData.country) {
            newErrors.country = "Country name is required";
        }

        if (!shippingData.firstName) {
            newErrors.firstName = "First Name is required";
        }

        if (!shippingData.lastName) {
            newErrors.lastName = "Last Name is required";
        }

        if (!shippingData.company) {
            newErrors.company = "Company is required";
        }

        if (!shippingData.address) {
            newErrors.address = "Address is required";
        }

        if (!shippingData.apartment) {
            newErrors.apartment = "Apartment is required";
        }
        if (!shippingData.city) {
            newErrors.city = "City is required";
        }

        if (!shippingData.pincode) {
            newErrors.pincode = "Pincode is required";
        }

        if (!shippingData.state) {
            newErrors.state = "State is required";
        }

        if (!shippingData.phone) {
            newErrors.phone = "Phone is required";
        }

        if (Object.keys(newErrors).length === 0) {
            console.log('Form data is valid:', shippingData);
        } else {
            setShippingErrors(newErrors);
        }


    };

    const handleChangeEmail = (e) => {
        const { name, value } = e.target;
        setContactEmail({ ...contactEmail, [name]: value });
    }

    const validate = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    }

    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        // alert('Hai', contactEmail);

        let newErrors = {};


        if (Object.keys(contactEmail.email).length === 0) {
            newErrors.email = "Email is required";
        }
        else {

            if (contactEmail.email !== '') {
                if (validate(contactEmail.email)) {
                    newErrors.email = "";
                }
                else {
                    newErrors.email = 'Invalid email address'
                }
            }
            else {
                newErrors.email = 'Invalid email address'
            }
        }

        if (Object.keys(newErrors).length === 0) {
            console.log('Form data is valid:', contactEmail);
        } else {
            setContactErrors(newErrors);
            console.log('Form data is not valid');
        }

    };

    return (<>

        <Modal
            size='lg'
            show={show === 'editShipingAddress'}
            onHide={handleClose}
            centered
            scrollable
        >
            <div className='modal-content modal-lg'>
                <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="modal-title">Edit Shipping Address</h5>
                </div>
                <div className="modal-body modal-lg">
                    <form onSubmit={handleSubmit}>
                        <div className='row'>

                            <div className='col-md-12 mb-3'>
                                <label className='setting-courier-model-label'>Country/region </label>
                                <input type='text' className='form-control' name='country' value={shippingData.country} onChange={(e) => handleChange(e)} />
                                {shippingErrors.country && <p className="error">{shippingErrors.country}</p>}
                            </div>
                            <div className='col-md-6 mb-3'>
                                <label className='setting-courier-model-label'>First name </label>
                                <input type='text' className='form-control' name='firstName' value={shippingData.firstName} onChange={(e) => handleChange(e)} />
                                {shippingErrors.firstName && <p className="error">{shippingErrors.firstName}</p>}
                            </div>


                            <div className='col-md-6 mb-3'>
                                <label className='setting-courier-model-label'>Last name </label>
                                <input type='text' className='form-control' name='lastName' value={shippingData.lastName} onChange={(e) => handleChange(e)} />
                                {shippingErrors.lastName && <p className="error">{shippingErrors.lastName}</p>}
                            </div>
                            <div className='col-md-12 mb-3'>
                                <label className='setting-courier-model-label'>Company</label>
                                <input type='text' className='form-control' name='company' value={shippingData.company} onChange={(e) => handleChange(e)} />
                                {shippingErrors.company && <p className="error">{shippingErrors.company}</p>}
                            </div>

                            <div className='col-md-12 mb-3'>
                                <label className='setting-courier-model-label'>Address </label>
                                <input type='text' className='form-control' name='address' value={shippingData.address} onChange={(e) => handleChange(e)} />
                                {shippingErrors.address && <p className="error">{shippingErrors.address}</p>}
                            </div>
                            <div className='col-md-12 mb-3'>
                                <label className='setting-courier-model-label'>Apartment, Suite, etc. </label>
                                <input type='text' className='form-control' name='apartment' value={shippingData.apartment} onChange={(e) => handleChange(e)} />
                                {shippingErrors.apartment && <p className="error">{shippingErrors.apartment}</p>}
                            </div>

                            <div className='col-md-6 mb-3'>
                                <label className='setting-courier-model-label'>City </label>
                                <input type='text' className='form-control' name='city' value={shippingData.city} onChange={(e) => handleChange(e)} />
                                {shippingErrors.city && <p className="error">{shippingErrors.city}</p>}
                            </div>
                            <div className='col-md-6 mb-3'>
                                <label className='setting-courier-model-label'>State</label>
                                <input type='text' className='form-control' name='state' value={shippingData.state} onChange={(e) => handleChange(e)} />
                                {shippingErrors.state && <p className="error">{shippingErrors.state}</p>}
                            </div>

                            <div className='col-md-6 mb-3'>
                                <label className='setting-courier-model-label'>Pincode </label>
                                <input type='text' className='form-control' name='pincode' value={shippingData.pincode} onChange={(e) => handleChange(e)} />
                                {shippingErrors.pincode && <p className="error">{shippingErrors.pincode}</p>}
                            </div>
                            <div className='col-md-6 mb-3'>
                                <label className='setting-courier-model-label'>Phone</label>
                                <input type='text' className='form-control' name='phone' value={shippingData.phone} onChange={(e) => handleChange(e)} />
                                {shippingErrors.phone && <p className="error">{shippingErrors.phone}</p>}
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button type="button" onClick={handleClose} className='btn btn-sm cancel-btn'>Cancel</button>&nbsp;
                            <button type="submit" className='btn btn-sm btn-primary'>Save</button>
                        </div>

                    </form>

                </div>

            </div>

        </Modal>

        <Modal
            size='lg'
            show={show === 'editInfoAddress'}
            onHide={handleClose}
            centered
            scrollable
        >
            <div className='modal-content modal-lg'>
                <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="modal-title">Edit Contact Information</h5>
                </div>
                <div className="modal-body modal-lg">
                    <form onSubmit={handleEmailSubmit}>
                        <div className='row'>

                            <div className='col-md-12 mb-3'>
                                <label className='setting-courier-model-label'>Email </label>
                                <input type='text' className='form-control' name='email' value={contactEmail.email} onChange={(e) => handleChangeEmail(e)} />
                                {contactErrors.email && <p className="error">{contactErrors.email}</p>}
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button type="button" onClick={handleClose} className='btn btn-sm cancel-btn'>Cancel</button>&nbsp;
                            <button type="submit" className='btn btn-sm btn-primary'>Save</button>
                        </div>

                    </form>

                </div>

            </div>

        </Modal>

    </>)

}

export default CreateWareHouse;