import React from 'react';
import DivBox from './data';

function DivBoxList({ dataItems }) {
    return (
        <div>
            {dataItems.map((item, index) => (
                <DivBox key={index} index={index} data={item} />
            ))}
        </div>
    );
}

export default DivBoxList;
