import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
// import Truck from '../../../../assets/truck.svg';
import { OverlayTrigger, Popover } from "react-bootstrap";
import Tags from "./tags";
import ProductDetails from "./productDetails";
import { useSelector } from 'react-redux';
import { commonServiceMethod } from "../../../../helpers/helper";
import Inventory from './inventory';
import Loader from "../../../global/loader";
//import { useParams } from "react-router";


const ProductBody = () => {

    const [loader, setLoader] = useState(false);

    const userClaim = useSelector((state) => state.user);
    let { id } = useParams();
    id = atob(id);
    console.log(id);

    const [productDetail, setProductDetail] = useState({});
    const getDetailPage = () => {
        commonServiceMethod(`app/product/details?shopifyProductId=${id}`, "get", {}, {}, setLoader, (res) => {
            if (res?.status === 200) {
                setProductDetail(res.data);
                console.log(res.data);
            }
        })
    }
    useEffect(() => {
        // setRefreshData(0);
        if (userClaim.token !== "") {
            getDetailPage();
        }
    }, []);
    return (
        <>
            <Loader showHide={loader} />
            <div className="parent-div">
                <div className="row">
                    <div className="col-md-9">
                        <div className="topview mb-4">
                            <div className="col-md-6">
                                <h4 className='fw-600'>{productDetail?.title}</h4>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex justify-content-end space-between">
                                    <OverlayTrigger className="action-button" rootClose={true} trigger="click" placement="bottom" overlay={
                                        <Popover id="popover-basic" className='z-index-2000 action-buttons'>
                                            <Popover.Body>
                                                <button className='print-invoice' onClick={() => printInvoiceMethod([productDetail])}>Print Invoice</button>
                                                <button className='cancel-order' onClick={() => showModal('cancelPopup', productDetail?.order, cancelOrder)}>Cancel Order</button>
                                            </Popover.Body>
                                        </Popover>
                                    }>
                                        <button className="btn btn-sm filter-btn">...</button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>

                        <ProductDetails productDetail={productDetail?.variants} />
                        <Inventory />

                    </div>
                    <div className="col-md-3" style={{ marginTop: '61px' }}>
                        <Tags tagsAll={productDetail?.tags} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default ProductBody
