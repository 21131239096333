import React from 'react';
import PageLayout from '../../../global/pageLayout';
import NewReturnsBody from './body';

const NewReturn = () => {
    return (
        <div>
            <PageLayout tabMenu ={false} returnTabMenu={true} selectedItem="New Returns" body={<NewReturnsBody />} />
        </div>
    )
}

export default NewReturn;