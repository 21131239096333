import instance from "./axiosInstance";

export const commonServiceMethod = async (url, method, data, config, setLoader, successMethod, errorMEthod) => {
    setLoader(true);
    try {
        await instance({
            url: url,
            data: data,
            method: method,
            ...config,
        })
            .then((res) => {
                setLoader(false);
                successMethod(res);
            });
    }
    catch (e) {
        setLoader(false);
        console.error(e);
        errorMEthod && errorMEthod(e);
    }

}

//Get Access 
export const checkAccess = (data) => {
    const permission = localStorage.userPermission?.split(",") || [];
    return permission.includes(data.toString())
}