import React from "react";
import { Link } from "react-router-dom";
export const Popup = ({ selecteObject, closePopup, exportMethod }) => {
    console.log(selecteObject);
    return (
        <div className="popup-container grid-checkbox-popuop">
            <div className="popup-body">
                <div className='row'>
                    <div className='col-md-6 selected-item'>
                        {selecteObject.length} items Seleted
                    </div>
                    <div className='col-md-6'>
                        <div className="row">
                            <div className="col-md-5">
                                <button type='button' className='btn btn-sm w-100 cancel-btn'>Reset HSN</button>
                            </div>
                            <div className="col-md-5">
                                <button type='button' onClick={exportMethod} className='btn btn-sm w-100 cancel-btn'>Export</button>
                            </div>
                            <div className="col-md-2">
                                <Link to={''} onClick={closePopup} >X</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};