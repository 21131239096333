import React from 'react';
import ReactEcharts from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
    BarChart,
} from 'echarts/charts';
import {
    GridComponent,
    ToolboxComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    LegendComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, ToolboxComponent, AxisPointerComponent, LegendComponent]
);

const NewCancelReason = ({ cancelReason }) => {
    console.log('====================================');
    console.log(cancelReason);
    console.log('====================================');
    if (!cancelReason) {
        return <div>Loading...</div>;
    }

    const getItemColor = (index) => {
        const colorPalette = ['#3E1C96', '#5925DC', '#7A5AF8', '#BDB4FE', '#D9D6FE', '#EBE9FE'];
        return colorPalette[index % colorPalette.length];
    };

    // const legendData = cancelReason.map((item) => item.legend);
    let legendData = cancelReason.map((item) => item.legend);
    let seriesData = cancelReason.map((item, index) => ({
        name: item.legend,
        type: 'bar',
        stack: 'total',
        barWidth: 16,
        emphasis: {
            focus: 'series'
        },
        itemStyle: {
            color: getItemColor(index)
        },
        data: [item.data]
    }));

    let options = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                return `${params?.seriesName}`;
            },
        },
        legend: {
            data: legendData,
            bottom: '0%',
            left: 'left',
            icon: 'circle',
            orient: 'vertical',
            right: 10,
            top: 50,
        },
        grid: {
            top: '0%',
            left: '0%',
            right: '0%',
            bottom: '80%',
            radius: '10%',
            containLabel: false
        },
        xAxis: {
            show: false
        },
        yAxis: {
            show: false,
            type: 'category',
            data: legendData
        },
        series: seriesData
    };

    // console.log(options, 'cancelReason');

    return (
        <>
            <ReactEcharts option={options} echarts={echarts} lazyUpdate={true} style={{ height: '100px' }} />
        </>

    );
};

export default NewCancelReason;
