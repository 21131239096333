import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import { useSelector } from 'react-redux';
import instance from '../../../helpers/axiosInstance';
import { Link } from 'react-router-dom';
import Search from '../../../assets/Iconsearch.svg';
import Calandar from '../../../assets/Icondate.svg';
// import AllFilter from '../../../assets/Iconall-filter.svg';
import DataGridPagination from '../../global/dataGridPagination';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import actionButton from '../../../assets/action-button.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import FilterMenu from "../../global/filterComponent/FilterMenu";
// import CallStatus from '../../global/popupModal/CallStatus';
import {
    //  filterOptions,
    sortBy, inventoryCount
} from './filterObj';
import './products.scss';
import {
    checkAccess,
    //commonServiceMethod,
    //  commonServiceMethod 
} from '../../../helpers/helper';
import Loader from '../../global/loader';
// import SuccessToast from '../../customToast/SuccessToast';
// import { Slide } from 'react-toastify';
//import refreshIcon from '../../../assets/dashboard/refreshIcon.png';
import SortBy from "../../global/filterComponent/productSort";
import Inventory from "../../global/filterComponent/productInventory";
import DropDown from '../../../assets/dropDown.svg'
//import { Slide, toast } from 'react-toastify';
//import SuccessToast from '../../customToast/SuccessToast';
import { Badge } from 'reactstrap';

const PRoductsBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const dropdownRef = useRef(null);
    const [rowData, setRowData] = useState();
    const [originalData, setOriginalData] = useState({});
    // const [selectedObject, setSelectedObject] = useState([]);
    // const [open, setOpen] = useState(false);

    // pagination and Filtr variables 
    const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");
    // const [isOpen, setIsOpen] = useState(false);
    const [, setIsOpen] = useState(false);

    // const [callDetails, setCallDetails] = useState('');
    // const [callRowID, setCallRowID] = useState('');
    const [loader, setLoader] = useState(false);
    const [columnData, setComlumnData] = useState([]);

    const [isOpenOrder, setIsOpenOrder] = useState(false);
    const [isOpenTags, setIsOpenTags] = useState(false);

    const toggleOrderDropdown = () => {
        setIsOpenOrder(true);
        setIsOpen(false);
        setIsOpenTags(false);
        // console.log(isOpenOrder);
    };

    const toggleTagsDropdown = () => {
        setIsOpenTags(true);
        setIsOpen(false);
        setIsOpenOrder(false);
        // console.log(isOpenTags);
    };


    // const toggleDropdown = () => {
    //     setIsOpen(true);
    //     setIsOpenTags(false);
    //     setIsOpenOrder(false);
    //     //  console.log(isOpen);
    // };


    const columnDefs = [
        {
            headerName: 'Product Name',
            field: 'product',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            width: 300,
            cellClass: "check-box-container",
            cellRenderer: (param) => {
                return (<div>
                    <Link className='grid-link' to={`/products/product-details/${btoa(param.value.product_id)}`}>{param.value.title}</Link>

                </div>)
            }
        },
        {
            field: 'sku',
            width: 150,
            wrapText: true,
            autoHeight: true,
            headerName: 'SKU',
            cellClass: "app-order-status",
            cellRenderer: (params) => {
                return (<span className={``}>
                    {params.value}
                </span>)
            }
        },
        {
            width: 200,
            field: 'weight',
            wrapText: true,
            autoHeight: true,
            headerName: 'Weight(gm)',
            cellClass: "d-flex-center",
            cellRenderer(params) {
                return (
                    <span>{params.value} gm</span>
                )
            }
        },
        {
            headerName: 'Status',
            field: 'product',
            width: 150,
            cellClass: "d-flex-center",
            cellRenderer(params) {
                return (
                    <Badge className="productBadge">{params.value.is_delete === "N" ? "Active" : "Deactive"}</Badge>
                )
            }
        },
        {
            width: 300,
            field: 'title',
            wrapText: true,
            autoHeight: true,
            headerName: 'Vendor',
            cellClass: '',
            cellRenderer(param) {
                return (<span className={`status-value ${param.value}`}>{param.value}</span>)
            }
        },
        {
            width: 130,
            field: 'title',
            wrapText: true,
            autoHeight: true,
            headerName: 'Actions',
            cellRenderer(param) {
                return (
                    <div className="more-action">
                        <OverlayTrigger className="" rootClose={true} trigger="click" placement="bottom" overlay={
                            <Popover id="popover-basic" className='z-index-2000 action-buttons new-order'>
                                <Popover.Body>
                                    <button className='view-details' onClick={() => showModal('courierPartner', 'courierPartner', param)}>Update Inventory</button>
                                </Popover.Body>
                            </Popover>
                        }>
                            <img alt='Action' src={actionButton} />
                        </OverlayTrigger>

                    </div>
                )
            }
        },
    ];

    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
            if (!checkAccess(11)) {
                // columnDefs.splice(5, 1);
                setComlumnData([...columnDefs]);
            }
            else
                setComlumnData([...columnDefs]);
        }

        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
                setIsOpenTags(false);
                setIsOpenOrder(false);
            }
        };

        // Attach the event listener
        document.addEventListener('click', handleOutsideClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [userClaim]);

    // const onClearFilter = () => {
    //     getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), "");
    // }

    // const showModal = (string, value) => {
    //     setCallDetails(string);
    //     setCallRowID(value)
    // }

    // const closeModal = () => {
    //     setCallDetails('');
    // }

    const getData = (
        page = 1,
        // startDate,
        // endDate,
        // searchText,
        // perams = ""
    ) => {
        var rowData = [];
        try {
            instance.get(`app/product/getvarent?per_page=${per_page}&page=${page}`).then((res) => {

                console.log(res)
                setLoader(false);
                if (res.status === 200 && Array.isArray(res?.data?.products)) {
                    setRowData(res?.data?.products || []);
                    setOriginalData(res?.data)
                }

            }).catch(e => {
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        console.log(selectedITem.length > 0)
        // setSelectedObject((selectedObject) => [...selectedObject]);
        selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setIdCount([]));
    }
    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        getData(
            pageNo,
            date.startDate.format('YYYY-MM-DD'),
            date.endDate.format('YYYY-MM-DD'),
            searchText
        )
    }
    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }
    // const onApplyFilter = (selectionOption, searchTextval = "") => {
    //     console.log(selectionOption)
    //     let url = "";
    //     Object.keys(selectionOption).map(i => {

    //         url += `&${i}=${selectionOption[i]}`
    //     })
    //     getData(
    //         pageNo,
    //         moment(startDate).format('YYYY-MM-DD'),
    //         moment(endDate).format('YYYY-MM-DD'),
    //         searchTextval || searchText,
    //         url
    //     )
    // }
    // const suncPRoduct = () => {
    //     commonServiceMethod(`/app/product/syncProductData`, "post", {}, {}, setLoader, (res) => {
    //         if (res.data.status_code === "200") {
    //             toast(<SuccessToast body={res.data?.message} />, {
    //                 transition: Slide,
    //                 hideProgressBar: true,
    //                 autoClose: 2000,
    //             });
    //         }
    //     });
    // }
    return (
        <>
            {/* {open ? <Popup selecteObject={selectedObject} closePopup={() => { setOpen(false); gridRef.current.api.deselectAll(); }} /> : null} */}
            <Loader showHide={loader} />
            <div className="parent-div product-table">
                <div className="topview">
                    <h4 className='fw-600' >Products</h4>
                </div>
                {/* <div className='quick-buttons'>
                    <button className='syncBtn' onClick={suncPRoduct}> <img src={refreshIcon} className="refreshIcon"></img> Sync Products</button>
                </div> */}
                {/* {open && <div className="product-popup popup-container grid-checkbox-popuop">
                    <div className="popup-body">
                        <div className='row'>
                            <div className='col-md-3 selected-item'>
                                {selectedObject.length} items Seleted
                            </div>
                            <div className='col-md-9 d-flex'>
                                <button type='button' onClick={() => showModal('courierPartner', 'courierPartner')} className='btn btn-sm w-100 asign-courier filter-btn'>Update Inventory</button>
                                <Link to={''} className='close-icon' onClick={() => { setOpen(false); gridRef.current.api.deselectAll(); }} >X</Link>
                            </div>
                        </div>
                    </div>
                </div>} */}
                {/* <div className='row mb-2 mt-4'>
                    <div className='col-md-5'>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <form onSubmit={searchTextChangeEvent}>
                                    <div style={{ position: 'relative' }}>
                                        <span className="icon"><img alt='' src={Search} /></span>
                                    </div>
                                    <input
                                        type="text"
                                        id="filter-text-box"
                                        placeholder="Search"
                                        className='form-control inputWithIcon'
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </form>
                            </div>

                        </div>

                    </div>
                    <div className='col-md-7 mt-1' ref={dropdownRef}>
                        <div className='d-flex d-align-right'>
                            <div className={`me-3 dropdown-dialog ${isOpenOrder ? 'open' : ''}`}>
                                <button type='button' className='btn btn-sm w-100 filter-btn' onClick={toggleOrderDropdown} style={{ padding: '6px 10px' }}> <span style={{ color: '#667085', fontSize: '12px' }}>Sort By</span> &nbsp; <img alt='' style={{ width: 15 }} src={DropDown} /></button>
                                {isOpenOrder && (
                                    <>
                                        <SortBy sortBy={sortBy}></SortBy>
                                    </>
                                )}
                            </div>
                            <div className={`me-3 dropdown-dialog ${isOpenTags ? 'open' : ''}`}>
                                <button type='button' className='btn btn-sm w-100 filter-btn' onClick={toggleTagsDropdown} style={{ padding: '6px 10px' }}> <span style={{ color: '#667085', fontSize: '12px' }}>Inventory</span> &nbsp; <img alt='' style={{ width: 15 }} src={DropDown} /></button>
                                {isOpenTags && (
                                    <>
                                        <Inventory inventoryCount={inventoryCount}></Inventory>
                                    </>
                                )}
                            </div>

                            <div className="form-group me-3 ">
                                <div style={{ position: 'relative' }}>
                                    <span className="icon"><img alt='' src={Calandar} /></span>
                                </div>
                                <DateRangePicker
                                    initialSettings={
                                        {
                                            startDate: startDate,
                                            endDate: endDate,
                                            maxDate: moment().toDate(),
                                            showDropdowns: true,
                                            locale: {
                                                format: 'MMM DD, YYYY',
                                            },
                                            ranges: {
                                                Today: [moment().toDate(), moment().toDate()],
                                                Yesterday: [
                                                    moment().subtract(1, 'days').toDate(),
                                                    moment().subtract(1, 'days').toDate(),
                                                ],
                                                'Last 7 Days': [
                                                    moment().subtract(6, 'days').toDate(),
                                                    moment().toDate(),
                                                ],
                                                'Last 30 Days': [
                                                    moment().subtract(30, 'days').toDate(),
                                                    moment().toDate(),
                                                ],
                                                'This Month': [
                                                    moment().startOf('month').toDate(),
                                                    moment().endOf('month').toDate(),
                                                ],
                                                'Last Month': [
                                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                                ],
                                            },
                                        }}
                                    onApply={(e, val) => dateChangeEvent(val, e)}
                                >
                                    <input type="text" className="form-control datepicker-range" />
                                </DateRangePicker>
                            </div>
                        </div>

                    </div>
                </div > */}

                <div className='row mb-2 mt-4 filter-section'>
                    <div className="filter-left-container">
                        <div className="form-group">
                            <form onSubmit={searchTextChangeEvent}>
                                <div style={{ position: 'relative' }}>
                                    <span className="icon"><img alt='' src={Search} /></span>
                                </div>
                                <input
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Search"
                                    className='form-control inputWithIcon'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </form>
                        </div>
                    </div>
                    <div className='filter-right-container' ref={dropdownRef}>
                        <div className={`me-3 dropdown-dialog ${isOpenOrder ? 'open' : ''}`}>
                            <button type='button' className='btn btn-sm w-100 filter-btn' onClick={toggleOrderDropdown} style={{ padding: '6px 10px' }}> <span style={{ color: '#667085', fontSize: '12px' }}>Sort By</span> &nbsp; <img alt='' style={{ width: 15 }} src={DropDown} /></button>
                            {isOpenOrder && (
                                <>
                                    <SortBy sortBy={sortBy} className="product-sort"></SortBy>
                                </>
                            )}
                        </div>
                        <div className={`me-3 dropdown-dialog ${isOpenTags ? 'open' : ''}`}>
                            <button type='button' className='btn btn-sm w-100 filter-btn' onClick={toggleTagsDropdown} style={{ padding: '6px 10px' }}> <span style={{ color: '#667085', fontSize: '12px' }}>Inventory</span> &nbsp; <img alt='' style={{ width: 15 }} src={DropDown} /></button>
                            {isOpenTags && (
                                <>
                                    <Inventory inventoryCount={inventoryCount} className="product-inventory"></Inventory>
                                </>
                            )}
                        </div>
                        <div className="form-group datepicker-class product-page-datepicker">
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Calandar} /></span>
                            </div>
                            <DateRangePicker
                                className="datepicker-custom"
                                initialSettings={
                                    {
                                        startDate: startDate,
                                        endDate: endDate,
                                        maxDate: moment().toDate(),
                                        showDropdowns: true,
                                        locale: {
                                            format: 'MMM DD, YYYY',
                                        },
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, 'days').toDate(),
                                                moment().subtract(1, 'days').toDate(),
                                            ],
                                            'Last 7 Days': [
                                                moment().subtract(6, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'Last 30 Days': [
                                                moment().subtract(30, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'This Month': [
                                                moment().startOf('month').toDate(),
                                                moment().endOf('month').toDate(),
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                            ],
                                        },
                                    }}
                                onApply={(e, val) => dateChangeEvent(val, e)}
                            >
                                <input type="text" className="form-control datepicker-range" />
                            </DateRangePicker>
                        </div>
                    </div>
                </div >
                <div className='product-table'>
                    <DataGridPagination
                        rowData={rowData}
                        columnDefs={columnData}
                        onSelectionChanged={onCellClick}
                        gridref={gridRef}
                        rowSelection={"multiple"}
                        domLayout="autoHeight"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={originalData.per_page}
                        pageCount={originalData.count}
                    />
                </div>

            </div>
        </>
    );
}

export default PRoductsBody;