import React from 'react';
import PageLayout from '../../global/pageLayout';
import NewOrdersBody from './body';

const NewOrders = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<NewOrdersBody />} selectedItem = "New" />
        </div>
    )
}

export default NewOrders;