import React from 'react';
import TrackYourOrderBody from './body';
import './tracking.scss';

function TrackYourOrder() {
    return (
        <>
            <TrackYourOrderBody/>
        </>
    )
}

export default TrackYourOrder
