import React, { useEffect, useState } from 'react';
// import BillingIcon from '../../../../assets/billingIcon.svg';
import { Link } from 'react-router-dom';
import backArrow from '../../../../assets/backArrow.svg';
import BillingAdvanced from '../../../../assets/billing/advanced.png';
import PersonIocn from '../../../../assets/billing/iconperson.png';
import DollerIcon from '../../../../assets/billing/doller.png';
import './style.scss'
import { useSelector } from 'react-redux';
import { commonServiceMethod } from '../../../../helpers/helper';
import Loader from '../../../global/loader';
import moment from 'moment';

const ManagePlan = () => {
    const [loader, setLoader] = useState(false);
    const userClaim = useSelector((state) => state.user);
    const [currentPlan, setCurrentPlan] = useState({});

    useEffect(() => {
        if (userClaim.token !== "") {
            getTransaction();

        }
    }, [userClaim]);
    const getTransaction = () => {
        commonServiceMethod(`app/getPlanByStore `, "get", {}, {}, setLoader, (res) => {
            console.log(res);
            if (res?.status === 200) {
                setCurrentPlan(res?.data?.data || {})

            }
        })
    }
    return (
        <>
            <Loader showHide={loader} />
            {/* <img src={BillingIcon} /> */}
            <div className="parent-div manage-billing-page">
                <div className='container mt-3 mb-3' >
                    <div className='row'>
                        <div className='col-md-9'>
                            <Link to={'/settings'}>
                                <img src={backArrow} /> Back
                            </Link>
                        </div>
                        <div className='col-md-9 '>
                            <p className='bill-titles'>Manage Plan</p>
                        </div>
                        <div className='billin-card'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='displayFlex'>

                                        <h5 className='setting-card-title'><span className='card-icons'>
                                            <img src={PersonIocn} />
                                        </span> <span>Plan details</span></h5>
                                        <Link to={"/settings/manage-plan/plans"} className='btn change-plan'>Change Plan</Link>
                                    </div>
                                </div>
                                {currentPlan.hasOwnProperty("name") &&
                                    <div className='card-body'>
                                        <div className='left-container'>
                                            <p className='card-sub-title'>Plan</p>
                                            <p className='advanced-section'><img src={BillingAdvanced} />
                                                <span className='advance-text'>{currentPlan.ame}
                                                    <span className='amount-text'>{currentPlan.currency === 'USD' ? '$' : '₹'}{currentPlan.amount} <span className='inr-text'>{currentPlan.currency}/month</span></span>
                                                </span>
                                            </p>
                                            <p className='billing-cycle-container'>
                                                <img src={DollerIcon} />
                                                <span className='billing-cyle'>Billing cycle <span className='billing-date'>{currentPlan?.planEndDate && moment(currentPlan?.planEndDate).subtract(1, 'months').format("DD MMM YYYY")} {currentPlan?.planEndDate && '-'} {currentPlan?.planEndDate && moment(currentPlan?.planEndDate).format("DD MMM YYYY")}</span></span>

                                            </p>
                                        </div>
                                        {/* <div className='right-container'>
                                        <p className='total-text'>Current total <span className='amount'>₹1994</span> INR/month</p>
                                    </div> */}

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManagePlan
