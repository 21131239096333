import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

const Accord = ({ title, text, obj , setSelectionOption }) => {

    // console.log(text);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const optionSelect = (e, option, item) =>{
        setSelectionOption(item.key,item.value);
    };

    return (
        // <div className="container">
        <div className="row">
            <div className="wrapper">
                <div className={isOpen ? "open" : ""} onClick={toggle}>
                    <div className="title">
                        {title} <span className={isOpen ? "arrow-dw" : "arrow"}>
                            <i className="fa fa-angle-down"></i></span>
                    </div>
                </div>
                <Collapse isOpen={isOpen}>
                    {Object.values(text).map((item, i) => {
                        return (
                                <p className="menuLink" key={i}>
                                    <label className="form-check-label  checkboxLabel">
                                        <input type="radio" className="form-check-input" onChange={(e)=>optionSelect(e, obj, item)} name={title} value={item?.menu} />
                                        &nbsp;{item?.menu}</label>
                                </p>
                        )
                    })}

                </Collapse>
                <hr />
            </div>
        </div>
        // </div>
    );
}

export default Accord