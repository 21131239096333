export const filterOptions = [   
    {
        "title": "Payment Type",
        "label": [
            {
                "id": 1,
                "menu": 'COD',
                "value":"COD",
                "key":"payment_type"

            },
            {
                "id": 2,
                "menu": 'Prepaid',
                "value": "Pre-paid" ,
                "key":"payment_type"
            },
        ]
    },
    {
        "title": "Not Serviceable Orders",
        "label": [
            {
                "id": 1,
                "menu": 'Not Serviceable',
                value:"NotServiceable",
                key: "notServiceable",
    
            }
        ]
    }
]