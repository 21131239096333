import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import OrderDetailUpdate from '../../global/popupModal/orderDetails'
import { commonServiceMethod } from '../../../helpers/helper';
import Loader from '../../global/loader';
import SuccessToast from '../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import customerIcon from '../../../assets/customerIcon.svg'

const CustomerDetails = ({ orderDetail, setRefreshData }) => {
    const [orderDetailData, setOrderDetail] = useState({});
    const [customerAddress, setCustomerAddress] = useState({});
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        setOrderDetail(orderDetail?.order || {});
        setCustomerAddress(JSON.parse(orderDetail?.order?.shipping_address || '{}'));
    }, [orderDetail])

    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null });

    const showModal = (popupName, data, callBackMethod) => {

        // console.log(string, value);
        // setCallDetails(string);
        // setCallRowID(value)
        setPopupData({ popupName, data, callBackMethod })
    }

    const closeModal = () => {
        // setCallDetails('');
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }
    const updateCutomerDetails = (obj) => {
        let email = obj.email;
        delete obj.email;
        commonServiceMethod(`app/order/${orderDetailData.order_id}/edit_shipping_address`, "post", obj, {}, setLoader, (res) => {
            if (res.data?.status_code === 200) {
                setRefreshData(refreshData => refreshData + 1);
                if (email !== orderDetailData?.customer_email) {
                    console.log(email)
                    commonServiceMethod(`app/order/${orderDetailData.order_id}/edit_contect_info`, "post", { customer_email: email }, {}, setLoader, (res) => {
                        if (res.data?.status_code === 200) {
                            setRefreshData(refreshData => refreshData + 1);
                            closeModal();
                            toast(<SuccessToast body={'Customer data updated for order #' + orderDetailData.order_id} />, {
                                transition: Slide,
                                hideProgressBar: true,
                                autoClose: 2000,
                            });
                        }
                    })
                }
                else {
                    toast(<SuccessToast body={'Customer data updated for order #' + orderDetailData.order_id} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }
                closeModal();
            }
        })

    }
    return (
        <>
            <Loader showHide={loader} />
            <div className="card">
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-md-11 d-flex'>
                            <span className='card-icons'>
                                <img src={customerIcon} alt='customerIcon' />
                            </span>
                            <h5 className='orderDetailTitle'>Customer Details</h5>
                        </div>

                        <div className='col-md-1 no-padding'>
                            <Link to={''} onClick={() => showModal('customerEdit', orderDetailData, updateCutomerDetails)}><i className="bi bi-pencil-square" style={{ fontSize: '20px' }} ></i></Link>

                        </div>
                    </div>
                </div>

                <div className='card-body'>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="textLabel">Name</label>
                            <p>{customerAddress?.first_name} {customerAddress?.last_name}</p>
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Contact No.</label>
                            <p>{customerAddress?.phone}</p>
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Email</label>
                            <p>{orderDetailData?.customer_email}</p>
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Address</label>
                            <p>{customerAddress?.address1 + ", "}
                                {customerAddress?.address2 && customerAddress?.address2 + ", "}
                                {customerAddress?.city && customerAddress?.city + ", "}
                                {customerAddress?.province && customerAddress?.province + ", "}
                                {customerAddress?.country && customerAddress?.country + ", "}
                                {customerAddress?.zip && customerAddress?.zip}</p>
                        </div>
                    </div>
                </div>

            </div>
            {popupData.popupName !== "" &&
                <OrderDetailUpdate
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closeModal}
                />
            }

        </>
    )
}

export default CustomerDetails