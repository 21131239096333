import React, { useState, useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './orderDetails.scss';
import ProductIcon from '../../../assets/productIcon.svg'

const ProductDetails = ({ orderDetail }) => {
    const [orderDetailData, setOrderDetail] = useState([]);
    const containerStyle = useMemo(() => ({ width: '100%', height: 'auto' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const agGrid = useRef(null);
    const [grandTotal, setGrandTotal] = useState(0);
    useEffect(() => {
        let total = 0;
        let order = orderDetail?.order?.order_products?.map((i) => {
            i.price = parseFloat(i.price);
            i.quantity = parseInt(i.quantity);
            i.total = i.price * i.quantity;
            total += i.total;
            return i;
        });
        setGrandTotal(total)
        setOrderDetail(order || [])
        console.log(agGrid);
    }, [orderDetail])

    const columnDefs = [
        {
            headerName: 'Name',
            field: 'product_name',
            width: 150,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'SKU',
            field: 'sku',
            width: 120,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'Qty',
            field: 'quantity',
            width: 60,
            wrapText: true,
            autoHeight: true,
            cellRenderer(params) {
                return params?.data?.order_id && <>{params.value}</>
            }
        },
        {
            headerName: 'Unit Price',
            field: 'price',
            width: 105,
            wrapText: true,
            autoHeight: true,
            cellRenderer(params) {
                return params?.data?.order_id && <>&#x20b9;{params.value}</>
            }
        },
        {
            headerName: 'Discount',
            field: 'item_discount_amount',
            width: 100,
            wrapText: true,
            autoHeight: true,
            cellRenderer(params) {
                return params?.data?.order_id && <>&#x20b9;{params.value || 0}</>
            }
        },
        {
            headerName: 'Tax',
            field: 'tax',
            width: 90,
            wrapText: true,
            autoHeight: true,
            cellClass: "total-count",
            cellRenderer(params) {
                return params?.data?.order_id ?
                    <>&#x20b9;{(params.data?.igst + params.data?.cgst + params.data?.sgst).toFixed(2)}</>
                    : params.value
            }
        },
        {
            headerName: 'Total',
            field: 'total',
            width: 103,
            wrapText: true,
            autoHeight: true,
            aggFunc: "sum",
            cellClass: "total-sum",
            cellRenderer(params) {
                return params?.data?.order_id ?
                    params.value ? <>&#x20b9;{params.value}</> : '' || 0
                    : params.value
            },
        },

    ];
    // const autoGroupColumnDef = useMemo(() => {
    //     return {
    //         minWidth: 300,
    //         cellRendererParams: {
    //             footerValueGetter: (params) => {
    //                 const isRootLevel = params.node.level === -1;
    //                 if (isRootLevel) {
    //                     return 'Grand Total';
    //                 }
    //                 return `Sub Total (${params.value})`;
    //             },
    //         },
    //     };
    // }, []);
    const pinnedBottomRowData = useMemo((data) => {
        console.log(data)
        return [
            {
                product_name: "",
                sku: "",
                quantity: "",
                price: "",
                item_discount_amount: "",
                tax: `Product Total (${orderDetailData?.length} items)`,
                total: <>&#x20b9;{grandTotal.toFixed(2)}</>
            }
        ];
    }, [grandTotal]);

    return (
        <>
            <div className="card">
                <div className='card-header'>
                    <div className='displayFlex'>
                        <span className='card-icons'><img src={ProductIcon} alt='ProductIcon' /></span>
                        <h5 className='orderDetailTitle'>Product Details</h5>
                    </div>
                </div>
                {
                    orderDetailData.length > 0 &&

                    <div className={`card-bodys order-detail-page-product-grid ${orderDetailData?.length == 1 && 'single-row-data'}`}>
                        <div style={containerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine">
                                <AgGridReact
                                    ref={agGrid}
                                    columnDefs={columnDefs}
                                    rowData={orderDetailData}
                                    domLayout={'autoHeight'}
                                    // autoGroupColumnDef={autoGroupColumnDef}
                                    // groupIncludeFooter={true}
                                    // groupIncludeTotalFooter={true}
                                    pinnedBottomRowData={pinnedBottomRowData}
                                />
                                {/* <div className='footerTotal'>
                                <div>
                                    <p className='totel-text'>
                                        Product Total ({orderDetailData?.length} items)
                                    </p>
                                    <p className='total-amount'>
                                        &#x20b9;{grandTotal.toFixed(2)}
                                    </p>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default ProductDetails