import React from 'react';
import ProductBody from './body';
import PageLayout from '../../../global/pageLayout'

const ProductDetails = () => {
    return (
        <div>
            {/* <PageLayout tabMenu={true} body={<ProductBody />} /> */}
            <PageLayout tabMenu={false} body={<ProductBody />} />
        </div>
    )
}

export default ProductDetails
