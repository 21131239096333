export const filterOptions = [   
    {
        "title": "Carrier",
        "label": [ {
            "id": 1,
            "menu": 'Bluedart',
            value:"Bluedart",
            key: "carrier",

        },
        {
            "id": 2,
            "menu": 'Delhivery',
            value:"Delhivery",
            key: "carrier",

        },
        {
            "id": 3,
            "menu": 'DTDC',
            value:"DTDC",
            key: "carrier",

        },
        {
            "id": 4,
            "menu": 'Ekart',
            value:"Ekart",
            key: "carrier",

        },
        {
            "id": 5,
            "menu": 'Other',
            value:"Other",
            key: "carrier",
        }]
    },
]