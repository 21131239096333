import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import backArrow from '../../../../assets/backArrow.svg';
import CheckIcon from '../../../../assets/checkIcon.svg';
// import PlainIcon1 from '../../../../assets/billing/plan1.png';
// import PlainIcon2 from '../../../../assets/billing/plan2.png';
// import PlainIcon3 from '../../../../assets/billing/plan3.png';
import PlainIcon4 from '../../../../assets/billing/plan4.png';

import './style.scss';
import { useSelector } from 'react-redux';
import { commonServiceMethod } from '../../../../helpers/helper';
import Loader from '../../../global/loader';
// import { map } from 'lodash';
import useRazorpay from "react-razorpay";
import SuccessToast from '../../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import ErrorToast from '../../../customToast/ErrorToast';


const ViewPlan = () => {
    const userClaim = useSelector((state) => state.user);
    const [Razorpay] = useRazorpay();

    const [loader, setLoader] = useState(false);
    const [currentPlan, setCurrentPlan] = useState({});
    const [plans, setPlans] = useState();
    // const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        if (userClaim.token !== "") {
            getStorePlan();
            getPlans();

        }
    }, [userClaim]);

    // const loadScript = (src) => {
    //     return new Promise(() => {
    //         const script = document.createElement("script");
    //         script.src = src;
    //         script.onload = () => {
    //             setScriptLoaded(true);
    //         };
    //         script.onerror = () => {
    //             setScriptLoaded(false);
    //         };
    //         document.body.appendChild(script);
    //     });
    // }

    const getStorePlan = () => {
        commonServiceMethod(`app/getPlanByStore`, "get", {}, {}, setLoader, (res) => {
            console.log(res);
            if (res?.status === 200) {

                setCurrentPlan(res?.data?.data)
            }
        })
    }
    const getPlans = () => {
        commonServiceMethod(`app/getAllPlan`, "get", {}, {}, setLoader, (res) => {
            console.log(res);
            if (res?.status === 200) {

                console.log(res?.data?.data);
                const data = res?.data?.data?.map(i => {
                    return {
                        planTitle: i.name,
                        price: i.amount,
                        priceSign: i.currency === 'USD' ? '$' : '',
                        duration: 'mon',
                        desc: `Billed ${i.billingCycle}`,
                        data1: `Process ${i.maxOrder} orders`,
                        data2: i.withReturn ? 'Returns functionality' : false,
                        data3: i.withTracking ? 'Tracking Page' : false,
                        data4: `${i.maxUser} Users`,
                        currentPlan: false,
                        icon: PlainIcon4,
                        id: i.id

                    }
                })
                setPlans(data);
            }
        })
    }

    const createOrder = (item) => {
        commonServiceMethod(`app/createOrder?planId=${item.id}`, "post", {}, {}, setLoader, (res) => {
            console.log(res);
            if (res?.data?.statusCode === 200) {
                triggerPay(item, res?.data?.data.rzpOrderId);
            }
        })

    }
    const update = (e) => {
        // location.reload();
        console.log("sdsdsdssdd", e)
        getStorePlan();
        toast(<SuccessToast body={'Payment Succeeded'} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
        });

    }
    const triggerPay = useCallback((item, order_id) => {
        console.log(item);
        const email = String(localStorage.getItem('username'));
        const userName = email.split('@')[0];
        const razorPayObj = {
            "key": "rzp_test_N7iUoF2lHodUNt", // Enter the Key ID generated from the Dashboard
            "amount": item.price,
            "currency": "USD",
            "name": "Acme Corp", //your business name
            "description": "Test Transaction",
            "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            // "callback_url":(e)=> update(e),
            handler: (res) => {
                update(res);
            },
            "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
                "name": userName, //your customer's name
                "email": email,
                "contact": "9500795024" //Provide the customer's phone number for better conversion rates 
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        const rzpay = new Razorpay(razorPayObj);
        rzpay.open();
        rzpay.on("payment.failed", function (response) {
            // console.log(response.error.code);
            // console.log(response.error.description);
            // console.log(response.error.source);
            // console.log(response.error.step);
            // console.log(response.error.reason);
            // console.log(response.error.metadata.order_id);
            // console.log(response.error.metadata.payment_id);
            toast(<ErrorToast header='Error' body={response.error.description} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        });

    }, [Razorpay])
    return (
        <>
            <Loader showHide={loader} />
            {/* <img src={BillingIcon} /> */}
            <div className="parent-div plan-details">
                <div className='container mt-3 mb-3' >
                    <div className='row'>
                        <div className='col-md-9'>
                            <Link to={'/settings/manage-plan'}>
                                <img src={backArrow} /> Back
                            </Link>

                        </div>
                        <div className='col-md-3 d-flex justify-content-end'>

                        </div>
                    </div>
                    <div className='row mt-3 mb-3'>
                        <div className='col-md-12'>
                            <p className='page-header-text mb-1'>Plans</p>
                            <div className='row mb-3'>
                                {plans && plans.map((items, i) => {
                                    return (
                                        <>
                                            <div className={`col-md-3 plan-list`} key={i}>
                                                <div className='card'>
                                                    {items.id === currentPlan?.id &&
                                                        <div className='card-header active'>
                                                            <p>Current Plan</p>
                                                        </div>
                                                    }
                                                    <div className='card-body'>
                                                        <div style={{ textAlign: 'center' }} className='mb-4'>
                                                            <div className='mb-2'><img src={items?.icon} /></div>
                                                            <div className='mb-2'><span className='billing-header'>{items?.planTitle}</span></div>
                                                            <div className='mb-2'>
                                                                <span className='billing-price'>{items?.priceSign}{items?.price}/{items?.duration}</span>
                                                            </div>
                                                            <div>
                                                                <span className='billing-short-desc'>{items?.desc}</span>
                                                            </div>

                                                        </div>
                                                        <div className='mb-2'>
                                                            <span className='billing-desc'> <img src={CheckIcon} /> {items?.data1}</span>
                                                        </div>
                                                        <div className='mb-2'>
                                                            <span className='billing-desc'> <img src={CheckIcon} /> {items?.data2}</span>
                                                        </div>
                                                        <div className='mb-2'>
                                                            <span className='billing-desc'> <img src={CheckIcon} /> {items?.data3}</span>
                                                        </div>
                                                        <div className='mb-2'>
                                                            <span className='billing-desc'> <img src={CheckIcon} /> {items?.data4}</span>
                                                        </div>
                                                        <div className='mt-4'>
                                                            <button onClick={() => createOrder(items)} className={`btn btn-primary w-100 plan-button ${items.id === currentPlan?.id ? 'active' : ''}`}>{items.id === currentPlan?.id ? 'Current Plan' : 'Get started'}</button>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>

                            {/* <div className='billing-layout mb-4'>
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className='mb-3'>
                                        <span className='header'>Ut enim ad minim veniam</span>

                                    </div>
                                    <div>
                                        <span className='desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore </span>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <button className='btn filter-btn w-100'>Customer Support</button>
                                        </div>
                                        <div className='col-md-4'>
                                            <button className='btn btn-primary w-100'>Contact us</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewPlan;
