import React from 'react';
import ServicePincode from './body';
import PageLayout from '../../global/pageLayout';

const index = () => {
    return (
        <>
            <PageLayout tabMenu={false} body={<ServicePincode />} />
        </>
    )
}

export default index
