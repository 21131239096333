import React from 'react';
import PageLayout from '../../global/pageLayout';
import DashboardBody from './body';
// import { Link } from 'react-router-dom';

const Dashboard = () => {
    return (
        <div>
            <PageLayout tabMenu={false} body={<DashboardBody />} />
            {/* <Link to={''}>Logout</Link> */}
        </div>
    )
}

export default Dashboard;