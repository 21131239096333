import React, { useState, useRef } from 'react'
import Accord from './Accord';
import Search from '../../../assets/Iconsearch.svg';


const Filter = ({ filterOptions, onApply, onClear }) => {
    const [searchText, setSearchText] = useState();
    const [selectionOption, setSelectionOption] = useState({});
    const ref = useRef(null);
    const selectObj = (lable, obj) => {
        setSelectionOption({ ...selectionOption, [lable]: obj })
    }
    const clearAll = () => {
        ref.current.reset();
        setSearchText('');
        setSelectionOption({});
        onClear();
    }
    return (
        <div className="accordion-wrapper">
            <ul className="dropdown-list">
                <li className='mt-3'></li>
                <li className="menuLink">All Filters</li>
                <li className='mt-3 mb-3'>
                    <div className="form-group">
                        <div style={{ position: 'relative' }}>
                            <span className="icon"><img alt='' src={Search} /></span>
                        </div>
                        <input
                            type="text"
                            id="filter-text-box"
                            placeholder="Search"
                            className='form-control inputWithIcon'
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </li>
                <li>
                    <form ref={ref}>
                        {
                            filterOptions.map((option) => {
                                return <Accord key={option.title} obj={option} setSelectionOption={selectObj} title={option.title} text={option.label} />
                            })
                        }
                    </form>

                </li>
                <li className='d-flex space-between mb-2'>
                    <button type='button' className='btn btn-sm  clear-all-btn' onClick={clearAll}>Clear All</button>
                    <button type='button' disabled={JSON.stringify(selectionOption) === '{}' && searchText === ''} className='btn btn-sm  apply-filter-btn' onClick={() => onApply(selectionOption, searchText)}>Apply Filter</button>
                </li>
            </ul>
        </div>
    )
}

export default Filter