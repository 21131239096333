import React, { useState, useEffect } from 'react';
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import Shopify from '../../../assets/shopify.svg'
import moment from 'moment';
import OrderDetailUpdate from '../../global/popupModal/orderDetails';
import orderIcon from '../../../assets/orderIcon.svg'
import { commonServiceMethod } from '../../../helpers/helper';
import Loader from '../../global/loader';

const OrderDetail = ({ orderDetail }) => {

    const [orderDetailData, setOrderDetail] = useState({});
    const [werehouseList, setWerehouseList] = useState([]);
    const [werehouseData, setWerehouseData] = useState({});
    useEffect(() => {
        console.log("orderDetail", orderDetail)
        orderDetail?.order?.warehouseId && getWereHouseData(orderDetail?.order.warehouseId)
        setOrderDetail(orderDetail?.order || {});
    }, [orderDetail])
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null });
    const [loader, setLoader] = useState(false);
    const showModal = (popupName, data, callBackMethod) => {

        // console.log(string, value);
        // setCallDetails(string);
        // setCallRowID(value)
        setPopupData({ popupName, data, callBackMethod })
    }
    const getWereHouseData = (id) =>{
        commonServiceMethod(`app/warehouselocation/${id}`,"get",{},{},setLoader,(res) => {
            console.log(res)
           if(res?.data?.status_code === 200){
            setWerehouseData(res?.data?.data || {});
           }
        },e => {
            console.error(e);
            setWerehouseList([]);
        })
    }
    const closeModal = () => {
        // setCallDetails('');
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }

    const getShippingAddress = async (orderDetailData) =>{
       
        try {
            commonServiceMethod("app/location","get",{},{},setLoader,(res) => {

                if (res.status === 200 && Array.isArray(res.data?.data)) {
                    setWerehouseList(res.data?.data || []);
                    showModal('orderEdit', orderDetailData, updatePickupAddress);
                }

            },e => {
                console.error(e);
                setWerehouseList([]);
            })
        }
        catch (e) {
            console.error(e);
            setWerehouseList([]);
        }
         
    }
    const updatePickupAddress = (obj) =>{
        // console.log(id);
        obj = JSON.parse(obj);
        commonServiceMethod(`app/order/updatewarehouse?orderId=${orderDetailData?.order_id}&warehouseId=${obj.id}`,"post",{},{},setLoader,(res) => {
            console.log(res);
            if (res.data?.statusCode === 200) {
                closeModal();
                setWerehouseData(obj);
            }

        },e => {
            console.error(e);
            setWerehouseList([]);
        })
    }
    return (
        <>
        <Loader showHide={loader}/>
            <div className="card">
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-md-11 d-flex'>
                            <span className='card-icons'>
                                <img src={orderIcon} alt='orderIcon' />
                            </span>
                            <h5 className='orderDetailTitle'>Order Details</h5>
                        </div>

                        <div className='col-md-1 no-padding'>
                            <Link to={''} onClick={() => {getShippingAddress(orderDetailData)}}><i className="bi bi-pencil-square" style={{ fontSize: '20px' }} ></i></Link>

                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="textLabel">Order created on channel</label>
                            <p>{moment(orderDetailData?.created_at).format("DD MMM YYYY hh:mm:ss")}</p>
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Channel</label>
                            <p><img src={Shopify} /> Shopify</p>
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Pickup Address</label>
                            {/* <p>{orderDetailData?.shipping_address}</p> */}
                            <p>{werehouseData?.name || ''}</p>
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Order created on system</label>
                            <p>{moment(orderDetailData?.store_order_created_at).format("DD MMM YYYY hh:mm:ss")}</p>
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Order Status</label>
                            <p><Badge className="labels">{orderDetailData?.app_order_status}</Badge></p>
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Fulfill Status</label>
                            <p><Badge className="labels">{orderDetailData?.order_fulfillment_status}</Badge></p>
                        </div>
                    </div>
                </div>

            </div>

            {popupData.popupName !== "" &&
                <OrderDetailUpdate
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closeModal}
                    options={werehouseList}
                />
            }
        </>
    )
}

export default OrderDetail