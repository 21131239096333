import React from 'react';
import PageLayout from '../../../global/pageLayout';
import ReturnsRequestBody from './body';

const AllReturns = () => {
    return (
        <div>
            <PageLayout tabMenu ={false} returnTabMenu={true} selectedItem="All" body={<ReturnsRequestBody />} />
        </div>
    )
}

export default AllReturns;