import React from 'react';
import PageLayout from '../../global/pageLayout';
import TrackingBody from './body'

function Tracking() {
    return (
        <>
            <PageLayout tabMenu={false} body={<TrackingBody />} />
        </>
    )
}

export default Tracking
