import React from 'react';
import UserView from './Body';
import PageLayout from '../../../global/pageLayout';

export default function Index() {
    return (
        <>
            <PageLayout tabMenu={false} body={<UserView />} />
        </>
    )
}
