import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { commonServiceMethod } from '../../../../helpers/helper';
import SuccessToast from '../../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import Loader from '../../../global/loader';

const UserDetailModal = (props) => {

    const [loader, setLoader] = useState(false);
    const { show, handleClose } = props;
    const [userDetails, setUserDetails] = useState({
        "userName": "",
        "phoneNumber": "",
        "firstName": "",
        "lastName": "",
        "roleId": "",
        "modules": []
    });
    const [userInfo, setUserInfo] = useState({});
    
    const updateCheckVal = (e) => {
        e.target.checked ? setUserDetails({ ...userDetails, "modules": _.uniq([...userDetails.modules, parseInt(e.target.value)]) }) : setUserDetails({ ...userDetails, "modules": _.without([...userDetails.modules], parseInt(e.target.value)) })
    }
    const updateVal = (e) => {
        setUserDetails({ ...userInfo, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        setUserInfo(props.userDetails)
        setUserDetails({ ...userDetails, "modules": props.permission })
    }, []);

    const saveUserPermission = () => {
        const config = {};
        let obj = {};
        obj.modules = userDetails.modules;
        obj.userId = props.item;
        commonServiceMethod("app/setpermission", "post", obj, config, setLoader, (res) => {
            if (res.status === 200) {
                if(userInfo.email === localStorage.username){
                    obj.modules.push(0);
                    localStorage.setItem("userPermission",obj.modules);
                }
                toast(<SuccessToast body={'User Permission updated'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                handleClose(1);
            }
        });
    }
    const saveUserInfo = () => {
        const config = {};
        let obj = {};
        obj = userInfo;
        // obj.userId = props.item;
        commonServiceMethod("app/setpermission", "post", obj, config, setLoader, (res) => {
            if (res.status === 200) {
                toast(<SuccessToast body={'User Permission updated'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                handleClose(1);
            }
        });
    }
    console.log(userInfo)
    return (
        <>
            <Loader showHide={loader} />
            <Modal
                size='lg'
                show={show === 'editUserDetails'}
                onHide={handleClose}
                centered
                scrollable
            >
                <div className='modal-content modal-lg'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Edit User Details</h5>
                        <Link
                            onClick={handleClose}
                            className='text-danger'
                        >
                            <i className="bi bi-x-circle" style={{ fontSize: '24px' }}></i>
                        </Link>
                    </div>
                    <div className="modal-body modal-lg">

                        <div className='row'>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom01" className="form-label userLabel">Full Name</label>
                                <input type="text" className="form-control" id="validationCustom01" value={userInfo.first_name} name='first_name' onChange={updateVal} required />
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom02" className="form-label userLabel">Last Name</label>
                                <input type="text" className="form-control" id="validationCustom02" value={userInfo.last_name} name='last_name' onChange={updateVal} />
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom02" className="form-label userLabel">User ID</label>
                                <input type="text" className="form-control" id="validationCustom02" value={userInfo.id} name='first_name' onChange={updateVal} readOnly />
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom02" className="form-label userLabel">Email Address</label>
                                <input type="text" className="form-control" disabled id="validationCustom02"value={userInfo.email} name='first_name' onChange={updateVal} readOnly />
                            </div>

                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom02" className="form-label userLabel">Phone Number</label>
                                <input type="text" className="form-control" id="validationCustom02"value={userInfo.phone_number} name='phone_number' onChange={updateVal} required />
                            </div>

                            <div className='col-md-12 mb-3'>
                                <label htmlFor="validationCustom02" className="form-label userLabel">Role</label>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="form-check">
                                            <input type="radio" className="form-check-input userLabel" name="role_id"  value={1} onChange={updateVal} id="validationFormCheck2" required />
                                            <label className="form-check-label  checkboxLabel"  htmlFor="validationFormCheck2">Administrator</label>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className="form-check mb-3">
                                            <input type="radio" className="form-check-input userLabel"  name="role_id" value={2}  onChange={updateVal} id="validationFormCheck3" required />
                                            <label className="form-check-label  checkboxLabel" htmlFor="validationFormCheck3">Staff</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">

                        <div className="col-12 mb-4 d-flex">
                            <button className="btn btn-sm btn-primary" onClick={saveUserInfo} type="submit">Save</button>&nbsp;&nbsp;
                            <button className="btn btn-sm btn-secondary" type="button" onClick={handleClose}>Cancel</button>
                        </div>

                    </div>

                </div>
            </Modal>

            <Modal
                size='lg'
                show={show === 'editUserPermission'}
                onHide={handleClose}
                centered
                scrollable
            >

                <div className='modal-content user-permission-modal'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">Edit User Permission</h5>
                        <Link
                            onClick={handleClose}
                            className='text-danger'
                        >
                            <i className="bi bi-x-circle" style={{ fontSize: '24px' }}></i>
                        </Link>
                    </div>

                </div>

                <div className="modal-body modal-lg">

                    <div className='cardBodyInner'>
                        <div className='row'>
                            {
                                props.moduleList.map((i, index) => (
                                    <div key={index} className={`col-md-5 ${"Call Access" === i.module_name && 'disabled'}`}>
                                        <div className="form-check mb-3">
                                            <input onChange={updateCheckVal} value={i.id} type="checkbox" checked={userDetails?.modules.indexOf(i.id) > -1} className="form-check-input" id={'validation' + index} />
                                            <label className="form-check-label  checkboxLabel" htmlFor={'validation' + index}>{i.module_name}</label>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>
                <div className="modal-footer">

                    <div className="col-12 mb-4 d-flex">
                        <button className="btn btn-sm btn-primary" onClick={saveUserPermission} type="submit">Save</button>&nbsp;&nbsp;
                        <button className="btn btn-sm btn-secondary" type="button" onClick={handleClose}>Cancel</button>
                    </div>

                </div>


            </Modal>

        </>
    )
}

export default UserDetailModal;