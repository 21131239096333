import React from 'react';
import PageLayout from '../../global/pageLayout';
import InvoiceBody from './body'

const ExotelIntegration = () => {
    return (
        <>
            <PageLayout tabMenu={false} body={<InvoiceBody />} />
        </>
    )
}

export default ExotelIntegration
