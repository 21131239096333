import React, { useEffect, useState } from 'react';
import backArrow from '../../../assets/backArrow.svg';
import { Link } from 'react-router-dom';
import DivList from './DivList';
import Loader from '../../global/loader';
import { commonServiceMethod } from '../../../helpers/helper';

const Body = () => {

    const [rowDatas, setRowDatas] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await commonServiceMethod(`app/shipping_carriers/getshipping_carriers?per_page=25&page=1&search=`, 'get', {}, {}, setLoader,
            (res) => {
                if (res?.data?.statusCode === 200)
                    setRowDatas(res?.data?.data);
                else
                    setRowDatas([])
            })
    }


    return (
        <>
            <Loader showHide={loader} />
            <div className="parent-div">
                <div className='container mt-3 mb-3' >
                    <div className='row'>
                        <div className='col-md-9'>
                            <Link to={'/settings'}>
                                <img src={backArrow} /> Back
                            </Link>

                        </div>
                        <div className='col-md-3 d-flex justify-content-end'>

                        </div>
                    </div>
                    <div className='row mt-3 mb-3'>
                        <div className='col-md-10'>
                            <span className='page-header-text mb-2'>Courier Integrations</span>
                            <p className='details'>Shipping provides are used when you purchase postage and print labels. Fulfillments provides are used when you have 3rd party services who packages and ships your orders for you connect and manage your provides for however you ship</p>

                            <h5 className='courier-head mt-3'>Couriers</h5>
                            <DivList dataItems={rowDatas} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Body
