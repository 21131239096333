import React from "react";

const Body = ({ body }) => {
  return (
    <>
      {body}
    </>
  );

};

export default Body;
