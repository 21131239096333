import React from 'react';
import PageLayout from '../../global/pageLayout';
import CustomersBody from './body';

const Customers = () => {
    return (
        <div>
            <PageLayout tabMenu={false} body={<CustomersBody />} />
        </div>
    )
}

export default Customers;