import React from 'react';
import orderImg from '../../../../assets/orderImg.svg'
import { Link } from 'react-router-dom';
import orderIcon from '../../../../assets/orderIcon.svg'

const LastPlacedOrder = () => {
    return (
        <>
            <div className="card last-placed-order">
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-md-11 d-flex'>
                            <span className='card-icons'>
                                <img src={orderIcon} alt='orderIcon' />
                            </span>
                            <h5 className='orderDetailTitle'>Last Placed Order</h5>
                        </div>

                        {/* <div className='col-md-1 no-padding'>
                            <Link to={''}><i className="bi bi-pencil-square" style={{ fontSize: '20px' }} ></i></Link>
                        </div> */}
                    </div>
                </div>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-md-10 mb-3">
                            <label className="order-number">#342565</label>
                            <p className='order-desc'>14 June 2023 at 7:08 pm from Deyga Mobile APP - live (via import)</p>
                        </div>
                        <div className="col-md-2 mb-3">
                            <p className="order-amount">₹ 42.00</p>
                        </div>
                        <div className="col-md-10">
                            <div className='row'>
                                <div className='col-md-1'>
                                    <img src={orderImg} />
                                </div>
                                <div className='col-md-11'>
                                    <p className='order-desc-one'>Vanilla Deo mini 10 gms</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <label className="order-amount-one">₹ 128.00</label>
                        </div>
                    </div>
                </div>
                <div className='card-footer'>
                    <div className='d-flex justify-content-end'>
                        <Link to={'/'} className='view-orders'>View All Orders</Link>
                    </div>
                </div>

            </div>

        </>
    )
}

export default LastPlacedOrder
