import React, { useEffect, useState } from "react";
import Loader from "../../global/loader";
import { useSelector } from 'react-redux';
import './style.scss';
import copy from '../../../assets/copy.png';
import SuccessToast from "../../customToast/SuccessToast";
import { Slide, toast } from "react-toastify";
import { commonServiceMethod } from "../../../helpers/helper";
import ErrorToast from "../../customToast/ErrorToast";

const TrackingBody = () => {
  const userClaim = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [trackID, setTrackId] = useState(null);
  const [formData, setFormData] = useState({
    "id": '',
    "storeId": '',
    "trackingPageUrl": "",
    "themeColor": "",
    "buttomFontColor": "",
    "fontColor": "",
    // "additionCss": "",
    "buttonText": "",
    "buttionDesc": "",
    "orderNumberMsg": "",
    "emailMsg": "",
    "notShipMsg": "",
    "enable": false
  });
  const [createOrUpdate, setCreateOrUpdate] = useState("");
  const [formErro, setErrors] = useState(0);
  useEffect(() => {
    // setRefreshData(0);
    if (userClaim.token !== "") {
      getTrackingDetails();
    }
  }, []);

  const getTrackingDetails = () => {
    const config = {};
    commonServiceMethod("app/get/tracking/setting", "get", {}, config, setLoader, (res) => {
      console.log(res?.data?.data)
      if (res?.data?.statusCode === 200) {
        let data = res?.data?.data;
        if (data?.hasOwnProperty('storeId') && data.id !== 0) {
          setCreateOrUpdate("update");          
          setFormData({ ...data });
          setTrackId(data.id);
        }
        else {
          setFormData({ ...data });
          setCreateOrUpdate("create");
          setTrackId(data.id);
        }
      }


    });
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let newErrors = 0;
    let id = trackID; 
    delete formData.id;
    delete formData.storeId;
    delete formData.enable;
    delete formData.additionCss;
    Object.keys(formData).map(i => {
      (formData[i] === "" || formData[i] === null)  && newErrors++;
    })
    
    if (newErrors === 0) {
      setErrors(newErrors);
      let data = { ...formData };
      const config = {};
      if (createOrUpdate === "update") {        
        commonServiceMethod(`app/update/tracking/setting?id=${id}`, "post", data, config, setLoader, (res) => {
          console.log(res?.data)
          if (res?.data?.statusCode === 200) {
            toast(<SuccessToast body={'Tracking Setting Data updated'} />, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 2000,
            });
          }
          else {

          }
        });
      }
      else {
        commonServiceMethod(`app/add/tracking/setting`, "post", data, config, setLoader, (res) => {
          if (res?.status === 200) {
            toast(<SuccessToast body={'Tracking Setting Data added'} />, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 2000,
            });
            getTrackingDetails()
          }
          else {
            toast(<ErrorToast body={'Somtheing went wrong'} />, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 2000,
            });
          }
        });
      }


    } else {
      setErrors(newErrors);
    }
  };

  const enableDisable = () => {
    let enableDisable = null;
    if (formData.enable) {
      enableDisable = false;
      setFormData({ ...formData, ['enable']: false });
    }
    else {
      enableDisable = true;
      setFormData({ ...formData, ['enable']: true });
    }
    commonServiceMethod(`app/enabled/tracking?id=${formData.id}&enabled=${enableDisable}`, "post", {} , {}, setLoader, (res) => {
      console.log(res?.data)
      if (res?.data?.statusCode === 200) {
        toast(<SuccessToast body={'Tracking status updated'} />, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 2000,
        });
      }
      else {
        toast(<ErrorToast body={'Something went wrong, Please try again'} />, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 2000,
        });
      }
    });
  }
  return (
    <>
      <Loader showHide={loader} />
      <div className="parent-div">
        <form onSubmit={handleSubmit}>
          <div className="row tracking-page">
            <div className="col-md-12">
              <div className="topview mb-4">
                <div className="col-md-6">
                  <h4 className='fw-600'>Tracking</h4>
                </div>
              </div>

              <div className="tracking-details">
                <div className="row">
                  <div className="col-md-4 content">
                    <h2 className="headr">Settings</h2>
                    {/* <p className="desc">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p> */}
                  </div>
                  <div className="col-md-8 card-detail">
                    {/* Card  start*/}
                    <div className="card settings">
                      <div className='card-header'>
                        <div className='row'>
                          <div className='col-md-11 d-flex header'>
                            <h5 className='orderDetailTitle'>The order tracking page is <span className={`preview ${!formData.enable ? 'disable' : 'enable'}`}>{!formData.enable ? 'Disabled' : 'Enabled'}</span>. </h5>
                            <button type="button" disabled = {createOrUpdate !== "update"} onClick={() => enableDisable(formData.enable)} className={`enable-disable ${formData.enable ? 'disable' : 'enable'}`}>{formData.enable ? 'Disable' : 'Enable'}</button>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div >
                          <div className="copy-continer">
                            <input readOnly={true} disabled = {true} type="text" value={formData.trackingPageUrl} name="trackingPageUrl" onChange={(e) => handleChange(e)} className="link" />
                            <img onClick={() => {
                              navigator.clipboard.writeText(formData.trackingPageUrl);
                              toast(<SuccessToast body={'Coppied'} />, {
                                transition: Slide,
                                hideProgressBar: true,
                                autoClose: 2000,
                              });
                            }}
                              alt='' src={copy} />
                          </div>

                          {/* <button className="how-to-setup">How to Setup</button> */}
                          {/* <p className="warming-text">The tracking page only works for those orders which is shipped by this panel.</p> */}
                        </div>
                      </div>
                    </div>
                    {/* Card End */}
                  </div>
                </div>
              </div>

              <div className="tracking-details">
                <div className="row">
                  <div className="col-md-4 content">
                    <h2 className="headr">Appearance Settings 
                    {/* (<span className="preview">Preview</span>) */}
                    </h2>
                    {/* <p className="desc">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p> */}
                  </div>
                  <div className="col-md-8 card-detail">
                    {/* Card  start*/}
                    <div className="card">
                      <div className='card-body'>
                        <div className="row">
                          <div className="col-md-4 appearance-settings-input">
                            <label>Theme Color <span className="required">*</span></label>
                            <input value={formData.themeColor} name="themeColor" onChange={(e) => handleChange(e)} className="color" type="color" /> <input value={formData.themeColor} className="color-text" type="text" name="themeColor" onChange={(e) => handleChange(e)} />
                          </div>
                          <div className="col-md-4 appearance-settings-input">
                            <label>Button Font Color <span className="required">*</span></label>
                            <input value={formData.buttomFontColor} name="buttomFontColor" onChange={(e) => handleChange(e)} type="color" className="color" /> <input value={formData.buttomFontColor} className="color-text" name="buttomFontColor" type="text" onChange={(e) => handleChange(e)} />
                          </div>
                          <div className="col-md-4 appearance-settings-input">
                            <label>Font Color</label>
                            <input value={formData.fontColor} name="fontColor" onChange={(e) => handleChange(e)} type="color" className="color" /><input value={formData.fontColor} className="color-text" type="text" name="fontColor" onChange={(e) => handleChange(e)} />
                          </div>
                        </div>
                        {/* <div className="aditional-css">
                          <label>Additional CSS</label>
                          <textarea value={formData.additionCss} name="additionCss" onChange={(e) => handleChange(e)} ></textarea>
                        </div> */}
                      </div>
                    </div>
                    {/* Card End */}
                  </div>
                </div>
              </div>

              <div className="tracking-details">
                <div className="row">
                  <div className="col-md-4 content">
                    <h2 className="headr">Button Label & Description</h2>
                    {/* <p className="desc">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p> */}
                  </div>
                  <div className="col-md-8 card-detail">
                    {/* Card  start*/}
                    <div className="card">
                      <div className='card-body'>
                        <div className="row">
                          <div className="col-md-12 button-lable-desc">
                            <label>Description <span className="required">*</span></label>
                            <input className="" value={formData.buttionDesc} name="buttionDesc" onChange={(e) => handleChange(e)} placeholder="Enter the information" type="text" />
                          </div>
                          <div className="col-md-12 button-lable-desc">
                            <label>Button Text <span className="required">*</span></label>
                            <input className="" value={formData.buttonText} name="buttonText" onChange={(e) => handleChange(e)} placeholder="Track" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Card End */}
                  </div>
                </div>
              </div>

              <div className="tracking-details">
                <div className="row">
                  <div className="col-md-4 content">
                    <h2 className="headr">Feedback message Text</h2>
                    {/* <p className="desc">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p> */}
                  </div>
                  <div className="col-md-8 card-detail">
                    {/* Card  start*/}
                    <div className="card">
                      <div className='card-body'>
                        <div className="row">
                          <div className="col-md-12 button-lable-desc">
                            <label>Message will be display when email or phone number is wrong <span className="required">*</span></label>
                            <input className="" value={formData.emailMsg} name="emailMsg" onChange={(e) => handleChange(e)} placeholder="Enter the information" type="text" />
                          </div>
                          <div className="col-md-12 button-lable-desc">
                            <label>Message will be display when order number is wrong <span className="required">*</span></label>
                            <input className="" value={formData.orderNumberMsg} name="orderNumberMsg" onChange={(e) => handleChange(e)} placeholder="Enter the information" type="text" />
                          </div>
                          <div className="col-md-12 button-lable-desc">
                            <label>Message will be display when not shipped <span className="required">*</span></label>
                            <input className="" value={formData.notShipMsg} name="notShipMsg" onChange={(e) => handleChange(e)} placeholder="Enter the information" type="text" />
                          </div>
                          {/* <div className="col-md-12 button-lable-desc">
                            <label>Message will be displayed when order not found <span className="required">*</span></label>
                            <input className="" value={formData.buttonText} name="buttonText" onChange={(e) => handleChange(e)}  placeholder="Enter the information" type="text" />
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {/* Card End */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <p style={{ "textAlign": "right" }} ><span className="required">*</span>Mandatory fields</p>
                  {formErro > 0 && <p className="warming-text error-message">Please fill all manditary fields values</p>}
                  <button type="submit" style={{ "float": "right" }} className='btn btn-md btn-primary'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

    </>
  )
}

export default TrackingBody
