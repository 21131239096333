import React from 'react';
import PageLayout from '../../global/pageLayout';
import SettingBody from './Body';

export default function index() {
    return (
        <>
            <PageLayout tabMenu={false} body={<SettingBody />} />
        </>
    )
}
