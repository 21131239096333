import React from 'react';
import CourierBody from './body'
import PageLayout from '../../global/pageLayout';


const index = () => {
    return (
        <>
            <PageLayout tabMenu={false} body={<CourierBody />} />
        </>
    )
}

export default index
