import React from 'react';
import NewOrder from '../../../assets/newOrder.svg';
import Inprogress from '../../../assets/inProgress.svg';
import ReadyShip from '../../../assets/readyShip.svg';
import InTransit from '../../../assets/inTransit.svg'

const DashboardCount = ({ countData }) => {

    return (
        <>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body" style={{ minHeight: '100%' }}>
                        <div className='displayFlex'>
                            <div className='dashboard-card-shape'>
                                <img src={NewOrder} />
                            </div>

                            <h5 className='dashboard-card-number-title'>{countData?.newOrder === undefined ? 0 : countData?.newOrder}<br /> <span className="dashboard-card-sub-title">New Orders</span></h5>
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-md-6">
                <div className="card" >
                    <div className="card-body" style={{ minHeight: '100%' }}>
                        <div className='displayFlex'>
                            <div className='dashboard-card-shape'>
                                <img src={Inprogress} />
                            </div>
                            <h5 className='dashboard-card-number-title'>{countData?.inProgress === undefined ? 0 : countData?.inProgress}<br /> <span className="dashboard-card-sub-title">Ready to Ship</span></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card" >
                    <div className="card-body" style={{ minHeight: '100%' }}>
                        <div className='displayFlex'>
                            <div className='dashboard-card-shape'>
                                <img src={ReadyShip} />
                            </div>
                            <h5 className='dashboard-card-number-title'>{countData?.readyToShip === undefined ? 0 : countData?.readyToShip}<br /> <span className="dashboard-card-sub-title">Manifested</span></h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="card" >
                    <div className="card-body" style={{ minHeight: '100%' }}>
                        <div className='displayFlex'>
                            <div className='dashboard-card-shape'>
                                <img src={InTransit} />
                            </div>
                            <h5 className='dashboard-card-number-title'>{countData?.inTransit === undefined ? 0 : countData?.inTransit}<br /> <span className="dashboard-card-sub-title">In transit</span></h5>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DashboardCount;
