import React from 'react';
import PageLayout from '../../../global/pageLayout';
import Billing from './body';

const index = () => {
    return (
        <>
            <PageLayout tabMenu={false} body={<Billing />} />
        </>
    )
}

export default index
