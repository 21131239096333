import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Body from '../body';
import './OrdersTabMenu.scss';
import { useNavigate } from 'react-router-dom';
import { AllOrderTabDetails } from '../../../constants';

const OrdersTabMenu = ({ body, selectedItem='All' }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(selectedItem)

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(event.target.attributes['data-url'].value)
  };
  const [orderMenuData, setOrderMenuData] = useState([]);

  useEffect(() => {
    const permission = localStorage?.userPermission?.split(",") || [];
    if (AllOrderTabDetails.length > 0) {
      let finalData = AllOrderTabDetails.filter((item) => {
        return permission.includes(item.moduleId.toString())
      });
      setOrderMenuData(finalData);
    }

  }, []);
  return (
    <div className="parent-div">
      <div className="topview">
        <h4 className='fw-600' >{selectedItem === "All" ? 'Orders' : selectedItem}</h4>
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        TabIndicatorProps={{
          sx: {
            height: "5px",
          }
        }}
        indicatorColor="secondary"
        aria-label="secondary tabs example">
        {orderMenuData.map((item, i) => (
          <Tab key={i} value={item.value} data-url={item.url} label={item.label} className='tabItem'></Tab>
        ))}
      </Tabs>
      <Divider variant='fullWidth' className='divider' />
      <Body body={body} style={{ height: '100%' }} />
    </div>
  );
}

export default OrdersTabMenu;