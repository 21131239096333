import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import './style.scss';
import { useSelector } from 'react-redux';
import instance from '../../../../helpers/axiosInstance';
import { Link } from 'react-router-dom';
import Search from '../../../../assets/Iconsearch.svg';
import Calandar from '../../../../assets/Icondate.svg';
import AllFilter from '../../../../assets/Iconall-filter.svg'
// import copy from '../../../../assets/copy.png';
// import { Popup } from "../../../global/popupModal/BottomDialog";
// import DataGridPagination from '../../../global/dataGridPagination';
import Moment from 'react-moment';
import moment from 'moment';
// import shopify from '../../../../assets/shopify.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import actionButton from '../../../../assets/action-button.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import FilterMenu from "../../../global/filterComponent/FilterMenu";
import { filterOptions } from './filterObj';
import { Slide, toast } from "react-toastify";
import SuccessToast from '../../../customToast/SuccessToast';
import Loader from '../../../global/loader';
import { commonServiceMethod } from '../../../../helpers/helper';
import { Modal } from 'react-bootstrap';
import CallStatus from '../../orderDetails/callStatus';
import ErrorToast from '../../../customToast/ErrorToast';
// import { async } from 'q';
import CloseIcon from '../../../../assets/close -Icon.png';

const IntransitReturnsBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    // const [rowData, setRowData] = useState();
    // const [originalData, setOriginalData] = useState({});
    const [selectedObject, setSelectedObject] = useState([]);
    const [open, setOpen] = useState(false);

    // pagination and Filtr variables 
    const per_page = 50;
    // const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [loader, setLoader] = useState(false);
    // const [callRowID, setCallRowID] = useState('');
    const [productPopupData, setProductPopupData] = useState({ isVisible: false, data: "", callBackMethod: null });
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })
    const [, setSearchParam] = useState("");
    const [, setComlumnData] = useState([]);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    };
    // const [qtyError, setQtyError] = useState(null)

    const [values, setValues] = useState([]);

    const allocateQty = (index, qty, totalQty) => {

        if (/^\d+$/.test(qty)) {
            const newValues = [...values];
            const allocatedQtys = parseInt(qty, 10);
            // var error = '';

            if (allocatedQtys <= totalQty) {
                newValues[index] = allocatedQtys;
                // error = '';
            } else {
                newValues[index] = 0;
                // error = 'Quantity exceeded';
            }

            setValues(newValues);
            // setQtyError(error);
            return newValues;
        }
        return 0;
    };


    let columnDefsWithCall = [
        {
            headerName: 'Request ID & Date',
            field: 'order_name',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            width: 200,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {
                let productNames = param.data.orderProducts;
                return (<div>
                    <Link className='grid-link' to={`/returns/all-order/order-details/${btoa(param.data?.order_id)}`}>{param.value}</Link>
                    <p className='font-insetMedium order-date'><Moment format={"DD MMM YYYY hh:mm A"}>{param.data?.order_created_at}</Moment> </p>
                    <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={
                        <Popover id="popover-basic" className='z-index-2000 produc-list'>
                            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
                            <Popover.Body>
                                {productNames.map((i, j) => <div key={j} className='product-list-container'>
                                    <p className='p-name bold'><b>{i.product_name}</b></p>
                                    <p className='p-sku'>SKU <b>{i.sku}</b></p>
                                    <p className='p-qty'>QTY <b>{i.quantity}</b></p>
                                </div>)}
                            </Popover.Body>
                        </Popover>
                    }>
                        <p className='viewMore'>
                            View Products
                        </p>
                    </OverlayTrigger>
                </div>)
            }
        },
        {
            headerName: 'Customer',
            width: 200,
            field: 'shipping_address',
            wrapText: true,
            autoHeight: true,
            cellClass: "d-flex-center customers",
            cellRenderer(params) {
                let address = params.data?.shipping_address;
                return (
                    <div className="address-wrap">
                        <p className="customer-name">
                            {params.data?.customer_name}
                        </p>
                        <p className="address">
                            {params.data?.customer_email}
                        </p>
                        <p className="address">
                            {address.phone ? address.phone : ''}
                        </p>
                        <p className="address">
                            {address.city ? address.city : ''} - {address.zip ? address.zip : ''}
                        </p>
                    </div>
                )
            }
        },
        {
            headerName: 'Product Details',
            field: 'total_price',
            width: 165,
            cellClass: "d-flex-center",
            cellRenderer(params) {
                let payment_mode = "";
                let payment_status = "";
                if (params.data?.gateway === "Cash on Delivery (COD)") {
                    payment_mode = "COD";
                    payment_status = "info";
                } else {
                    payment_mode = "Pre-paid";
                    payment_status = "success";
                }
                return (
                    <div className='address-wrap'>
                        <p className="price_tag">{"Rs. " + params.value}</p>
                        <p className="price_tag">
                            <span className={payment_status}>{payment_mode}</span>
                        </p>
                    </div>
                )
            }
        },

        {
            headerName: 'Return Reason',
            field: 'return_reason',
            width: 245,
            wrapText: true,
            autoHeight: true,
            cellClass: "return-reason",
            cellRenderer(params) {
                return (<p>{params.value}</p>)
            }
        },
        {
            headerName: 'Status',
            field: 'app_order_status',
            width: 200,
            wrapText: true,
            autoHeight: true,
            cellClass: "app-order-status",
            cellRenderer: (params) => {
                let order_status_class = "";
                if (params.value.toLowerCase() === "cancel") {
                    order_status_class = "cancel";
                } else if (params.value.toLowerCase() === "refunded") {
                    order_status_class = "refund";
                } else if (params.value.toLowerCase() === "delivered") {
                    order_status_class = "delivered";
                } else {
                    order_status_class = "";
                }
                return (<span className={`default-status ${order_status_class}`}>
                    {params.value}
                </span>)
            }
        },

        {
            width: 100,
            field: '',
            wrapText: true,
            autoHeight: true,
            headerName: 'Actions',
            cellRenderer(param) {
                return (
                    <div className="more-action">
                        <OverlayTrigger className="action-button"  rootClose={true} trigger="click" placement="bottom" overlay={
                            <Popover id="popover-basic" className='z-index-2000 action-buttons'>
                                <Popover.Body>
                                    <div className="button-container">
                                        <button className='print-invoice' id='accept' onClick={() =>{ document.getElementsByTagName('body')[0].click(); setProductPopupData({ isVisible: true, data: param?.data, callBackMethod: acceptReturn })}}>Accept</button>
                                        <button className='print-invoice' id='cancel' onClick={() =>{ document.getElementsByTagName('body')[0].click(); showModal('cancelPopup', param?.data, cancelOrder)}}>Decline</button>
                                    </div>
                                </Popover.Body>
                            </Popover>
                        }>
                            <img alt='Action' src={actionButton} />
                        </OverlayTrigger>

                    </div>
                )
            }
        },
    ];
    useEffect(() => {
        if (userClaim.token !== "") {
            // getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
            setComlumnData([...columnDefsWithCall]);
        }
    }, [userClaim]);

    const onClearFilter = () => {
        getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), "");
    }
    const showModal = (popupName, data, callBackMethod) => {
        setPopupData({ popupName, data, callBackMethod })
    }

    // const closeModal = () => {
    //     setPopupData({ popupName: "", data: "", callBackMethod: null });
    // }


    const getData = async (
        page = 1,
        startDate,
        endDate,
        searchText,
        // perams = "&order_status="
        perams = "&order_status=return pending"
    ) => {
        var rowData = [];
        if (searchText !== "") {
            if (/^\d{10}$/.test(searchText)) {
                searchText += '&orderDetails=m'
            }
            else if (searchText.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                searchText += '&orderDetails=e'
            }
            else {
                searchText += '&orderDetails='
            }
        }
        setLoader(true);
        try {
            await instance.get(`app/order/getOrder?per_page=${per_page}&page=${page}&order_date=${startDate}&end_order_date=${endDate}&search=${searchText}${perams}`).then((res) => {

                console.log(res);
                if (res.status === 200 && Array.isArray(res?.data?.orders)) {
                    setRowData(res?.data?.orders || []);
                    setOriginalData(res?.data)
                }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    // const handlePageClick = (event) => {
    //     setPageNo(event.selected + 1);
    //     getData(
    //         event.selected + 1,
    //         moment(startDate).format('YYYY-MM-DD'),
    //         moment(endDate).format('YYYY-MM-DD'),
    //         searchText
    //     );
    // }

    // const onCellClick = (e) => {
    //     let selectedITem = e.api.getSelectedRows();
    //     console.log(selectedITem.length > 0)
    //     // setSelectedObject((selectedObject) => [...selectedObject]);
    //     selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setSelectedObject([]));
    // }
    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        getData(
            pageNo,
            date.startDate.format('YYYY-MM-DD'),
            date.endDate.format('YYYY-MM-DD'),
            searchText
        )
    }
    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onApplyFilter = (selectionOption, searchTextval = "") => {
        console.log(selectionOption)
        let url = "";
        Object.keys(selectionOption).map(i => {

            url += `&${i}=${selectionOption[i]}`
        })
        setSearchParam(url);
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchTextval || searchText,
            url
        )
        toggleDropdown();
    }

    const closeExportPopup = () => {
        setOpen(false);
        setSelectedObject([])
        gridRef.current.api.deselectAll();
    }
    // const exportMethod = () => {
    //     var objdata = {};
    //     const config = {};
    //     objdata.order_id = selectedObject.map((i) => i.order_id).join();
    //     commonServiceMethod("app/order/export", "post", objdata, config, setLoader, (res) => {
    //         console.log("res", res)
    //         const downloadUrl = window.URL.createObjectURL(
    //             new Blob([res.data])
    //         );
    //         console.log(downloadUrl)
    //         const link = document.createElement("a");
    //         link.href = downloadUrl;
    //         link.setAttribute("download", "file.csv"); //any other extension
    //         document.body.appendChild(link);
    //         link.click();
    //         link.remove();
    //         closeExportPopup();
    //     }, () => {
    //         toast(<ErrorToast body={'Something went wrong, please try again'} />, {
    //             transition: Slide,
    //             hideProgressBar: true,
    //             autoClose: 2000,
    //         });
    //     });
    // }
    const printInvoiceMethod = (obj = []) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        var objdata = {};
        objdata.order_id = obj.map((i) => i.order_id).join();
        commonServiceMethod(`app/order/print-invoice/${objdata.order_id}?templateName=temp1`, "post", {}, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
            closeExportPopup();
        }, () => {
            toast(<ErrorToast body={'Something went wrong, please try again'} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        });
    }

    const cancelOrder = (obj, item) => {
        const config = {};
        commonServiceMethod("app/orders/cancel", "post", obj, config, setLoader, (res) => {
            if (res.data.statusCode === 200) {
                toast(<SuccessToast body={'This order is ' + item.order_name + ' cancelled'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
                handleClose();
            }
        }, () => {
            toast(<ErrorToast body={'Something went wrong, please try again'} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        });
    }
    // const gridReadyEvent = () =>{
    //     let allColumnIds = [];
    //     gridRef.current.columnApi.getColumns().forEach((column) => {
    //         allColumnIds.push(column.getId());
    //     });
    //     gridRef.current.columnApi.autoSizeColumns(allColumnIds, true);
    // }
    const acceptReturn = (items) => {
        let obj = {
            "orderId": items.id,
            "lineItem": []
        };
        items.orderProducts.map(i => {
            if (i.hasOwnProperty('selectedQuantity') && i.selectedQuantity > 0)
                obj.lineItem.push({
                    lineItem: i.line_item_id,
                    qty: i.selectedQuantity
                })
        })
        const config = {};
        commonServiceMethod("/app/init/return", "post", obj, config, setLoader, (res) => {
            if (res.data.statusCode === 200) {
                toast(<SuccessToast body={'Return accepted for #' + obj.orderId+ ''} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
                handleClose();
            }
        }, () => {
            toast(<ErrorToast body={'Something went wrong, please try again'} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        });
    }
    const handleClose = () => {
        setProductPopupData({ isVisible: false, data: {}, callBackMethod: null });
    }
    return (
        <>
            <Loader showHide={loader} />
            {open ? 
            <div className={`popup-container grid-checkbox-popuop`}>
                <div className="popup-body">
                    <div className="left-container">
                        <span className="selected-item"> {selectedObject.length} items Seleted</span>
                    </div>
                    <div className="right-container">
                        <button type='button' onClick={() => showModal('courierPartner', selectedObject, assignCourier)} className='btn btn-sm  asign-courier filter-btn'>Assign Courier</button>
                        <button type='button' onClick={() => printInvoiceMethod(selectedObject)} className='btn btn-sm  print-invoice filter-btn'>Print Invoice</button>
                        <button type='button' onClick={() => showModal('cancelPopup', selectedObject, cancelOrder)} className='btn btn-sm cancel-button filter-btn'>Cancel Order</button>
                        <Link to={''} className='close-icon' onClick={() => { setOpen(false); gridRef.current.api.deselectAll(); }} > <img src={CloseIcon}/>  </Link>
                    </div>
                </div>
            </div>
            
            // <Popup exportMethod={exportMethod} printInvoiceMethod={printInvoiceMethod} selecteObject={selectedObject} closePopup={() => { closeExportPopup(); }} /> 
            : null}
            <div className='row mb-2 mt-4 filter-section'>
                <div className="filter-left-container">
                    <div className="form-group">
                        <form onSubmit={searchTextChangeEvent}>
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Search} /></span>
                            </div>
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search"
                                className='form-control inputWithIcon'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </form>
                    </div>
                </div>
                <div className='filter-right-container'>
                    <div className="form-group datepicker-class">
                        <div style={{ position: 'relative' }}>
                            <span className="icon"><img alt='' src={Calandar} /></span>
                        </div>
                        <DateRangePicker
                            className="datepicker-custom"
                            initialSettings={
                                {
                                    startDate: startDate,
                                    endDate: endDate,
                                    maxDate: moment().toDate(),
                                    showDropdowns: true,
                                    locale: {
                                        format: 'MMM DD, YYYY',
                                    },
                                    ranges: {
                                        Today: [moment().toDate(), moment().toDate()],
                                        Yesterday: [
                                            moment().subtract(1, 'days').toDate(),
                                            moment().subtract(1, 'days').toDate(),
                                        ],
                                        'Last 7 Days': [
                                            moment().subtract(6, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Last 30 Days': [
                                            moment().subtract(30, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'This Month': [
                                            moment().startOf('month').toDate(),
                                            moment().endOf('month').toDate(),
                                        ],
                                        'Last Month': [
                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                        ],
                                    },
                                }}
                            onApply={(e, val) => dateChangeEvent(val, e)}
                        >
                            <input type="text" className="form-control datepicker-range" />
                        </DateRangePicker>
                    </div>
                    <div className='dropdown-dialog-parent'>
                        <div className={` dropdown-dialog ${isOpen ? 'open' : ''}`}>
                            <button type='button' className='btn btn-sm  filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 15 }} src={AllFilter} /> All Filters</button>
                            {isOpen && (
                                <>
                                    <FilterMenu filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div >
            <div style={{"marginTop":"50px","textAlign":"center"}}>
                No records Found
            </div>
            {/* <DataGridPagination
                rowData={rowData}
                columnDefs={columnData}
                onSelectionChanged={onCellClick}
                gridref={gridRef}
                rowSelection={"multiple"}
                domLayout="autoHeight"
                onPageChange={handlePageClick}
                pageRangeDisplayed={originalData.per_page}
                pageCount={originalData.count}
            /> */}

            {popupData.popupName !== "" &&
                <CallStatus
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={handleClose}
                />
            }


            <Modal
                size='lg'
                show={productPopupData.isVisible}
                onHide={handleClose}
                centered
                scrollable
                backdrop="static"
            >
                <div className='modal-content modal-lg return-accept-popup'>
                    <div className="modal-header">
                        <h5 className="modal-title">Return Product</h5>
                        <p className='sub-title'>Select Product to return &nbsp;
                            {/* <span className='error mb-2'>{qtyError}</span> */}
                        </p>

                    </div>
                    <div className="modal-body modal-lg">
                        <div className='container'>

                            {productPopupData.data?.orderProducts?.length > 0 && productPopupData.data.orderProducts.map((items, index) => {
                                var totalPrice = values[index] * parseFloat(items.price);
                                return (
                                    <React.Fragment key={index}>
                                        <div className='row mb-2'>
                                            <div className='col-md-2'>
                                                <div className='imgBox'></div>
                                            </div>
                                            <div className='col-md-5'>
                                                <p className='pTitle'>{items.product_name}</p>
                                                <p className='pSubTitle'>{items.sku}</p>
                                                <p className='pSubTitle'>₹ {items.price}</p>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className='flex justify-content-between'>
                                                    <input
                                                        type='number'
                                                        className='col-md-4 priceBox'
                                                        onChange={(e) => {
                                                            let quantity = allocateQty(index, e.target.value, items.quantity, items);
                                                            productPopupData.data.orderProducts[index].selectedQuantity = quantity[index];
                                                        }}
                                                        value={values[index]}
                                                        placeholder={0}
                                                    />
                                                    &nbsp;/&nbsp;{items.quantity}
                                                </div>
                                            </div>

                                            <div className='col-md-2'>
                                                ₹ {isNaN(totalPrice) ? '0.00' : totalPrice.toFixed(2)}
                                            </div>
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                );
                            })}

                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="col-12 mb-4 d-flex justify-content-end">
                        <button className="btn btn-sm filter-btn" onClick={handleClose} type="button" >Cancel</button>
                        <button className="btn btn-sm btn-primary" disabled={!values.filter(a => a > 0).length > 0} onClick={() => productPopupData.callBackMethod(productPopupData.data)}>Accept Return</button>
                    </div>

                </div>

            </Modal>

        </>
    );
}

export default IntransitReturnsBody;

