import React from 'react';
import ChannelBody from './body';
import PageLayout from '../../global/pageLayout';

const index = () => {
    return (
        <>
            <PageLayout tabMenu={false} body={<ChannelBody />} />
        </>
    )
}

export default index
