import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom'
import PackageIcon from '../../../assets/packageIcon.svg'

const PackageDetails = ({ orderDetail }) => {
    const [orderDetailData, setOrderDetail] = useState({});
    useEffect(() => {
        setOrderDetail(orderDetail?.order?.shipment || {});
    }, [orderDetail])
    return (
        <>
            <div className="card">
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-md-11 d-flex'>
                            <span className='card-icons'><img src={PackageIcon} alt='PackageIcon' /></span>
                            <h5 className='orderDetailTitle'>Package Details</h5>
                        </div>

                        {/* <div className='col-md-1 no-padding'>
                            <Link to={''}><i className="bi bi-pencil-square" style={{ fontSize: '20px' }} ></i></Link>
                        </div> */}
                    </div>
                </div>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="textLabel">Weight (in kg)</label>
                            <p>{orderDetailData?.weight}</p>
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Dimensions</label>
                            {/* <p>12.44 x 234.5 x 12</p> */}
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Volumetric Weight (in kg)</label>
                            <p>{orderDetailData?.weight}</p>
                        </div>
                        <div className="col-md-4">
                            <label className="textLabel">Applied Weight (in kg)</label>
                            <p>{orderDetailData?.weight}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PackageDetails