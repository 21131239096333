import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from "react-bootstrap";

const ProductDetailsModal = ({ item, show, handleClose, handleSave }) => {
    const [productData, setProductData] = useState({});

    useEffect(() => {
        setProductData(item)
    }, [])

    return (<>

        <Modal
            size='md'
            show={show === 'productEdit'}
            onHide={handleClose}
            centered
            scrollable
        >

            <div className='modal-content modal-md'>
                <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="modal-title">Edit Shipping Address</h5>
                </div>
                <div className="modal-body modal-md">

                    {Object.values(productData).map((data) => {
                        return (<>

                            <div className='row' key={data?.id}>
                                <div className='col-md-6'>
                                    <label className='userLabel'>Weight (in Kg)</label>
                                    <input type='text' name="weight" className='form-control' value={data?.weight} />
                                </div>
                                <div className='col-md-6'>
                                    <label className='userLabel'>GST (%)</label>
                                    <input type='text' name="gst" className='form-control' value={data?.weight} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label className='userLabel'>HSN</label>
                                    <input type='text' name="hsn" className='form-control' value={data?.weight} />
                                </div>

                            </div>
                        </>)
                    })}


                </div>

            </div>

            <div className="modal-footer">

                <div className="col-12 mb-4 d-flex justify-content-end">
                    <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm btn-primary" onClick={() => handleSave()} type="submit" >Save</button>

                </div>

            </div>

        </Modal>
    </>)

}

export default ProductDetailsModal;