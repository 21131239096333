import React, { useMemo, useRef } from 'react';
import '../../orderDetails/orderDetails.scss';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import allOrders from '../../../../assets/allOrders.svg'

const AllOrders = () => {

    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const agGrid = useRef(null);
    const rowData = [
        {
            name: 'Product name',
            category: 'Category',
            hsn: '21784',
            sku: '234DDS',
            qty: '01',
            unitprice: '₹ 1186.44',
            discount: '₹ 0',
            tax: '₹ 200',
            total: '₹ 1,400.00',
        },
        {
            name: 'Product name',
            category: 'Category',
            hsn: '21784',
            sku: '234DDS',
            qty: '01',
            unitprice: '₹ 1186.44',
            discount: '₹ 0',
            tax: '₹ 200',
            total: '₹ 1,400.00',
        },
        {
            name: 'Product name',
            category: 'Category',
            hsn: '21784',
            sku: '234DDS',
            qty: '01',
            unitprice: '₹ 1186.44',
            discount: '₹ 0',
            tax: '₹ 200',
            total: '₹ 1,400.00',
        },
    ]

    const columnDefs = [
        {
            headerName: 'Name',
            field: 'name',
            wrapText: true,
            autoHeight: true,
            width: 150,
        },
        {
            headerName: 'Category',
            field: 'category',
            wrapText: true,
            autoHeight: true,
            width: 100,
        },
        {
            headerName: 'HSN',
            field: 'hsn',
            wrapText: true,
            autoHeight: true,
            width: 75,
        },
        {
            headerName: 'SKU',
            field: 'sku',
            wrapText: true,
            autoHeight: true,
            width: 120,
        },
        {
            headerName: 'Qty',
            field: 'qty',
            wrapText: true,
            autoHeight: true,
            width: 75,
        },
        {
            headerName: 'Unit price',
            field: 'unitprice',
            wrapText: true,
            autoHeight: true,
            width: 120,
        },
        {
            headerName: 'Discount',
            field: 'discount',
            wrapText: true,
            autoHeight: true,
            width: 100,
        },
        {
            headerName: 'Tax',
            field: 'tax',
            wrapText: true,
            autoHeight: true,
            width: 100,
        },
        {
            headerName: 'Total',
            field: 'total',
            wrapText: true,
            autoHeight: true,
            width: 150,
        },
    ];
    return (
        <>
            <div className="card all-orders">
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-md-10 d-flex'>
                            <span className='card-icons'>
                                <img src={allOrders} alt='allOrders' />
                            </span>
                            <h5 className='orderDetailTitle'>All Orders</h5>
                        </div>

                        <div className='col-md-2 no-padding'>

                        </div>
                    </div>
                </div>
                <div className={`card-bodys order-detail-page-product-grid`}>
                    <div>
                        <div style={gridStyle} className="ag-theme-alpine">
                            <AgGridReact
                                ref={agGrid}
                                columnDefs={columnDefs}
                                rowData={rowData}
                                domLayout={'autoHeight'}
                            // autoGroupColumnDef={autoGroupColumnDef}
                            // groupIncludeFooter={true}
                            // groupIncludeTotalFooter={true}
                            />
                        </div>
                    </div>

                </div>
                <div className='card-footer'>
                    <div className='d-flex justify-content-end'>
                        <span className='total'>Product Total (3 items)</span> &nbsp; <span className='total-amount'> ₹ 4,200.00</span>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AllOrders
