import React, { useEffect, useState } from "react";
import Loader from "../../global/loader";
import { useSelector } from 'react-redux';
import SuccessToast from "../../customToast/SuccessToast";
import { Slide, toast } from "react-toastify";
import { commonServiceMethod } from "../../../helpers/helper";
import UploadImg from '../../../assets/Iconupload.png';
import ErrorToast from "../../customToast/ErrorToast";
import { Link } from "react-router-dom";
import backArrow from '../../../assets/backArrow.svg';

function Body() {
  const userClaim = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    store_name: '',
    gst: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    pincode: '',
    country: '',
    province_code: '',
    email: '',
    phoneno: '',
    package_name: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    logoUrl: ''
  });
  const [errors, setErrors] = useState({});
  const [previewImg, setPreviewImg] = useState(null);
  const [createOrUpdate, setCreateOrUpdate] = useState("");
  useEffect(() => {
    // setRefreshData(0);
    if (userClaim.token !== "") {
      getStoreDetails();
    }
  }, []);

  const getStoreDetails = () => {
    const config = {};
    commonServiceMethod("app/get/setting", "get", {}, config, setLoader, (res) => {
      console.log(res?.data?.data)
      if (res?.data?.statusCode === 200) {
        if (res?.data?.data?.hasOwnProperty('store_name')) {
          setCreateOrUpdate("update");
          setFormData({ ...res?.data?.data });
        }
        else {
          setCreateOrUpdate("create");
        }
      }
      else {
        console.error(res?.data)
        toast(<ErrorToast body={'Something went wrong'} />, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 2000,
        });
      }

    });
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const validate = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let newErrors = {};

    if (formData.email !== '') {
      if (!validate(formData.email)) {
        newErrors.email = 'Invalid email address';
      }
    }
    else {
      newErrors.email = "Email is required";
    }

    if (!formData.store_name) {
      newErrors.store_name = "Legal Name is required";
    }
    if (!formData.logoUrl) {
      newErrors.logoUrl = "Logo image is required";
    }
    if (!formData.gst) {
      newErrors.gst = "GST No is required";
    }

    if (!formData.logoUrl) {
      newErrors.gst = "Logo is required";
    }

    if (!formData.address1) {
      newErrors.address1 = "Address 1 is required";
    }

    if (!formData.city) {
      newErrors.city = "City is required";
    }

    if (!formData.state) {
      newErrors.state = "State is required";
    }

    if (!formData.pincode) {
      newErrors.pincode = "Pin Code is required";
    }

    if (!formData.country) {
      newErrors.country = "Country is required";
    }

    if (!formData.province_code) {
      newErrors.province_code = "Province Code is required";
    }

    if (!formData.phoneno) {
      newErrors.phoneno = "Phone is required";
    }

    if (!formData.package_name) {
      newErrors.package_name = "Package Name is required";
    }

    if (!formData.length) {
      newErrors.length = "Length is required";
    }

    if (!formData.width) {
      newErrors.width = "Width is required";
    }

    if (!formData.height) {
      newErrors.height = "Height is required";
    }

    if (!formData.weight) {
      newErrors.weight = "Weight is required";
    }

    if (Object.keys(newErrors).length === 0) {
      if (formData.logoUrl)
        stoereImage(formData);

    } else {
      setErrors(newErrors);
    }
  };

  const submitEvent = (formData) => {
    console.log('Form data is valid:', formData);
    let data = { ...formData };
    const config = {};
    if (createOrUpdate === "update") {
      commonServiceMethod(`app/update/setting`, "put", data, config, setLoader, (res) => {
        console.log(res?.data)
        if (res?.data?.statusCode === 200) {
          toast(<SuccessToast body={'Store Details updated'} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
          });
        }
        else {
          console.error(res?.data)
          toast(<ErrorToast body={'Something went wrong'} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
          });
        }
      });
    }
    else {
      commonServiceMethod(`app/add/setting`, "post", data, config, setLoader, (res) => {
        console.log(res?.data)
        if (res?.data?.statusCode === 200) {
          toast(<SuccessToast body={'Store Details added'} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
          });
        }
        else {
          console.error(res?.data)
          toast(<ErrorToast body={'Something went wrong'} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
          });
        }
      });
    }
  }
  const stoereImage = (formData) => {
    if (typeof formData.logoUrl !== 'object') {
      submitEvent(formData);
    }
    else {
      const formdataImage = new FormData();
      formdataImage.append("file", formData.logoUrl[0]);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      commonServiceMethod(`app/upload/file`, "post", formdataImage, config, setLoader, (res) => {
        console.log(res)
        if (res?.status === 200) {
          const data = { ...formData };
          data.logoUrl = res?.data?.url;
          submitEvent(data);
        }
        else {
          console.error(res?.data)
          toast(<ErrorToast body={'Something went wrong'} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
          });
        }
      });
    }
  }
  const imageuploadChange = (e) => {
    setFormData({ ...formData, ['logoUrl']: e.target.files });
    if (e.target.files[0]) {
      setPreviewImg(URL.createObjectURL(e.target.files[0]));
    }
  }
  return (
    <>
      <Loader showHide={loader} />
      <div className="parent-div">
        <div className="row">
          <div className='col-md-9 mb-3'>
            <Link to={'/settings'}>
              <img src={backArrow} /> Back
            </Link>
          </div>
          <div className="col-md-9">
            <div className="topview mb-4">
              <div className="col-md-6">
                <h4 className='fw-600'>Store Details</h4>
              </div>
            </div>

            <form onSubmit={handleSubmit}>

              <div className="card">
                <div className='card-header'>
                  <div className='row'>
                    <div className='col-md-11 d-flex'>
                      <span className='card-icons'></span>
                      <h5 className='orderDetailTitle'>General </h5>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Seller Name <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='store_name' value={formData.store_name} onChange={(e) => handleChange(e)} />
                      {errors.store_name && <p className="error">{errors.store_name}</p>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>GST No <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='gst' value={formData.gst} onChange={(e) => handleChange(e)} />
                      {errors.gst && <p className="error">{errors.gst}</p>}
                    </div>
                  </div>
                  <div className='row'>
                    <label className='setting-courier-model-label'>Store Logo <span style={{ color: 'red' }}>*</span></label>
                    <div className="file-upload">
                      <div className="image-upload-wrap">
                        <input className="file-upload-input" onChange={(e) => imageuploadChange(e)} name="logoUrl" type='file' accept="image/*" />
                        <div className="drag-text">
                          <img src={UploadImg} />
                          <p>Upload or Drag and Drop Store logo image (png, jpeg, svg under 600kb)</p>
                        </div>
                      </div>
                      <div className="uploaded-img">
                        {formData.logoUrl && <img src={previewImg || formData.logoUrl} />}
                      </div>
                      <div className="file-upload-content">
                        <img className="file-upload-image" src={previewImg} alt="your image" />
                        <div className="image-title-wrap">
                          <button type="button" className="remove-image">Remove <span className="image-title">Uploaded Image</span></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="card">
                <div className='card-header'>
                  <div className='row'>
                    <div className='col-md-11 d-flex'>
                      <span className='card-icons'></span>
                      <h5 className='orderDetailTitle'>Shipping/Return Address </h5>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='row'>

                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Address Line 1 <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='address1' value={formData.address1} onChange={(e) => handleChange(e)} />
                      {errors.address1 && <p className="error">{errors.address1}</p>}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Address Line 2 </label>
                      <input type='text' className='form-control' name='address2' value={formData.address2} onChange={(e) => handleChange(e)} />
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>City <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='city' value={formData.city} onChange={(e) => handleChange(e)} />
                      {errors.city && <p className="error">{errors.city}</p>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>State <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='state' value={formData.state} onChange={(e) => handleChange(e)} />
                      {errors.state && <p className="error">{errors.state}</p>}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>pincode <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='pincode' value={formData.pincode} onChange={(e) => handleChange(e)} />
                      {errors.pincode && <p className="error">{errors.pincode}</p>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Country <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='country' value={formData.country} onChange={(e) => handleChange(e)} />
                      {errors.country && <p className="error">{errors.country}</p>}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Province Code <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='province_code' value={formData.province_code} onChange={(e) => handleChange(e)} />
                      {errors.province_code && <p className="error">{errors.province_code}</p>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Email <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='email' value={formData.email} onChange={(e) => handleChange(e)} />
                      {errors.email && <p className="error">{errors.email}</p>}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Phone No. <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='phoneno' value={formData.phoneno} onChange={(e) => handleChange(e)} />
                      {errors.phoneno && <p className="error">{errors.phoneno}</p>}
                    </div>
                  </div>
                </div>

              </div>

              <div className="card">
                <div className='card-header'>
                  <div className='row'>
                    <div className='col-md-11 d-flex'>
                      <span className='card-icons'></span>
                      <h5 className='orderDetailTitle'>General Packaging Dimensions </h5>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='row'>

                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Package Name <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='package_name' value={formData.package_name} onChange={(e) => handleChange(e)} />
                      {errors.package_name && <p className="error">{errors.package_name}</p>}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Length(CM)  <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='length' value={formData.length} onChange={(e) => handleChange(e)} />
                      {errors.length && <p className="error">{errors.length}</p>}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Width(CM) <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='width' value={formData.width} onChange={(e) => handleChange(e)} />
                      {errors.width && <p className="error">{errors.width}</p>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Height(CM) <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='height' value={formData.height} onChange={(e) => handleChange(e)} />
                      {errors.height && <p className="error">{errors.height}</p>}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label className='setting-courier-model-label'>Weight(KG) <span style={{ color: 'red' }}>*</span></label>
                      <input type='text' className='form-control' name='weight' value={formData.weight} onChange={(e) => handleChange(e)} />
                      {errors.weight && <p className="error">{errors.weight}</p>}
                    </div>

                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-md-1 me-5">
                  <button type="submit" className='btn btn-md btn-primary'>Submit</button>
                </div>
                <div className="col-md-1">
                  <button type="reset" className='btn btn-md filter-btn'>Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  )
}

export default Body
