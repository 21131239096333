import React from 'react';
import './toast.scss';

const SuccessToast = ({ header = 'Success', body }) => {
    return (
        <div className='base-toast-div'>
            <i className="bi bi-check-circle custom-toast-succes-icon"></i>
            <div style={{ marginLeft: '10px' }}>
                <div className='base-toast-header'>
                    <div>
                        <h6 className='custom-toast-title-success'>{header}</h6>
                    </div>
                </div>
                <div className='custom-toast-body-message'>
                    <span>{body}</span>
                </div>
            </div>
        </div>
    );
};

export default SuccessToast;
