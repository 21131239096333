import React, { useRef, useState } from 'react'

const Filter = ({ sortBy, className }) => {

    const ref = useRef(null);
    const [, setSelectionOption] = useState({});

    const optionSelect = (e, option) => {
        setSelectionOption(option.key, option.value);
    };

    return (
        <div className={`accordion-wrapper ${className}`}>
            <ul className="dropdown-list customer-filter-dialog">
                <li className='mt-3'></li>
                <li className="header">{sortBy[0]?.title}</li>
                <li><hr /></li>
                <li>
                    <form ref={ref}>
                        {
                            Object.values(sortBy[0]?.label).map((option) => {
                                return (<>
                                    <p className="menuLink" key={option?.id}>
                                        <label className="form-check-label  checkboxLabel">
                                            <input type="radio" className="form-check-input" name={option.key} value={option?.menu} onChange={(e) => optionSelect(e, option)} />
                                            &nbsp; &nbsp;{option?.menu}</label>
                                    </p>
                                </>)
                            })
                        }
                    </form>
                </li>

            </ul>
        </div>
    )
}

export default Filter