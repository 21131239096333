import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { useSelector } from 'react-redux';
import instance from '../../../helpers/axiosInstance';
import Search from '../../../assets/Iconsearch.svg';
import Calandar from '../../../assets/Icondate.svg';
import AllFilter from '../../../assets/Iconall-filter.svg';
import DataGridPagination from '../../global/dataGridPagination';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import FilterMenu from "../../global/filterComponent/FilterMenu";
import { filterOptions } from './filterObj';
import './intransit.scss';
import Loader from '../../global/loader';
import SuccessToast from '../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import copy from '../../../assets/copy.png';
// import refreshIcon from '../../../assets/dashboard/refreshIcon.png';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
// import Moment from 'react-moment';

const InTransitBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const [rowData, setRowData] = useState();
    const [originalData, setOriginalData] = useState({});

    // pagination and Filtr variables 
    const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [loader, setLoader] = useState(false);


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    };

    // const resss = {
    //     "per_page": 50,
    //     "status_code": 200,
    //     "count": 19,
    //     "page": 1,
    //     "shipments": [
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "Delhivery",
    //             "name": "DEYGA EXPRESS",
    //             "tracking_number": "3163414076822",
    //             "created_at": "2023-02-04 07:39:46",
    //             "customer_name": "Ronak ",
    //             "tracking_url": "https://www.delhivery.com/track/package/3163414076822",
    //             "order_id": "5149508206841",
    //             "order_name": "#1106"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "80084841841",
    //             "created_at": "2023-02-02 03:45:24",
    //             "customer_name": "Pavithran R",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=80084841841",
    //             "order_id": "5130169614585",
    //             "order_name": "#1089"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81123366645",
    //             "created_at": "2023-02-02 03:45:23",
    //             "customer_name": "Pavithran R",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81123366645",
    //             "order_id": "5138874958073",
    //             "order_name": "#1091"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81123366623",
    //             "created_at": "2023-02-02 03:45:22",
    //             "customer_name": "Pavithran R",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81123366623",
    //             "order_id": "5138876236025",
    //             "order_name": "#1092"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81123366590",
    //             "created_at": "2023-02-02 03:45:21",
    //             "customer_name": "Test R",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81123366590",
    //             "order_id": "5138877448441",
    //             "order_name": "#1093"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81123366553",
    //             "created_at": "2023-02-02 03:45:19",
    //             "customer_name": "Ronak ",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81123366553",
    //             "order_id": "5141826306297",
    //             "order_name": "#1100"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81123366520",
    //             "created_at": "2023-02-02 03:45:17",
    //             "customer_name": "Ronak ",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81123366520",
    //             "order_id": "5147519451385",
    //             "order_name": "#1103"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81123327493",
    //             "created_at": "2023-02-02 02:08:43",
    //             "customer_name": "Ronak ",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81123327493",
    //             "order_id": "5147552907513",
    //             "order_name": "#1105"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81123327482",
    //             "created_at": "2023-02-02 02:08:42",
    //             "customer_name": "Ronak ",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81123327482",
    //             "order_id": "5147523907833",
    //             "order_name": "#1104"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81117511241",
    //             "created_at": "2023-01-28 04:46:50",
    //             "customer_name": "Ronak ",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81117511241",
    //             "order_id": "5141817590009",
    //             "order_name": "#1098"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81117511215",
    //             "created_at": "2023-01-28 04:46:49",
    //             "customer_name": "Ronak ",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81117511215",
    //             "order_id": "5141818048761",
    //             "order_name": "#1099"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "DTDC",
    //             "name": "DEYGA EXPRESS DTDC",
    //             "tracking_number": "X82157077",
    //             "created_at": "2023-01-25 10:44:28",
    //             "customer_name": "Pavithran R",
    //             "tracking_url": "https://tracking.dtdc.com/ctbs-tracking/customerInterface.tr?submitName=showCITrackingDetails&cType=Consignment&cnNo=X82157077",
    //             "order_id": "5138873123065",
    //             "order_name": "#1090"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "Delhivery",
    //             "name": "DEYGA EXPRESS",
    //             "tracking_number": "3163414020516",
    //             "created_at": "2023-01-25 10:44:08",
    //             "customer_name": "Karthigeyan S",
    //             "tracking_url": "https://www.delhivery.com/track/package/3163414020516",
    //             "order_id": "5138879742201",
    //             "order_name": "#1094"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "Delhivery",
    //             "name": "DEYGA EXPRESS",
    //             "tracking_number": "3163414020505",
    //             "created_at": "2023-01-25 10:44:08",
    //             "customer_name": "test test",
    //             "tracking_url": "https://www.delhivery.com/track/package/3163414020505",
    //             "order_id": "5138887311609",
    //             "order_name": "#1096"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81115109294",
    //             "created_at": "2023-01-25 10:44:00",
    //             "customer_name": "test test",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81115109294",
    //             "order_id": "5138886066425",
    //             "order_name": "#1095"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "80077990705",
    //             "created_at": "2023-01-25 10:43:58",
    //             "customer_name": "test test",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=80077990705",
    //             "order_id": "5138887966969",
    //             "order_name": "#1097"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "BlueDart",
    //             "name": "DEYGA ORGANICS",
    //             "tracking_number": "81095373343",
    //             "created_at": "2023-01-06 04:34:16",
    //             "customer_name": "Ronak ",
    //             "tracking_url": "https://www.bluedart.com/web/guest/trackdartresultthirdparty?trackFor=0&trackNo=81095373343",
    //             "order_id": "5119160615161",
    //             "order_name": "#1088"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "Delhivery",
    //             "name": "DEYGA EXPRESS",
    //             "tracking_number": "3163413856495",
    //             "created_at": "2023-01-03 04:09:23",
    //             "customer_name": "Ronak ",
    //             "tracking_url": "https://www.delhivery.com/track/package/3163413856495",
    //             "order_id": "5115994210553",
    //             "order_name": "#1086"
    //         },
    //         {
    //             "store_id": "61499441401",
    //             "ecom_name": "Delhivery",
    //             "name": "DEYGA EXPRESS",
    //             "tracking_number": "3163413856484",
    //             "created_at": "2023-01-03 04:09:11",
    //             "customer_name": "Ronak ",
    //             "tracking_url": "https://www.delhivery.com/track/package/3163413856484",
    //             "order_id": "5115994800377",
    //             "order_name": "#1087"
    //         }
    //     ]
    // }
    const columnDefs = [
        {
            headerName: 'Order Id',
            field: 'order_name',
            wrapText: true,
            autoHeight: true,
            width: 200,
            cellClass: "order-id",
            cellRenderer: (param) => {
                return (<>
                    <Link className='grid-link' to={`/orders/intrasit/order-details/${btoa(param.data?.order_id)}`}>{param.value}</Link>
                    <p><Moment format={"DD MMM YYYY hh:mm A"}></Moment></p>
                </>)
            }
        },
        {
            headerName: "Customer",
            field: 'customer_name',
            width: 150,
            wrapText: true,
            autoHeight: true,
            cellClass: "customer-name",
            cellRenderer: (params) => {
                return (<span>{params.value}</span>)
            }
        },
        {
            headerName: 'Carrier',
            field: 'ecom_name',
            width: 170,
            wrapText: true,
            autoHeight: true,
            cellClass: "carrier-data",
            cellRenderer(param) {
                return (<><p className='carrier'>{param.value}</p><span className={`delivery-id`}>#{param.data.tracking_number || ""} <img onClick={() => {
                    navigator.clipboard.writeText(param.data.tracking_number);
                    toast(<SuccessToast body={'Coppied'} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }}
                    alt='' src={copy} /></span></>)
            }
        },
        {
            headerName: 'Tracking Id',
            width: 150,
            wrapText: true,
            autoHeight: true,
            field: 'tracking_number',

        },
        {
            headerName: 'Warehouse',
            field: 'name',
            width: 170,
            wrapText: true,
            autoHeight: true,
            cellClass: "",

        },
        {
            headerName: 'Ship Date',
            width: 200,
            field: 'created_at',
            wrapText: true,
            autoHeight: true,
            cellRenderer(param) {
                return (
                    <Moment format={"DD MMM YYYY hh:mm A"}>{param.value}</Moment>
                )
            }
        },
        {
            headerName: 'Weight',
            field: 'weight',
            width: 110,
            wrapText: true,
            autoHeight: true,
        }
    ];


    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
        }
    }, [userClaim]);

    const onClearFilter = () => {
        getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), "");
    }
    const getData = (
        page = 1,
        startDate,
        endDate,
        searchText,
        params = '&carrier='
    ) => {
        var rowData = [];
        if(searchText !== ""){
            if(/^\d{10}$/.test(searchText)){
                searchText+='&orderDetails=m'
            }
            else if(searchText.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )){
                searchText+='&orderDetails=e'
            }
            else{
                searchText+='&orderDetails='
            }
        }
        try {
            setLoader(true);
            instance.get(`app/shipment/get_shipment?per_page=${per_page}&page=${page}&start_date=${startDate}&end_date=${endDate}&search=${searchText}&flag=true${params}`).then((res) => {

                console.log(res?.data)

                // console.log(resss.shipments)
                // if (res.status === 200 && Array.isArray(res?.data?.shipments)) {
                setRowData(res?.data.shipments || []);
                // setOriginalData(resss)
                // }
                // if (res.status === 200 && Array.isArray(res?.data?.shipments)) {
                //     setRowData(res?.data?.shipments || []);
                //     setOriginalData(res?.data)
                // }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
                setOriginalData({});
                setRowData([]);
            })
        }
        catch (e) {
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        getData(
            pageNo,
            date.startDate.format('YYYY-MM-DD'),
            date.endDate.format('YYYY-MM-DD'),
            searchText
        )
    }
    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }
    const onApplyFilter = (selectionOption, searchTextval = "") => {
        console.log(selectionOption)
        let url = "";
        Object.keys(selectionOption).map(i => {

            url += `&${i}=${selectionOption[i]}`
        })
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchTextval || searchText,
            url
        );
        toggleDropdown();
    }
    console.log(rowData)
    return (
        <>
            <Loader showHide={loader} />
            {/* <div className='quick-buttons'>
                <button className='syncBtn'> <img src={refreshIcon} className="refreshIcon"></img> Sync Orders</button>
            </div> */}
            <div className='row mb-2 mt-4 filter-section'>
                <div className="filter-left-container">
                    <div className="form-group">
                        <form onSubmit={searchTextChangeEvent}>
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Search} /></span>
                            </div>
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search"
                                className='form-control inputWithIcon'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </form>
                    </div>
                </div>
                <div className='filter-right-container'>
                    <div className="form-group datepicker-class">
                        <div style={{ position: 'relative' }}>
                            <span className="icon"><img alt='' src={Calandar} /></span>
                        </div>
                        <DateRangePicker
                        className="datepicker-custom"
                            initialSettings={
                                {
                                    startDate: startDate,
                                    endDate: endDate,
                                    maxDate: moment().toDate(),
                                    showDropdowns: true,
                                    locale: {
                                        format: 'MMM DD, YYYY',
                                    },
                                    ranges: {
                                        Today: [moment().toDate(), moment().toDate()],
                                        Yesterday: [
                                            moment().subtract(1, 'days').toDate(),
                                            moment().subtract(1, 'days').toDate(),
                                        ],
                                        'Last 7 Days': [
                                            moment().subtract(6, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Last 30 Days': [
                                            moment().subtract(30, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'This Month': [
                                            moment().startOf('month').toDate(),
                                            moment().endOf('month').toDate(),
                                        ],
                                        'Last Month': [
                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                        ],
                                    },
                                }}
                            onApply={(e, val) => dateChangeEvent(val, e)}
                        >
                            <input type="text" className="form-control datepicker-range" />
                        </DateRangePicker>
                    </div>
                    <div className='dropdown-dialog-parent'>
                        <div className={` dropdown-dialog ${isOpen ? 'open' : ''}`}>
                            <button type='button' className='btn btn-sm  filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 15 }} src={AllFilter} /> All Filters</button>
                            {isOpen && (
                                <>
                                    <FilterMenu filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                                </>
                            )}
                        </div>
                    </div>
                </div>                
            </div >
            <div className='Ag-grid-intransit'>
                <DataGridPagination
                    rowData={rowData}
                    columnDefs={columnDefs}
                    // onSelectionChanged={onCellClick}
                    gridref={gridRef}
                    rowSelection={"multiple"}
                    domLayout="autoHeight"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={Math.round(originalData.count / per_page)}
                    pageCount={originalData.count}
                />

            </div>
        </>
    );
}

export default InTransitBody;

