import React from 'react';
import PageLayout from '../../../global/pageLayout';
import Userbody from './Body'


export default function Index() {
    return (
        <>
            <PageLayout tabMenu={false} body={<Userbody />} />
        </>
    )
}
