import React, { useState, useEffect } from 'react';
import backArrow from '../../../../assets/backArrow.svg';
import { Link, useLocation, useParams } from 'react-router-dom';
import UserDetailModal from './UserDetailModal';
import '../staff.scss';
import instance from '../../../../helpers/axiosInstance';
import Loader from '../../../global/loader';
import { commonServiceMethod } from '../../../../helpers/helper';
import SuccessToast from '../../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import UserAdmin from '../../../../assets/usersAdmin.svg';
import UserStaff from '../../../../assets/usersStaff.svg';
import UserManage from '../../../../assets/userManage.svg';
const Body = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userDetails, setUserDetails] = useState({});
    const [moduleList, setModuleList] = useState([]);
    const [permission, setPermission] = useState([]);
    const [modalShow, setModalShow] = useState('');
    const { id } = useParams();

    const showModal = (value) => {
        // setUserDetails(value);
        setModalShow(value)
    }

    const closeModal = (data = 0) => {
        // setUserDetails('');
        setModalShow('');
        if (data === 1)
            getPermissionList();
    }
    useEffect(() => {
        getPermissionList();
        getUserRoleLsit();
        setUserDetails(location?.state?.userDetail || {});
    }, []);
    const getPermissionList = async () => {
        try {
            instance.get("app/getPermissionbyid?id=" + id).then((res) => {

                if (res.status === 200) {
                    setPermission(res.data.map(i => i.module_id));
                }
                else {
                    setPermission([]);
                }

            }).catch(e => {
                console.error(e);
                setModuleList([]);
            })
        }
        catch (e) {
            console.error(e);
            setModuleList([]);
        }
    }
    const getUserRoleLsit = async () => {
        try {
            instance.get("app/getModuleList").then((res) => {

                if (res.status === 200 && Array.isArray(res.data)) {
                    setModuleList(res.data);
                }

            }).catch(e => {
                console.error(e);
                setModuleList([]);
            })
        }
        catch (e) {
            console.error(e);
            setModuleList([]);
        }
    }
    const [loader, setLoader] = useState(false);
    const suspendAccess = () => {
        try {
            const config = {};
            let obj = {};
            commonServiceMethod(`app/updateaccountStatus?id=${id}`, "put", obj, config, setLoader, (res) => {
                if (res.status === 200) {
                    if (res?.data?.message === 'Account Suspended')
                        setUserDetails({ ...userDetails, is_active: 'N' });
                    else
                        setUserDetails({ ...userDetails, is_active: 'Y' });
                    toast(<SuccessToast body={res?.data?.message} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    }
    const deleteAccount = () => {
        try {
            const config = {};
            let obj = {};
            commonServiceMethod(`app/deleteaccountStatus?id=${id}`, "put", obj, config, setLoader, (res) => {
                if (res.status === 200) {
                    if (res?.data?.message === 'Account deleted Successfully')
                        toast(<SuccessToast body={res?.data?.message} />, {
                            transition: Slide,
                            hideProgressBar: true,
                            autoClose: 2000,
                        });
                    navigate('/settings/staff-managemanet');
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    }
    //
    return (
        <>
            <Loader showHide={loader} />
            <div className="parent-div">
                <div className='container mt-3 mb-3' >

                    <div className='row'>
                        <div className='col-md-9'>
                            <Link to={'/settings/staff-managemanet'}>
                                <img src={backArrow} /> Back
                            </Link>

                        </div>
                        <div className='col-md-3 d-flex justify-content-end'>

                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-8 col-sm-12'>
                            <span className='page-header-text mb-2'>{`${userDetails.first_name} ${userDetails.last_name}`}</span>
                            {/* <p className='details'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p> */}
                        </div>

                    </div>

                    <div className='mt-2'>
                        <div className='col-8 mb-3' >
                            <div className='card'>
                                <div className='card-header'>

                                    <div className='row'>
                                        <div className='col-md-11 d-flex'>
                                            <span className='card-icons'>
                                                <img src={UserAdmin} alt='UserAdmin' />
                                            </span>
                                            <h5 className='cardTitle'>User Details</h5>
                                        </div>

                                        {/* <div className='col-md-1 no-padding'>
                                        <Link to={''} onClick={() => showModal('editUserDetails')}><i className="bi bi-pencil-square" style={{ fontSize: '20px' }} ></i></Link>

                                    </div> */}
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom01" className="form-label userLabel">Full name</label>
                                            <p>{`${userDetails.first_name} ${userDetails.last_name}`}</p>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom02" className="form-label userLabel">User ID</label>
                                            <p>{`#${userDetails.user_id}`}</p>
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom02" className="form-label userLabel">Email Address</label>
                                            <p>{`${userDetails.email}`}</p>
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom02" className="form-label userLabel">Phone Number</label>
                                            <p>{`${userDetails.phone_number}`}</p>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom02" className="form-label userLabel">Role</label>
                                            <p>{`${userDetails.role_id === 1 ? 'Administrator' : 'Staff'}`}</p>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-8  mb-3' >
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-md-11 d-flex'>
                                            <span className='card-icons'>
                                                <img src={UserStaff} alt='UserStaff' />
                                            </span>
                                            <h5 className='cardTitle'>Permissions</h5>
                                        </div>

                                        <div className='col-md-1 no-padding'>
                                            <Link to={''} onClick={() => showModal('editUserPermission')} ><i className="bi bi-pencil-square" style={{ fontSize: '20px' }}></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='cardBodyInner'>
                                        <div className='row'>
                                            {
                                                moduleList.map((i, index) => (
                                                    <div key={index} className='col-md-5'>
                                                        <div className="form-check mb-3">
                                                            <input value={i.id} type="checkbox" checked={permission?.indexOf(i.id) > -1} disabled className="form-check-input" id={'validationFormCheck' + index} />
                                                            <label className="form-check-label  checkboxLabel" htmlFor={'validationFormCheck' + index}>{i.module_name}</label>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='col-8 mb-3' >
                            <div className='card'>
                                <div className='card-header'>

                                    <div className='displayFlex'>
                                        <span className='card-icons'>
                                            <img src={UserManage} alt='UserManage' />
                                        </span>
                                        <h5 className='cardTitle'>Manage staff Access</h5>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <p className='innerTitle'>Suspend access</p>
                                    <p className='innerContent'>This account will no longer have access to your app. You can restore access at any time.</p>
                                    <button className='btn btn-sm deleteButton' onClick={suspendAccess}>{userDetails.is_active === 'Y' ? 'Suspend Access' : 'Reactivate Access'}</button>
                                    <div className='elementGap'></div>
                                    <p className='innerTitle'>Delete Account</p>
                                    <p className='innerContent'>Removed staff members will be permanently removed from your app. This action cannot be reversed.</p>
                                    <button className='btn btn-sm deleteButton' onClick={deleteAccount} >Delete Account</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            {modalShow !== '' &&
                <UserDetailModal
                    show={modalShow}
                    item={id}
                    userDetails={userDetails}
                    handleClose={closeModal}
                    moduleList={moduleList}
                    permission={permission}
                />
            }
        </>
    )
}
export default Body