import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AllOrderTabDetails } from "../constants";
const PermissionWrapper = (props) => {
    const navigate = useNavigate();
    const [access, setAccess] = useState(false);
    const { child, moduleId, module = '', ...rest } = props;
    useEffect(() => {
        const permission = localStorage.userPermission?.split(",") || [];
        if (module === 'order') {
            let filter = AllOrderTabDetails.filter(item => {
                return permission.includes(item.moduleId?.toString())
            });
            if (filter.length > 0) {
                let samepath = filter.filter(item => {
                    return location.pathname === item.url;
                });
                samepath.length === 1 ? setAccess(true) : navigate(filter[0]?.url,{ replace: true })
            }
            else
                navigate("/home",{ replace: true });
        }
        else {
            !permission.includes(moduleId?.toString()) ? navigate("/home",{ replace: true }) : setAccess(true);
        }
    }, [props]);
    if (access) {
        return <>{child({ ...rest })}</>;
    }
}

export default PermissionWrapper;