import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import FeatureIcon from "../../../assets/FeaturedIcon.svg"

const ChannelIntegration = ({ item, show, handleClose, handleSave }) => {

    const [enableSaveBtn, ] = useState(false);

    return (<>

        <Modal
            size='sm'
            show={show === 'addNew'}
            onHide={handleClose}
            centered
            scrollable
        >
            <div className='modal-content modal-lg'>
                <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="modal-title">New Integration</h5>
                </div>
                <div className="modal-body modal-lg">
                    <label className='userLabel'>Enter URL</label>
                    <input type='text' className='form-control mb-3' placeholder='acme.myshopify.com' />
                </div>

            </div>

            <div className="modal-footer">

                <div className="col-12 mb-4 d-flex justify-content-end">
                    <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm btn-primary" onClick={() => handleSave(item)} type="submit" disabled={enableSaveBtn === false ? true : false}>Save</button>

                </div>

            </div>

        </Modal>

        <Modal
            size='sm'
            show={show === 'details'}
            onHide={handleClose}
            centered
            scrollable
        >
            <div className='modal-content modal-lg'>
                <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="modal-title">Integration Details</h5>
                </div>
                <div className="modal-body modal-lg">
                    <label className='userLabel'>Connected URL</label>
                    <input type='text' className='form-control mb-3' placeholder='acme.myshopify.com' />
                </div>

            </div>

            <div className="modal-footer">
                <div className="col-12 mb-4 d-flex justify-content-end">
                    <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm btn-primary" onClick={() => handleSave(item)} type="submit" disabled={enableSaveBtn === false ? true : false}>Save</button>

                </div>

            </div>

        </Modal>

        <Modal
            size='md'
            show={show === 'disconnect'}
            onHide={handleClose}
            centered
            scrollable
        >
            <div className='modal-content modal-lg'>
                <div className="modal-body modal-lg">
                    <div className='row'>
                        <div className='col-md-2' style={{ paddingRight: '0px' }}>
                            <img src={FeatureIcon} />
                        </div>
                        <div className='col-md-8' style={{ paddingLeft: '0px' }}>
                            <h5 style={{ color: '#101828', fontSize: '18px', fontWeight: '600' }}>Disconnect Channel</h5>
                            <p style={{ color: '#667085', fontSize: '14px' }}>Are you sure want to Disconnect channel?</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="modal-footer">

                <div className="col-12 mb-4 d-flex justify-content-end">
                    <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm btn-danger" onClick={() => handleSave(item)} type="submit" >Disconnect now</button>

                </div>

            </div>

        </Modal>

    </>)

}

export default ChannelIntegration;