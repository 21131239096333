import React from 'react';
import PageLayout from '../../../global/pageLayout';
import IntransitReturnsBody from './body';

const IntransitReturns = () => {
    return (
        <div>
            <PageLayout tabMenu ={false} returnTabMenu={true} selectedItem="In transit" body={<IntransitReturnsBody />} />
        </div>
    )
}

export default IntransitReturns;