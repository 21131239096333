import React, { useMemo, useRef, useState } from 'react';
import './orderDetails.scss';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ProductInventory from '../../../../assets/productInventory.svg'
const Inventory = () => {

    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const agGrid = useRef(null);
    const [activeTab, setActiveTab] = useState('all');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const rowData = [
        {
            product_name: 'Product name',
            unavailable: '0',
            commited: '8',
            available: '492',
            onHand: '500'
        },
        {
            product_name: 'Product name',
            unavailable: '0',
            commited: '0',
            available: '-174',
            onHand: '-174'
        },
        {
            product_name: 'Total',
            unavailable: '0',
            commited: '8',
            available: '138',
            onHand: '326'
        },
    ]

    const columnDefs = [
        {
            headerName: 'Location',
            field: 'product_name',
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'Unavailable',
            field: 'unavailable',
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'Committed',
            field: 'commited',
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'Available',
            field: 'available',
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'On hand',
            field: 'onHand',
            wrapText: true,
            autoHeight: true,
        },
    ];


    return (
        <>
            <div className="card">
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-md-10 d-flex'>
                            <span className='card-icons'>
                                <img src={ProductInventory} alt='ProductInventory' />
                            </span>
                            <h5 className='orderDetailTitle'>Inventory</h5>
                        </div>

                        <div className='col-md-2 no-padding'>
                            {/* <Link to={''}>All</Link>&nbsp;
                            <Link to={''} className='text-primary'>Incoming</Link> */}
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 'all' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('all')}
                                    >
                                        All
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 'incoming' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('incoming')}
                                    >
                                        Incoming
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className={`card-bodys order-detail-page-product-grid`}>

                    <div className="tab-content">
                        <div
                            className={`tab-pane ${activeTab === 'all' ? 'active' : ''}`}
                            id="all"
                        >
                            <div style={gridStyle} className="ag-theme-alpine">
                                {/* <h6>All</h6> */}
                                <AgGridReact
                                    ref={agGrid}
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                    domLayout={'autoHeight'}
                                // autoGroupColumnDef={autoGroupColumnDef}
                                // groupIncludeFooter={true}
                                // groupIncludeTotalFooter={true}
                                />
                            </div>
                        </div>
                        <div
                            className={`tab-pane ${activeTab === 'incoming' ? 'active' : ''}`}
                            id="incoming"
                        >
                            <div style={gridStyle} className="ag-theme-alpine">
                                <h6>Incoming</h6>
                                <AgGridReact
                                    ref={agGrid}
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                    domLayout={'autoHeight'}
                                // autoGroupColumnDef={autoGroupColumnDef}
                                // groupIncludeFooter={true}
                                // groupIncludeTotalFooter={true}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <div style={gridStyle} className="ag-theme-alpine">
                            <AgGridReact
                                ref={agGrid}
                                columnDefs={columnDefs}
                                rowData={rowData}
                                domLayout={'autoHeight'}
                            // autoGroupColumnDef={autoGroupColumnDef}
                            // groupIncludeFooter={true}
                            // groupIncludeTotalFooter={true}
                            />
                        </div>
                    </div> */}
                </div>

            </div>

        </>
    )
}

export default Inventory