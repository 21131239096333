import React from 'react';
import './pageNotFound.scss';
// import { useNavigate } from 'react-router-dom';
const PageNotFoundBody = ()=>{
    // const navigate = useNavigate();
    return(
        <div className='home-page'>
            {/* <button onClick={navigate('/')}>Back</button> */}
            Page Not Found
        </div>
    )
}
export default PageNotFoundBody;