import React, { useState, useEffect } from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Calandar from '../../../assets/Icondate.svg';
import moment from 'moment';
import NewShipmentOverview from "./newShipmentOverview";
import NewCourierSplit from "./newCourierSplit";
import NewOverallShipment from "./newOverAllShipment";
import NewCancelReason from "./newCancelReason";
import DashboardCount from "./newCountData";
import { commonServiceMethod } from '../../../helpers/helper';
import Loader from '../../global/loader';
import { useSelector } from 'react-redux';

const DashboardBody = () => {

    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());

    const [loader, setLoader] = useState(false);
    const userClaim = useSelector((state) => state.user);
    const [countDatas, setCountDatas] = useState();
    const [cancelReasonDatas, setCancelReasonDatas] = useState();
    const [courierSplitDatas, setCourierSplitDatas] = useState();
    const [overallShipmentDatas, setOverallShipmentDatas] = useState();
    const [shipmentOverviewDatas, setShipmentOverviewDatas] = useState();


    const courierSplit = [
    //     {
    //     name: 'Delhivery',
    //     value: 40,
    // }, {
    //     name: 'Bluedart',
    //     value: 30,
    // }, {
    //     name: 'Ekart',
    //     value: 10,
    // }, {
    //     name: 'DTDC',
    //     value: 10,
    // }, {
    //     name: 'Other',
    //     value: 10,
    // }
];

    const overallShipment = [
    //     {
    //     name: 'Refund',
    //     value: 40,
    // }, {
    //     name: 'Inprogres',
    //     value: 30,
    // }, {
    //     name: 'Cancel',
    //     value: 10,
    // }, {
    //     name: 'Shipped',
    //     value: 10,
    // }
];

    const dateChangeEvent = (date) => {
        setStartDate(moment(date.startDate).format('YYYY-MM-DD'));
        setEndDate(moment(date.endDate).format('YYYY-MM-DD'));
    }

    const defaultCancelReason = [
        // {
        //     legend: '0: WrongContact',
        //     data: 0,
        //     color: '#3E1C96',
        // }, {
        //     legend: '0: HighPrice',
        //     data: 0,
        //     color: '#5925DC',
        // }, {
        //     legend: '0: Codprepaid',
        //     data: 0,
        //     color: '#7A5AF8',
        // }, {
        //     legend: '0: Demo1',
        //     data: 6,
        //     color: '#BDB4FE',
        // }, {
        //     legend: '0: Demo2',
        //     data: 0,
        //     color: '#D9D6FE',
        // }, {
        //     legend: '0: Other',
        //     data: 0,
        //     color: '#EBE9FE',
        //     legend: '0: Wrong contact',
        //     data: 0,
        //     color: '#3E1C96',
        // }, {
        //     legend: '0: High price',
        //     data: 0,
        //     color: '#5925DC',
        // }, {
        //     legend: '0: COD prepaid',
        //     data: 0,
        //     color: '#7A5AF8',
        // }
        // , {
        //     legend: '0: demo1',
        //     data: 6,
        //     color: '#BDB4FE',
        // }, {
        //     legend: '0: demo2',
        //     data: 0,
        //     color: '#D9D6FE',
        // }, {
        //     legend: '0: other',
        //     data: 0,
        //     color: '#EBE9FE',
        // }
    ];

    const shipmentOverview = [
    //     {
    //     courier: 'Delhivery',
    //     pickedUp: 10,
    //     intransit: 20,
    //     delivered: 12,
    //     return: 3,
    //     delayed: 10,
    //     lostDamaged: 0
    // }, {
    //     courier: 'Bluedart',
    //     pickedUp: 5,
    //     intransit: 20,
    //     delivered: 6,
    //     return: 3,
    //     delayed: 4,
    //     lostDamaged: 3
    // }, {
    //     courier: 'Ekart',
    //     pickedUp: 3,
    //     intransit: 6,
    //     delivered: 5,
    //     return: 20,
    //     delayed: 3,
    //     lostDamaged: 4
    // }, {
    //     courier: 'DTDC',
    //     pickedUp: 20,
    //     intransit: 5,
    //     delivered: 12,
    //     return: 3,
    //     delayed: 10,
    //     lostDamaged: 0
    // }, {
    //     courier: 'Other',
    //     pickedUp: 18,
    //     intransit: 5,
    //     delivered: 12,
    //     return: 3,
    //     delayed: 10,
    //     lostDamaged: 0
    // }
];

    useEffect(() => {
        if (userClaim.token !== "") {
            getCountData(startDate, endDate);
            getCancelData(startDate, endDate);
            getCourierSplitData(startDate, endDate);
            getoverallShipmentDatas(startDate, endDate);
            getshipmentOverviewDatas(startDate, endDate);
        }

    }, [startDate, endDate])

    function toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }


    const getCountData = async (sDate, eDate) => {
        let startdate = moment(sDate).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD 00:00:00');
        let enddate = moment(eDate).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD 23:59:59');

        commonServiceMethod(`app/dashboard/overview`, "post", { startdate, enddate }, {}, setLoader, (res) => {
            console.log(res, startdate, enddate);
            if (res?.status === 200) {
                console.log('Counts');
                setCountDatas(res?.data?.data);
                console.log(res?.data?.data);
            }
        })
    }
    const getCancelData = async (sDate, eDate) => {
        let startdate = moment(sDate).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD 00:00:00');
        let enddate = moment(eDate).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD 23:59:59');

        var colors = ['#3E1C96', '#5925DC', '#7A5AF8', '#BDB4FE', '#D9D6FE', '#EBE9FE'];

        commonServiceMethod(`app/dashboard/cancel/overview`, "post", { startdate, enddate }, {}, setLoader, (res) => {
            console.log(res, startdate, enddate);
            if (res?.status === 200) {

                var array = [];

                for (let i = 0; i < res?.data?.data.length; i++) {

                    //toTitleCase(res?.data?.data[i]['cancelReason'].replace(/_/g, ' '))

                    let temp = {};
                    temp['legend'] = res?.data?.data[i]['count'] + ' : ' + ' ' + toTitleCase(res?.data?.data[i]['cancelReason'].replace(/_/g, ' '));
                    temp['data'] = res?.data?.data[i]['count'];
                    temp['color'] = colors[i];

                    array.push(temp);


                }

                // console.log(res?.data?.data, array, 'defaultCancelReason');
                let data = array?.length === 0 ? defaultCancelReason : array;
                setCancelReasonDatas(data);
            }
        })
    }

    const getCourierSplitData = async (sDate, eDate) => {
        // let dates = {
        let startdate = moment(sDate).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD 00:00:00');
        let enddate = moment(eDate).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD 23:59:59');
        // }

        commonServiceMethod(`app/dashboard/courier/overview`, "post", { startdate, enddate }, {}, setLoader, (res) => {
            console.log(res, startdate, enddate);
            if (res?.status === 200) {
                console.log('getCourierSplitData');
                var array = [];


                for (var values in res?.data?.data) {
                    console.log(values, res?.data?.data[values]);

                    let temp = {};
                    temp['name'] = res?.data?.data[values]['ecomName'] || "";
                    temp['value'] = res?.data?.data[values]['count'];

                    array.push(temp);

                }

                console.log(res?.data?.data.length, array.length, 'Courier Length');
                let data = array.length === 0 ? courierSplit : array;
                setCourierSplitDatas(data);
                console.log(array);
            }
        })
    }
    const getoverallShipmentDatas = async (sDate, eDate) => {
        let startdate = moment(sDate).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD 00:00:00');
        let enddate = moment(eDate).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD 23:59:59');

        commonServiceMethod(`app/dashboard/shipment/overview`, "post", { startdate, enddate }, {}, setLoader, (res) => {
            console.log(res, startdate, enddate);
            if (res?.status === 200) {
                console.log('getoverallShipmentDatas');
                const array = Object.entries(res?.data?.data).map(([name, value]) => ({
                    name: toTitleCase(name.replace(/_/g, ' ')),
                    value,
                }));
                console.log(res?.data?.data.length, array.length, 'Courier Length');
                let data = array.length === 0 ? overallShipment : array;
                setOverallShipmentDatas(data)
                console.log(array);
            }
        })
    }

    const getshipmentOverviewDatas = async (sDate, eDate) => {
        console.log(sDate, eDate)
        let startdate = moment(sDate).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD 00:00:00');
        let enddate = moment(eDate).hours(0).minutes(0).seconds(0).format('YYYY-MM-DD 23:59:59');

        commonServiceMethod(`app/dashboard/shipment/overviewByCourier`, "post", { startdate, enddate }, {}, setLoader, (res) => {
            console.log(res, startdate, enddate);
            if (res?.status === 200) {
                console.log('getshipmentOverviewDatas');


                var data = res?.data?.data?.shipmentOverviewCounts;
                const transformedData = data.map(item => ({
                    courier: item.ecomName,
                    pickedUp: item.pickedUp === undefined ? '0' : item.pickedUp,
                    intransit: item.inTransit === undefined ? '0' : item.inTransit,
                    delivered: item.delivered === undefined ? '0' : item.delivered,
                    returns: item.returns === undefined ? '0' : item.returns,
                    delayed: item.delayed === undefined ? '0' : item.delayed,
                    lost: item.lost === undefined ? '0' : item.lost
                }));

                let datas = transformedData.length === 0 ? shipmentOverview : transformedData;
                setShipmentOverviewDatas(datas);
                console.log(datas, 'shipmentOverview');
            }
        })
    }


    return (
        <>
            <Loader showHide={loader} />
            <div className="parent-div">
                <div className="row">
                    <div className="col-md-12">
                        <div className="topview mb-4">
                            <div className="col-md-3">
                                <h4 className='fw-600' >Dashboard</h4>
                            </div>
                            <div className="col-md-9">
                                <div className="d-flex justify-content-end space-between">
                                    <div className="form-group">
                                        <div style={{ position: 'relative' }}>
                                            <span className="icon"><img alt='' src={Calandar} /></span>
                                        </div>
                                        <DateRangePicker
                                            initialSettings={
                                                {
                                                    startDate: startDate,
                                                    endDate: endDate,
                                                    maxDate: moment().toDate(),
                                                    showDropdowns: true,
                                                    locale: {
                                                        format: 'MMM DD, YYYY',
                                                    },
                                                    ranges: {
                                                        Today: [moment().toDate(), moment().toDate()],
                                                        Yesterday: [
                                                            moment().subtract(1, 'days').toDate(),
                                                            moment().subtract(1, 'days').toDate(),
                                                        ],
                                                        'Last 7 Days': [
                                                            moment().subtract(6, 'days').toDate(),
                                                            moment().toDate(),
                                                        ],
                                                        'Last 30 Days': [
                                                            moment().subtract(30, 'days').toDate(),
                                                            moment().toDate(),
                                                        ],
                                                        'This Month': [
                                                            moment().startOf('month').toDate(),
                                                            moment().endOf('month').toDate(),
                                                        ],
                                                        'Last Month': [
                                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                                        ],
                                                    },
                                                }}
                                            onApply={(e, val) => dateChangeEvent(val, e)}
                                        >
                                            <input type="text" className="form-control datepicker-range" />
                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <DashboardCount countData={countDatas} />
                                    <div className="col-md-12">
                                        <NewCourierSplit courierSplitData={courierSplitDatas} />
                                        {/* <div className="card" >
                                            <div className="card-body" style={{ minHeight: '100%' }}>
                                                <div className='row mb-4'>
                                                    <div className="col-md-6">
                                                        <div className="dashboard-card-title">
                                                            Courier Split
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='justify-content-end'>
                                                            <select className='form-select'>
                                                                {courierSplitDatas && courierSplitDatas.map((item, i) => {
                                                                    return (<>
                                                                        <option value={item?.name} key={i}>{item?.name}</option>
                                                                    </>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <NewCourierSplit courierSplitData={courierSplitDatas} />
                                            </div>
                                       </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card" >
                                    <div className="card-body" style={{ minHeight: '208px' }}>
                                        <div className="dashboard-card-title mb-4">
                                            Cancellation Reasons
                                        </div>
                                        <NewCancelReason cancelReason={cancelReasonDatas} />
                                    </div>

                                </div>
                                <div className="card" >
                                    <div className="card-body" style={{ minHeight: '100%' }}>
                                        <NewOverallShipment overallShipmentData={overallShipmentDatas} />
                                        {/* <div className='row mb-4'>
                                            <div className="col-md-6">
                                                <div className="dashboard-card-title">
                                                    Overall Shipment
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className='justify-content-end'>
                                                    <select className='form-select'>
                                                        {overallShipmentDatas && overallShipmentDatas.map((item, i) => {
                                                            return (<>
                                                                <option value={item?.name} key={i}>{item?.name}</option>
                                                            </>)
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <NewOverallShipment overallShipmentData={overallShipmentDatas} /> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="card" >
                                    <div className="card-body" style={{ minHeight: '100%' }}>
                                        <div className='row mb-4'>
                                            <div className="col-md-6">
                                                <div className="dashboard-card-title">
                                                    Shipment Overview by Courier
                                                </div>
                                            </div>
                                        </div>
                                        <NewShipmentOverview shipmentOverview={shipmentOverviewDatas} />
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DashboardBody;