import React from 'react'
import ReactEcharts from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
    BarChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
    GridComponent,
    ToolboxComponent,
    TooltipComponent,
    AxisPointerComponent,
    BrushComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    LegendScrollComponent,
    LegendPlainComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
    AriaComponent,
    TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    SVGRenderer,
} from 'echarts/renderers';

// Register the required components
echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, SVGRenderer, ToolboxComponent, TooltipComponent, AxisPointerComponent, BrushComponent, TitleComponent, TimelineComponent, MarkPointComponent, MarkLineComponent, MarkAreaComponent, LegendComponent, LegendScrollComponent, LegendPlainComponent, DataZoomComponent, DataZoomInsideComponent, DataZoomSliderComponent, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, AriaComponent,
        TransformComponent, DatasetComponent]
);

const NewShipmentOverview = ({ shipmentOverview }) => {

    const options = {
        legend: {
            orient: 'vertical',
            right: 10,
            top: 'center',
            icon: 'circle',

        },
        tooltip: {
            trigger: "axis",
            formatter: params => {
                // Create legend color items in tooltip
                const legendItems = params.map(param => {
                    const color = param.color;
                    const name = param.seriesName;
                    const values = param.value;
                    return `<span style="display:inline-block; margin-right:5px; width:10px; height:10px; border-radius:50%; background-color:${color};font-size:12px;"></span>${name} :&nbsp;<span> ${Number(values) || 0}</span>`;
                }).join('<br/>');

                // Calculate the sum of values
                //const sum = params.reduce((acc, cur) => parseInt(acc) + parseInt(cur.value), 0);
                const sum = params.reduce((acc, cur) => acc + (Number(cur.value) || 0), 0);

                // Format the tooltip content
                const tooltipContent = `<strong style="color:#000;font-size:12px;">${params[0].axisValue}</strong><br/>${legendItems}<br/><strong style="color:#000;font-size:12px;">Total Shipment: &nbsp;${sum}</strong>`;
                return tooltipContent;
            },
        },
        yAxis: {
            type: "value"
        },
        xAxis: {
            type: "category",
            axisPointer: {
                type: "shadow"
            },
            // data: ["Mon", "Tue", "Wed", "Thu", "Fri"]
            data: shipmentOverview && shipmentOverview.map(value => (value.courier))
        },
        series: [

            {
                name: "Intransit",
                type: "bar",
                stack: "total",
                showBackground: false,
                barMaxWidth: 60,
                itemStyle: {
                    borderRadius: 0,
                    color: '#175CD3'
                },
                label: {
                    show: false
                },
                emphasis: {
                    focus: "series"
                },
                data: shipmentOverview && shipmentOverview.map(value => (value.intransit))
            },
            {
                name: "Delivered",
                type: "bar",
                stack: "total",
                showBackground: false,
                barMaxWidth: 60,
                itemStyle: {
                    borderRadius: 0,
                    color: '#0086C9'
                },
                label: {
                    show: false
                },
                emphasis: {
                    focus: "series"
                },
                data: shipmentOverview && shipmentOverview.map(value => (value.delivered))
            },
            {
                name: "Return",
                type: "bar",
                stack: "total",
                showBackground: false,
                barMaxWidth: 60,
                itemStyle: {
                    borderRadius: 0,
                    color: '#53B1FD'
                },
                label: {
                    show: false
                },
                emphasis: {
                    focus: "series"
                },
                data: shipmentOverview && shipmentOverview.map(value => (value.returns))
            },
            {
                name: "Picked Up",
                type: "bar",
                stack: "total",
                showBackground: false,
                barMaxWidth: 60,
                itemStyle: {
                    borderRadius: 0,
                    color: '#53B1FD'
                },
                label: {
                    show: false
                },
                emphasis: {
                    focus: "series"
                },
                data: shipmentOverview && shipmentOverview.map(value => (value.pickedUp))
            },
            {
                name: "Delayed",
                type: "bar",
                stack: "total",
                showBackground: false,
                barMaxWidth: 60,
                itemStyle: {
                    borderRadius: 0,
                    color: '#53B1FD'
                },
                label: {
                    show: false
                },
                emphasis: {
                    focus: "series"
                },
                data: shipmentOverview && shipmentOverview.map(value => (value.delayed))
            },
            {
                name: "Lost",
                type: "bar",
                stack: "total",
                showBackground: false,
                barMaxWidth: 60,
                itemStyle: {
                    borderRadius: 0,
                    color: '#53B1FD'
                },
                label: {
                    show: false
                },
                emphasis: {
                    focus: "series"
                },
                data: shipmentOverview && shipmentOverview.map(value => (value.lost))
            },

        ]
    };

    // var series = [
    //     {
    //         data: [120, 200, 150, 80, 70, 110, 130],
    //         type: 'bar',
    //         stack: 'a',
    //         name: 'a'
    //     },
    //     {
    //         data: [10, 46, 64, '-', 0, '-', 0],
    //         type: 'bar',
    //         stack: 'a',
    //         name: 'b'
    //     },
    //     {
    //         data: [30, '-', 0, 20, 10, '-', 0],
    //         type: 'bar',
    //         stack: 'a',
    //         name: 'c'
    //     },
    //     {
    //         data: [30, '-', 0, 20, 10, '-', 0],
    //         type: 'bar',
    //         stack: 'b',
    //         name: 'd'
    //     },
    //     {
    //         data: [10, 20, 150, 0, '-', 50, 10],
    //         type: 'bar',
    //         stack: 'b',
    //         name: 'e'
    //     }
    // ];
    // const stackInfo = {};
    // for (let i = 0; i < series[0].data.length; ++i) {
    //     for (let j = 0; j < series.length; ++j) {
    //         const stackName = series[j].stack;
    //         if (!stackName) {
    //             continue;
    //         }
    //         if (!stackInfo[stackName]) {
    //             stackInfo[stackName] = {
    //                 stackStart: [],
    //                 stackEnd: []
    //             };
    //         }
    //         const info = stackInfo[stackName];
    //         const data = series[j].data[i];
    //         if (data && data !== '-') {
    //             if (info.stackStart[i] == null) {
    //                 info.stackStart[i] = j;
    //             }
    //             info.stackEnd[i] = j;
    //         }
    //     }
    // }
    // for (let i = 0; i < series.length; ++i) {
    //     const data = series[i].data;
    //     const info = stackInfo[series[i].stack];
    //     for (let j = 0; j < series[i].data.length; ++j) {
    //         // const isStart = info.stackStart[j] === i;
    //         const isEnd = info.stackEnd[j] === i;
    //         const topBorder = isEnd ? 20 : 0;
    //         const bottomBorder = 0;
    //         data[j] = {
    //             value: data[j],
    //             itemStyle: {
    //                 borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder]
    //             }
    //         };
    //     }
    // }
    // const options = {
    //     legend: {
    //         orient: 'vertical',
    //         right: 10,
    //         top: 'center'
    //     },
    //     tooltip: {
    //         trigger: "axis"
    //     },
    //     xAxis: {
    //         type: 'category',
    //         data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    //     },
    //     yAxis: {
    //         type: 'value'
    //     },
    //     series: series
    // };
    return (
        <>
            <ReactEcharts option={options} echarts={echarts} lazyUpdate={true} style={{ minHeight: '72vh', padding: '0px', margin: '0px' }} />
        </>
    )
}

export default NewShipmentOverview;