import React from 'react';
import PageLayout from '../../global/pageLayout';
import PageNotFoundBody from './pageNotFound';

const PageNotFound = () => {
    return (
        <div>
            <PageLayout tabMenu={false} body={<PageNotFoundBody />} />
        </div>
    )
}

export default PageNotFound;