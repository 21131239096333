export const filterOptions = [
    {
        "title": "Payment Type",
        "label": [
            {
                "id": 1,
                "menu": 'COD',
                "value": "COD",
                "key": "payment_type"

            },
            {
                "id": 2,
                "menu": 'Prepaid',
                "value": "Pre-paid",
                "key": "payment_type"
            },
        ]
    },
    // {
    //     "title": "Fulfilment Status",
    //     "label": [
    //         {
    //             "id": 1,
    //             "menu": 'Fulfilled',
    //             "value":"Fulfilled",
    //             "key":"fulfillment"

    //         },
    //         {
    //             "id": 2,
    //             "menu": 'Unfulfilled',
    //             "value":"Unfulfilled",
    //             "key":"fulfillment"
    //         },
    //     ]
    // },
    // {
    //     "title": "Order Status",
    //     "label": [
    //         {
    //             "id": 1,
    //             "menu": 'New',
    //             value:"New",
    //             key: "order_status",

    //         },
    //         {
    //             "id": 2,
    //             "menu": 'Ready to Ship',
    //             value:"Ready to Ship",
    //             key: "order_status",

    //         },
    //         {
    //             "id": 3,
    //             "menu": 'Manifest',
    //             value:"Manifest",
    //             key: "order_status",

    //         },
    //         {
    //             "id": 4,
    //             "menu": 'Intransit',
    //             value:"Intransit",
    //             key: "order_status",

    //         },
    //         {
    //             "id": 5,
    //             "menu": 'Delivered',
    //             value:"Delivered",
    //             key: "order_status",

    //         },
    //         {
    //             "id": 6,
    //             "menu": 'Cancel',
    //             value:"Cancel",
    //             key: "order_status",

    //         }, 
    //     ]
    // },
    {
        "title": "Carrier",
        "label": [{
            "id": 1,
            "menu": 'Bluedart',
            value: "Bluedart",
            key: "carrier",

        },
        {
            "id": 2,
            "menu": 'Delhivery',
            value: "Delhivery",
            key: "carrier",

        },
        {
            "id": 3,
            "menu": 'DTDC',
            value: "DTDC",
            key: "carrier",

        },
        {
            "id": 4,
            "menu": 'Ekart',
            value: "Ekart",
            key: "carrier",

        },
        {
            "id": 5,
            "menu": 'Other',
            value: "Other",
            key: "carrier",
        }]
    },
    // {
    //     "title": "Call Status",
    //     "label": [
    //         {
    //             "id": 1,
    //             "menu": 'Accepted',
    //             value:"Accepted",
    //             key: "callStatus",

    //         },
    //         {
    //             "id": 2,
    //             "menu": 'Declined',
    //             value:"Declined",
    //             key: "callStatus",

    //         },
    //         {
    //             "id": 3,
    //             "menu": 'Other',
    //             value:"Other",
    //             key: "callStatus",

    //         }
    //     ]
    // }
]