import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { useSelector } from 'react-redux';
import instance from '../../../helpers/axiosInstance';
import { Link } from 'react-router-dom';
import Search from '../../../assets/Iconsearch.svg';
import Calandar from '../../../assets/Icondate.svg';
import AllFilter from '../../../assets/Iconall-filter.svg';
import DataGridPagination from '../../global/dataGridPagination';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import actionButton from '../../../assets/action-button.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import FilterMenu from "../../global/filterComponent/FilterMenu";
// import CallStatus from '../../global/popupModal/CallStatus';
import { filterOptions } from './filterObj';
import './manifest.scss';
import { commonServiceMethod } from '../../../helpers/helper';
import Loader from '../../global/loader';
// import { MANIFEST_DOWNLOAD_URL } from '../../../constants';
// import refreshIcon from '../../../assets/dashboard/refreshIcon.png';

const ManifestBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const [rowData, setRowData] = useState();
    const [originalData, setOriginalData] = useState({});
    const [selectedObject, setSelectedObject] = useState([]);
    const [open, setOpen] = useState(false);

    // pagination and Filtr variables 
    const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    // const [callDetails, setCallDetails] = useState('');
    // const [callRowID, setCallRowID] = useState('');
    const [loader, setLoader] = useState(false);


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    };


    const columnDefs = [
        {
            headerName: 'Manifest Id',
            field: 'manifest_id',
            // headerCheckboxSelection: true,
            // checkboxSelection: true,
            width: 160,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {
                return (<div>
                    <Link className='grid-link'>#{param.value}</Link>
                </div>)
            }
        },
        {
            headerName: "Order Count",
            field: 'order_count',
            width: 120,
            cellClass: "app-order-status",
        },
        {
            field: 'status',
            width: 120,
            wrapText: true,
            autoHeight: true,
            headerName: 'Status',
            cellClass: "app-order-status",
            cellRenderer: (params) => {
                let order_status_class = "";
                if (params.value.toLowerCase() === "cancel") {
                    order_status_class = "cancel";
                } else if (params.value.toLowerCase() === "refunded") {
                    order_status_class = "refund";
                } else if (params.value.toLowerCase() === "delivered") {
                    order_status_class = "delivered";
                } else {
                    order_status_class = "";
                }
                return (<span className={`default-status ${order_status_class}`}>
                    {params.value}
                </span>)
            }
        },
        {
            width: 140,
            field: 'payment_type',
            headerName: 'Payment Type',
            cellRenderer(params) {
                let payment_mode = "";
                let payment_status = "";
                if (params.value === "COD") {
                    payment_mode = "COD";
                    payment_status = "info";
                } else {
                    payment_mode = "Pre-paid";
                    payment_status = "success";
                }
                return (
                    <div className='address-wrap'>
                        <p className="price_tag">
                            <span className={`${payment_status} mt-0`}>{payment_mode}</span>
                        </p>
                    </div>
                )
            }
        },
        {
            headerName: 'Carrier',
            field: 'courier',
            width: 120,
            wrapText: true,
            autoHeight: true,
            cellClass: "",
            cellRenderer(param) {
                return (<div className='carrier-data'><p className='carrier'>{param.value}</p></div>)
            }
        },
        {
            width: 160,
            field: 'warehouse_location',
            wrapText: true,
            autoHeight: true,
            headerName: 'Warehouse',
            cellRenderer(params) {
                return <div className='warehouse-location'>
                    {
                        params.value?.toLowerCase()
                    }
                </div>
            }
        },
        {
            field: 'created_at',
            width: 180,
            headerName: 'Manifest Date',
            cellRenderer(params) {
                return <div className='warehouse-location'>
                    {
                        moment(params.value).format('DD MMM YYYY hh:mm A')
                    }
                </div>
            }
        },
        {
            width: 100,
            field: '',
            headerName: 'Actions',
            cellRenderer(param) {
                return (
                    <div className="more-action">
                        <OverlayTrigger className="" rootClose={true} trigger="click" placement="bottom" overlay={
                            <Popover id="popover-basic" className='z-index-2000 action-buttons manifest-order'>
                                <Popover.Body>
                                    <button className='view-details' onClick={() => viewManifest([param.data])}>View Manifest</button>
                                    <button className='view-details' onClick={() => downloadManifest([param.data])}>Download Manifest</button>
                                    <button className='print-invoice' onClick={() => printInvoiceMethod([param.data])}>Print Invoice</button>
                                </Popover.Body>
                            </Popover>
                        }>
                            <img alt='Action' src={actionButton} />
                        </OverlayTrigger>

                    </div>
                )
            }
        },
    ];


    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
        }
    }, [userClaim]);

    const onClearFilter = () => {
        getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), "");
    }
    // const showModal = (string, value) => {
    //     setCallDetails(string);
    //     setCallRowID(value)
    // }

    // const closeModal = () => {
    //     setCallDetails('');
    // }

    const getData = (
        page = 1,
        startDate,
        endDate,
        searchText,
        perams = "&order_DetailValue=&payment_type=&carrier="
    ) => {
        var rowData = [];
        if(searchText !== ""){
            if(/^\d{10}$/.test(searchText)){
                searchText+='&order_DetailValue=m'
            }
            else if(searchText.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )){
                searchText+='&order_DetailValue=e'
            }
            else{
                searchText+='&order_DetailValue='
            }
        }
        try {
            instance.get(`app/manifest?per_page=${per_page}&page=${page}&order_date=${startDate}&end_order_date=${endDate}&search=${searchText}${perams}`).then((res) => {

                // console.log(res)

                if (res.status === 200 && Array.isArray(res?.data?.data)) {
                    setRowData(res?.data?.data || []);
                    setOriginalData(res?.data)
                }

            }).catch(e => {
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        console.log(selectedITem.length > 0)
        // setSelectedObject((selectedObject) => [...selectedObject]);
        selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setIdCount([]));
    }
    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        getData(
            pageNo,
            date.startDate.format('YYYY-MM-DD'),
            date.endDate.format('YYYY-MM-DD'),
            searchText
        )
    }
    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const printInvoiceMethod = (obj) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        var objdata = obj.map((i) => i.manifest_id).join();
        commonServiceMethod(`app/order/print-invoice/manifest/${objdata}?templateName=temp1`, "get", {}, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
        });
    }
    const onApplyFilter = (selectionOption, searchTextval = "") => {
        console.log(selectionOption)
        let url = "";
        Object.keys(selectionOption).map(i => {

            url += `&${i}=${selectionOption[i]}`
        })
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchTextval || searchText,
            url
        );
        toggleDropdown();
    }

    const viewManifest = (obj) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = { headers: { 'Accept': 'application/pdf' }, responseType: "blob" };
        var objdata = "";
        objdata = obj.map((i) => i.manifest_id).join();
        commonServiceMethod(`app/manifest/label?manifest_id=${objdata}`, "get", {}, config, setLoader, (res) => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/pdf' })
                const objectUrl = window.URL.createObjectURL(blob)
                window.open(objectUrl)
            }
        });
    }
    const downloadManifest = (obj) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = { headers: { 'Accept': 'application/pdf' }, responseType: "blob" };
        var objdata = "";
        objdata = obj.map((i) => i.manifest_id).join();
        commonServiceMethod(`app/manifest/label?manifest_id=${objdata}`, "get", {}, config, setLoader, (res) => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/pdf' })
                const objectUrl = window.URL.createObjectURL(blob)
                download(`manifest_${objdata}`, objectUrl)
            }
        });
        // window.open(MANIFEST_DOWNLOAD_URL + manifest_id, "_blank");
        // setLoader(false)
    }
    const download = (filename, content) => {
        var element = document.createElement("a");
        element.setAttribute("href", content);
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };
    return (
        <>
            <Loader showHide={loader} />
            {/* <div className='quick-buttons'>
                <button className='syncBtn'> <img src={refreshIcon} className="refreshIcon"></img> Sync Orders</button>
            </div> */}
            {open && <div className="manifest-opopu popup-container grid-checkbox-popuop">
                <div className="popup-body">
                    <div className='row'>
                        <div className='col-md-3 selected-item'>
                            {selectedObject.length} items Seleted
                        </div>
                        <div className='col-md-9 d-flex'>
                            <button className='btn btn-sm w-100 asign-courier filter-btn' onClick={() => viewManifest(selectedObject)}>View Manifest</button>
                            <button className='btn btn-sm w-100 print-invoice filter-btn' onClick={() => downloadManifest(selectedObject)}>Download Manifest</button>
                            <button className='btn btn-sm w-100 creat-manifest filter-btn' onClick={() => printInvoiceMethod(selectedObject)}>Print Invoice</button>

                            {/* <button type='button' onClick={() => showModal('courierPartner', 'courierPartner')} className='btn btn-sm w-100 asign-courier filter-btn'>Print Label</button>
                            <button type='button' onClick={() => printInvoiceMethod([param?.data])} className='btn btn-sm w-100 print-invoice filter-btn'>Print Invoice</button>
                            <button type='button' onClick={() => showModal('creat-manifest', param?.data, cancelOrder)} className='btn btn-sm w-100 creat-manifest filter-btn'>Create Manifest</button> */}
                            <Link to={''} className='close-icon' onClick={() => { setOpen(false); gridRef.current.api.deselectAll(); }} >X</Link>
                        </div>
                    </div>
                </div>
            </div>}
            <div className='row mb-2 mt-4 filter-section'>
                <div className="filter-left-container">
                    <div className="form-group">
                        <form onSubmit={searchTextChangeEvent}>
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Search} /></span>
                            </div>
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search"
                                className='form-control inputWithIcon'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </form>
                    </div>
                </div>
                <div className='filter-right-container'>
                    <div className="form-group datepicker-class">
                        <div style={{ position: 'relative' }}>
                            <span className="icon"><img alt='' src={Calandar} /></span>
                        </div>
                        <DateRangePicker
                        className="datepicker-custom"
                            initialSettings={
                                {
                                    startDate: startDate,
                                    endDate: endDate,
                                    maxDate: moment().toDate(),
                                    showDropdowns: true,
                                    locale: {
                                        format: 'MMM DD, YYYY',
                                    },
                                    ranges: {
                                        Today: [moment().toDate(), moment().toDate()],
                                        Yesterday: [
                                            moment().subtract(1, 'days').toDate(),
                                            moment().subtract(1, 'days').toDate(),
                                        ],
                                        'Last 7 Days': [
                                            moment().subtract(6, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Last 30 Days': [
                                            moment().subtract(30, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'This Month': [
                                            moment().startOf('month').toDate(),
                                            moment().endOf('month').toDate(),
                                        ],
                                        'Last Month': [
                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                        ],
                                    },
                                }}
                            onApply={(e, val) => dateChangeEvent(val, e)}
                        >
                            <input type="text" className="form-control datepicker-range" />
                        </DateRangePicker>
                    </div>
                    <div className='dropdown-dialog-parent'>
                        <div className={` dropdown-dialog ${isOpen ? 'open' : ''}`}>
                            <button type='button' className='btn btn-sm  filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 15 }} src={AllFilter} /> All Filters</button>
                            {isOpen && (
                                <>
                                    <FilterMenu filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                                </>
                            )}
                        </div>
                    </div>
                </div>                
            </div >
            <div className='Ag-grid-manifest'>
                <DataGridPagination
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onSelectionChanged={onCellClick}
                    gridref={gridRef}
                    rowSelection={"multiple"}
                    domLayout="autoHeight"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={per_page}
                    pageCount={originalData.count}
                />

            </div>
            {/* {callRowID && <CallStatus
                item={callRowID}
                show={callDetails}
                handleClose={closeModal}
            />} */}


        </>
    );
}

export default ManifestBody;

