import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
    PieChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
    GridComponent,
    ToolboxComponent,
    TooltipComponent,
    AxisPointerComponent,
    BrushComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    LegendScrollComponent,
    LegendPlainComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
    AriaComponent,
    TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    SVGRenderer,
} from 'echarts/renderers';

// Register the required components
echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, PieChart, CanvasRenderer, SVGRenderer, ToolboxComponent, TooltipComponent, AxisPointerComponent, BrushComponent, TitleComponent, TimelineComponent, MarkPointComponent, MarkLineComponent, MarkAreaComponent, LegendComponent, LegendScrollComponent, LegendPlainComponent, DataZoomComponent, DataZoomInsideComponent, DataZoomSliderComponent, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, AriaComponent,
        TransformComponent, DatasetComponent]
);
const NewCourierSplit = ({ courierSplitData }) => {

    const [selectedOption, setSelectedOption] = useState(''); // Initial selected option    
    const [chartData, setChartData] = useState(getChartData(courierSplitData));

    var colorPalette = ['#7F56D9', '#F4EBFF', '#9E77ED', '#B692F6', '#D6BBFB'];

    function getChartData(selectedOption) {

        var selectedData = String(selectedOption).split('-');
        // console.log(selectedData[0], courierSplitData);
        const filteredData =
            selectedData[0] === ''
                ? courierSplitData
                : courierSplitData && courierSplitData.filter((item) => item?.name === selectedData[0]);

        return {
            tooltip: {
                trigger: 'item',
                // data: selectedData[0] === 'select' ? courierSplitData : courierSplitData && courierSplitData.map((item) => item?.name === selectedData[0]),
                formatter: function (params) {
                    return `${params.seriesName}<br />${params?.name}: ${params?.value}`;
                },
            },
            legend: {
                orient: 'vertical',
                right:'0',
                top: 'center',
                icon: 'circle',
                data: filteredData && filteredData.map((item) => item?.name),
                hover: false
            },
            series: [
                {
                    name: `Courier Split`,
                    type: 'pie',
                    radius: ['35%', '70%'],
                    height: '350px',
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    data: filteredData && filteredData.map((item) => ({
                        name: item?.name,
                        value: item?.value,
                        itemStyle: {
                            color: colorPalette[selectedData[1]]
                        }
                    })),
                    color: colorPalette
                }
            ]
        };
    }

    useEffect(() => {
        setChartData(getChartData(selectedOption));
    }, [selectedOption, courierSplitData]);

    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <>
            {/* <ReactEcharts option={options} echarts={echarts} lazyUpdate={true} /> */}

            <div className="card" >
                <div className="card-body" style={{ minHeight: '100%' }}>
                    <div className='row mb-2'>
                        <div className="col-md-9">
                            <div className="dashboard-card-title">
                                Courier Split
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='justify-content-end'>
                                <select className='form-select' onChange={handleDropdownChange} style={{ fontSize: '14px' }}>
                                    <option value={''}>Select</option>
                                    {courierSplitData && courierSplitData.map((item, i) => {
                                        var optionValue = item?.name + '-' + i;
                                        if(item?.name !== "")
                                        return (<>
                                            <option value={optionValue} key={i}>{item?.name}</option>
                                        </>)
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <ReactEcharts option={chartData} echarts={echarts} lazyUpdate={true} style={{ height: '360px' }} />
                </div>
            </div>
        </>
    )
}

export default NewCourierSplit;
