import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import { useSelector } from 'react-redux';
import instance from '../../../helpers/axiosInstance';
import { Link } from 'react-router-dom';
import Search from '../../../assets/Iconsearch.svg';
import Calandar from '../../../assets/Icondate.svg';
import AllFilter from '../../../assets/Iconall-filter.svg';
import DataGridPagination from '../../global/dataGridPagination';
import Moment from 'react-moment';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import redPhone from '../../../assets/red-phone.svg';
import greenPhone from '../../../assets/green-phone.svg';
import blackPhone from '../../../assets/black-phone.svg';
import yellowPhone from '../../../assets/yellow-phone.svg';
import actionButton from '../../../assets/action-button.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import FilterMenu from "../../global/filterComponent/FilterMenu";
import CallStatus from '../../global/popupModal/CallStatus';
import { filterOptions } from './filterObj';
import './newOrder.scss';
import { checkAccess, commonServiceMethod } from '../../../helpers/helper';
import Loader from '../../global/loader';
import SuccessToast from '../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import CloseIcon from '../../../assets/close -Icon.png';
// import refreshIcon from '../../../assets/dashboard/refreshIcon.png';

const NewOrdersBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const [rowData, setRowData] = useState();
    const [originalData, setOriginalData] = useState({});
    const [selectedObject, setSelectedObject] = useState([]);
    const [open, setOpen] = useState(false);

    // pagination and Filtr variables 
    const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [callDetails, setCallDetails] = useState('');
    const [callRowID, setCallRowID] = useState('');
    const [loader, setLoader] = useState(false);
    const [columnData, setComlumnData] = useState([]);
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    };


    const columnDefsWithCall = [
        {
            headerName: 'Order #',
            field: 'order_name',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            width: 300,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {
                let productNames = param.data.orderProducts;
                let tags = param.data?.tag ? param.data?.tag.split(",") : false;
                return (<div>
                    <Link className='grid-link' to={`/orders/new-order/order-details/${btoa(param.data?.order_id)}`}>{param.value}</Link>
                    <p className='font-insetMedium order-date'><Moment format={"DD MMM YYYY hh:mm A"}>{param.data?.order_created_at}</Moment> </p>
                    <p className='logo'>{tags && tags.map((i, k) => <span key={k} className={`order-tag-status ${k + 1 !== tags.length && 'mb-2'}`}>{i?.toLowerCase()}</span>)}</p>
                    <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={
                        <Popover id="popover-basic" className='z-index-2000 produc-list'>
                            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
                            <Popover.Body>
                                {productNames.map((i, j) => <div key={j} className='product-list-container'>
                                    <p className='p-name bold'><b>{i.product_name}</b></p>
                                    <p className='p-sku'>SKU <b>{i.sku}</b></p>
                                    <p className='p-qty'>QTY <b>{i.quantity}</b></p>
                                </div>)}
                            </Popover.Body>
                        </Popover>
                    }>
                        <p className='viewMore'>
                            View Products
                        </p>
                    </OverlayTrigger>
                </div>)
            }
        },
        {
            field: 'app_order_status',
            width: 120,
            wrapText: true,
            autoHeight: true,
            headerName: 'Status',
            cellClass: "app-order-status",
            cellRenderer: (params) => {
                let order_status_class = "";
                if (params.value.toLowerCase() === "cancel") {
                    order_status_class = "cancel";
                } else if (params.value.toLowerCase() === "refunded") {
                    order_status_class = "refund";
                } else if (params.value.toLowerCase() === "delivered") {
                    order_status_class = "delivered";
                } else {
                    order_status_class = "";
                }
                return (<span className={`default-status ${order_status_class}`}>
                    {params.value}
                </span>)
            }
        },
        {
            width: 200,
            field: 'shipping_address',
            wrapText: true,
            autoHeight: true,
            headerName: 'Customer',
            cellClass: "d-flex-center customers",
            cellRenderer(params) {
                let address = params.data?.shipping_address;
                return (
                    <div className="address-wrap">
                        <p className="customer-name">
                            {params.data?.customer_name}
                        </p>
                        <p className="address">
                            {params.data?.customer_email}
                        </p>
                        <p className="address">
                            {address.phone ? address.phone : ''}
                        </p>
                        <p className="address">
                            {address.city ? address.city : ''} - {address.zip ? address.zip : ''}
                        </p>
                    </div>
                )
            }
        },
        {
            headerName: 'Total',
            field: 'total_price',
            width: 120,
            cellClass: "d-flex-center",
            cellRenderer(params) {
                let payment_mode = "";
                let payment_status = "";
                if (params.data?.gateway === "Cash on Delivery (COD)") {
                    payment_mode = "COD";
                    payment_status = "info";
                } else {
                    payment_mode = "Pre-paid";
                    payment_status = "success";
                }
                return (
                    <div className='address-wrap'>
                        <p className="price_tag">{"Rs. " + params.value}</p>
                        <p className="price_tag">
                            <span className={payment_status}>{payment_mode}</span>
                        </p>
                    </div>
                )
            }
        },
        {
            width: 140,
            field: 'order_fulfillment_status',
            wrapText: true,
            autoHeight: true,
            headerName: 'Fulfil Status',
            cellClass: 'order-fulfillment-status',
            cellRenderer(param) {
                return (<span className={`status-value ${param.value}`}>{param.value}</span>)
            }
        },
        {
            field: 'callStatus',
            width: 250,
            wrapText: true,
            autoHeight: true,
            headerName: 'Call Status',
            cellRenderer(param) {
                let img, text;
                switch (param.value) {
                    case "accepted":
                        img = greenPhone;
                        text = 'Order Accepted';
                        break;
                    case "declined":
                        img = redPhone;
                        text = 'Order Declined';
                        break;
                    case "other":
                        img = yellowPhone;
                        text = param.data?.reason_text || "";
                        break;
                    default:
                        img = blackPhone;
                        text = "";
                        break;
                }
                return (<div className='call-status-block'>
                    <span onClick={() => showModal('Call', param?.data, handleCallStatusChange)} className='call-count-status'>
                        <img alt='call status' src={img} />
                        {param.data?.callCount > 0 && <span className='count'>{param.data?.callCount}</span>}
                    </span>
                    <Link to={''} className={`btn btn-sm btn-primary ${param.value}`} onClick={() => showModal('CallUpdate', param?.data, handleStatusChange)}>{param.value || "Select Status"}</Link>
                    {text !== "" && <> <br /><p className={`status-text ${param.value}`}>{text}</p></>}

                </div>)
            }
        },
        {
            width: 100,
            field: '',
            wrapText: true,
            autoHeight: true,
            headerName: 'Actions',
            cellRenderer(param) {
                return (
                    <div className="more-action">
                        <OverlayTrigger className="" rootClose={true} trigger="click" placement="bottom" overlay={
                            <Popover id="popover-basic" className='z-index-2000 action-buttons new-order'>
                                <Popover.Body>
                                    <button className='view-details' onClick={() => showModal('courierPartner', [param?.data], assignCourier)}>Assign Courier</button>
                                    <button className='print-invoice' onClick={() => printInvoiceMethod([param?.data])}>Print Invoice</button>
                                    <button className='cancel-order' onClick={() => showModal('cancelPopup', param?.data, cancelOrder)}>Cancel Order</button>
                                </Popover.Body>
                            </Popover>
                        }>
                            <img alt='Action' src={actionButton} />
                        </OverlayTrigger>

                    </div>
                )
            }
        },
    ];
    const columnDefs = [
        {
            headerName: 'Order #',
            field: 'order_name',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            width: 280,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {
                let productNames = param.data.orderProducts;
                let tags = param.data?.tag ? param.data?.tag.split(",") : false;
                return (<div>
                    <Link className='grid-link' to={`/orders/new-order/order-details/${btoa(param.data?.order_id)}`}>{param.value}</Link>
                    <p className='font-insetMedium order-date'><Moment format={"DD MMM YYYY hh:mm A"}>{param.data?.order_created_at}</Moment> </p>
                    <p className='logo'>{tags && tags.map((i, k) => <span key={k} className={`order-tag-status ${k + 1 !== tags.length && 'mb-2'}`}>{i?.toLowerCase()}</span>)}</p>
                    <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={
                        <Popover id="popover-basic" className='z-index-2000 produc-list'>
                            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
                            <Popover.Body>
                                {productNames.map((i, j) => <div key={j} className='product-list-container'>
                                    <p className='p-name bold'><b>{i.product_name}</b></p>
                                    <p className='p-sku'>SKU <b>{i.sku}</b></p>
                                    <p className='p-qty'>QTY <b>{i.quantity}</b></p>
                                </div>)}
                            </Popover.Body>
                        </Popover>
                    }>
                        <p className='viewMore'>
                            View Products
                        </p>
                    </OverlayTrigger>
                </div>)
            }
        },
        {
            field: 'app_order_status',
            width: 140,
            wrapText: true,
            autoHeight: true,
            headerName: 'Status',
            cellClass: "app-order-status",
            cellRenderer: (params) => {
                let order_status_class = "";
                if (params.value.toLowerCase() === "cancel") {
                    order_status_class = "cancel";
                } else if (params.value.toLowerCase() === "refunded") {
                    order_status_class = "refund";
                } else if (params.value.toLowerCase() === "delivered") {
                    order_status_class = "delivered";
                } else {
                    order_status_class = "";
                }
                return (<span className={`default-status ${order_status_class}`}>
                    {params.value}
                </span>)
            }
        },
        {
            width: 300,
            field: 'shipping_address',
            wrapText: true,
            autoHeight: true,
            headerName: 'Customer',
            cellClass: "d-flex-center customers",
            cellRenderer(params) {
                let address = params.data?.shipping_address;
                return (
                    <div className="address-wrap">
                        <p className="customer-name">
                            {params.data?.customer_name}
                        </p>
                        <p className="address">
                            {params.data?.customer_email}
                        </p>
                        <p className="address">
                            {address.phone ? address.phone : ''}
                        </p>
                        <p className="address">
                            {address.city ? address.city : ''} - {address.zip ? address.zip : ''}
                        </p>
                    </div>
                )
            }
        },
        {
            headerName: 'Total',
            field: 'total_price',
            width: 140,
            cellClass: "d-flex-center",
            cellRenderer(params) {
                let payment_mode = "";
                let payment_status = "";
                if (params.data?.gateway === "Cash on Delivery (COD)") {
                    payment_mode = "COD";
                    payment_status = "info";
                } else {
                    payment_mode = "Pre-paid";
                    payment_status = "success";
                }
                return (
                    <div className='address-wrap'>
                        <p className="price_tag">{"Rs. " + params.value}</p>
                        <p className="price_tag">
                            <span className={payment_status}>{payment_mode}</span>
                        </p>
                    </div>
                )
            }
        },
        {
            width: 160,
            field: 'order_fulfillment_status',
            wrapText: true,
            autoHeight: true,
            headerName: 'Fulfil Status',
            cellClass: 'order-fulfillment-status',
            cellRenderer(param) {
                return (<span className={`status-value ${param.value}`}>{param.value}</span>)
            }
        },
        {
            width: 120,
            field: '',
            wrapText: true,
            autoHeight: true,
            headerName: 'Actions',
            cellRenderer(param) {
                return (
                    <div className="more-action">
                        <OverlayTrigger className="" rootClose={true} trigger="click" placement="bottom" overlay={
                            <Popover id="popover-basic" className='z-index-2000 action-buttons new-order'>
                                <Popover.Body>
                                    <button className='view-details' onClick={() => showModal('courierPartner', param?.data, assignCourier)}>Assign Courier</button>
                                    <button className='print-invoice' onClick={() => printInvoiceMethod([param?.data])}>Print Invoice</button>
                                    <button className='cancel-order' onClick={() => showModal('cancelPopup', param?.data, cancelOrder)}>Cancel Order</button>
                                </Popover.Body>
                            </Popover>
                        }>
                            <img alt='Action' src={actionButton} />
                        </OverlayTrigger>

                    </div>
                )
            }
        },
    ]
    const cancelOrder = (obj, item) => {
        const config = {};
        commonServiceMethod("app/orders/cancel", "post", obj, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={'This order is ' + item.order_name + ' cancelled'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
                closeModal();
                closeExportPopup();
            }
        });
    }
    const assignCourier = (item, selectedItem, selectedService) => {
        // console.log(item, selectedItem, selectedService);
        let orderids = [];
        let ids;
        if(Array.isArray(item)){
            ids = item.map(i => {
                orderids.push(i.order_id);
                return {
                    "order_id": i.order_id.toString(),
                    "weight": "100",
                    "width": "10",
                    "height": "10",
                    "length": "10"
                }
            })
        }
        else{
            orderids.push(item.order_id);
            ids = [{
                "order_id": item.order_id.toString(),
                "weight": "100",
                "width": "10",
                "height": "10",
                "length": "10"
            }]
        }
        
        let obj = {
            "service": selectedService,
            "location_id": selectedItem.id.toString(),
            "order_ids": orderids.toString(),
            "orderdata": ids
        }
        const config = {};
        commonServiceMethod("app/createLable/bulk", "post", obj, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={res.data?.message} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
                closeModal();
                setOpen(false);
            }
            else {
                toast(<SuccessToast body={'Something went wrong try again'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            }
        });
    }
    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
            if (!checkAccess(11)) {
                // columnDefs.splice(5, 1);
                setComlumnData([...columnDefs]);
            }
            else
                setComlumnData([...columnDefsWithCall]);
        }
    }, [userClaim]);

    const handleCallStatusChange = async (data, item) => {
        setLoader(true);
        try {
            await instance.post(`app/order/makeacall`, data).then((res) => {
                if (res.status === 200 && res?.data?.status_code === 200) {
                    closeModal();
                    // const updatedRecord = rowData.map(i => {
                    //     if (i.order_id === data.order_id) {
                    //         i.callCount = parseInt(i.callCount) + 1;
                    //     }
                    //     return i;
                    // });
                    // setRowData([...updatedRecord]);
                    getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
                    toast(<SuccessToast body={res?.data?.message + ' for the Order ' + item.order_name} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }
                setLoader(false);
            }).catch(e => {
                console.error(e);
                setLoader(false);
            })
        }
        catch (e) {
            console.error(e);
            setLoader(false);
        }
    }

    const onClearFilter = () => {
        getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), "");
    }

    const showModal = (string, value, callBackMethod) => {
        setCallDetails(string);
        setCallRowID(value)
        setPopupData({ string, value, callBackMethod });
    }

    const closeModal = () => {
        setCallDetails('');
        setPopupData({ popupName: "", data: "", callBackMethod: null })
    }


    const handleStatusChange = (val, obj) => {
        setCallStatus(val, obj);
    }

    const setCallStatus = async (status, item) => {
        let url = `app/order/update/callStatus`;
        let obj = {};
        obj.order_id = item.order_id;
        obj.status = status.status;
        obj.reason_text = status.other;
        try {
            setLoader(true);
            await instance.put(url, obj).then((res) => {
                if (res.status === 200 && res?.data?.message === "Update order sucessfully") {
                    closeModal();
                    // const updatedRecord = rowData.map(i => {
                    //     if (i.order_id === item.order_id) {
                    //         i.callStatus = status.status;
                    //         i.reason_text = status.other;
                    //     }
                    //     return i
                    // });
                    // setRowData([...updatedRecord]);
                    getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
                    toast(<SuccessToast body={'Call status updated for Order ' + item.order_name} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
            })
        }
        catch (e) {
            console.error(e);
            // setRowData([]);
        }
    }
    const getData = (
        page = 1,
        startDate,
        endDate,
        searchText,
        perams = ""
    ) => {
        var rowData = [];
        if(searchText !== ""){
            if(/^\d{10}$/.test(searchText)){
                searchText+='&orderDetails=m'
            }
            else if(searchText.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )){
                searchText+='&orderDetails=e'
            }
            else{
                searchText+='&orderDetails='
            }
        }
        try {
            instance.get(`app/order/getOrder?per_page=${per_page}&page=${page}&order_date=${startDate}&end_order_date=${endDate}&search=${searchText}&type=New${perams}`).then((res) => {

                // console.log(res)

                if (res.status === 200 && Array.isArray(res?.data?.orders)) {
                    setRowData(res?.data?.orders || []);
                    setOriginalData(res?.data)
                }

            }).catch(e => {
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        console.log(selectedITem.length > 0)
        // setSelectedObject((selectedObject) => [...selectedObject]);
        selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setSelectedObject([]));
    }
    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        getData(
            pageNo,
            date.startDate.format('YYYY-MM-DD'),
            date.endDate.format('YYYY-MM-DD'),
            searchText
        )
    }
    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }
    const closeExportPopup = () => {
        setOpen(false);
        setSelectedObject([])
        gridRef.current.api.deselectAll();
    }

    const printInvoiceMethod = (obj = []) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        var objdata = "";
        objdata = obj.map((i) => i.order_id).join();
        commonServiceMethod(`app/order/print-invoice/${objdata}?templateName=temp1`, "post", {}, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
            setOpen(false);
            closeExportPopup();
        });
    }
    const onApplyFilter = (selectionOption, searchTextval = "") => {
        console.log(selectionOption)
        let url = "";
        Object.keys(selectionOption).map(i => {

            url += `&${i}=${selectionOption[i]}`
        })
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchTextval || searchText,
            url
        )
        toggleDropdown();
    }
    return (
        <>
            {/* {open ? <Popup selecteObject={selectedObject} closePopup={() => { setOpen(false); gridRef.current.api.deselectAll(); }} /> : null} */}
            <Loader showHide={loader} />
            {/* <div className='quick-buttons'>
                <button className='syncBtn'> <img src={refreshIcon} className="refreshIcon"></img> Sync Orders</button>
            </div> */}
            {open && 
                <div className={`popup-container grid-checkbox-popuop`}>
                    <div className="popup-body">
                        <div className="left-container">
                            <span className="selected-item"> {selectedObject.length} items Seleted</span>
                        </div>
                        <div className="right-container">
                            <button type='button' onClick={() => showModal('courierPartner', selectedObject, assignCourier)} className='btn btn-sm  asign-courier filter-btn'>Assign Courier</button>
                            <button type='button' onClick={() => printInvoiceMethod(selectedObject)} className='btn btn-sm  print-invoice filter-btn'>Print Invoice</button>
                            <button type='button' onClick={() => showModal('cancelPopup', selectedObject, cancelOrder)} className='btn btn-sm cancel-button filter-btn'>Cancel Order</button>
                            <Link to={''} className='close-icon' onClick={() => { setOpen(false); gridRef.current.api.deselectAll(); }} > <img src={CloseIcon}/>  </Link>
                        </div>
                    </div>
                </div>
            
            // <div className="new-order popup-container grid-checkbox-popuop">
            //     <div className="popup-body">
            //         <div className='row'>
            //             <div className='col-md-3 selected-item'>
            //                 {selectedObject.length} items Seleted
            //             </div>
            //             <div className='col-md-9 d-flex'>
            //                 <button type='button' onClick={() => showModal('courierPartner', selectedObject, assignCourier)} className='btn btn-sm  asign-courier filter-btn'>Assign Courier</button>
            //                 <button type='button' onClick={() => printInvoiceMethod(selectedObject)} className='btn btn-sm  print-invoice filter-btn'>Print Invoice</button>
            //                 <button type='button' onClick={() => showModal('cancelPopup', selectedObject, cancelOrder)} className='btn btn-sm cancel-order filter-btn'>Cancel Order</button>
            //                 <Link to={''} className='close-icon' onClick={() => { setOpen(false); gridRef.current.api.deselectAll(); }} > <img src={CloseIcon}/>  </Link>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        }
            <div className='row mb-2 mt-4 filter-section'>
                <div className="filter-left-container">
                    <div className="form-group">
                        <form onSubmit={searchTextChangeEvent}>
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Search} /></span>
                            </div>
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search"
                                className='form-control inputWithIcon'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </form>
                    </div>
                </div>
                <div className='filter-right-container'>
                    <div className="form-group datepicker-class">
                        <div style={{ position: 'relative' }}>
                            <span className="icon"><img alt='' src={Calandar} /></span>
                        </div>
                        <DateRangePicker
                        className="datepicker-custom"
                            initialSettings={
                                {
                                    startDate: startDate,
                                    endDate: endDate,
                                    maxDate: moment().toDate(),
                                    showDropdowns: true,
                                    locale: {
                                        format: 'MMM DD, YYYY',
                                    },
                                    ranges: {
                                        Today: [moment().toDate(), moment().toDate()],
                                        Yesterday: [
                                            moment().subtract(1, 'days').toDate(),
                                            moment().subtract(1, 'days').toDate(),
                                        ],
                                        'Last 7 Days': [
                                            moment().subtract(6, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Last 30 Days': [
                                            moment().subtract(30, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'This Month': [
                                            moment().startOf('month').toDate(),
                                            moment().endOf('month').toDate(),
                                        ],
                                        'Last Month': [
                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                        ],
                                    },
                                }}
                            onApply={(e, val) => dateChangeEvent(val, e)}
                        >
                            <input type="text" className="form-control datepicker-range" />
                        </DateRangePicker>
                    </div>
                    <div className='dropdown-dialog-parent'>
                        <div className={` dropdown-dialog ${isOpen ? 'open' : ''}`}>
                            <button type='button' className='btn btn-sm  filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 15 }} src={AllFilter} /> All Filters</button>
                            {isOpen && (
                                <>
                                    <FilterMenu filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                                </>
                            )}
                        </div>
                    </div>
                </div>                
            </div >
            <DataGridPagination
                rowData={rowData}
                columnDefs={columnData}
                onSelectionChanged={onCellClick}
                gridref={gridRef}
                rowSelection={"multiple"}
                domLayout="autoHeight"
                onPageChange={handlePageClick}
                pageRangeDisplayed={originalData.per_page}
                pageCount={originalData.count}
            />
            {
                callDetails !== "" && <CallStatus
                    item={callRowID}
                    show={callDetails}
                    handleClose={closeModal}
                    handleSave={popupData.callBackMethod}
                />
            }


        </>
    );
}

export default NewOrdersBody;

