import React, { useState, useRef } from 'react'
import Search from '../../../assets/Iconsearch.svg';

const Filter = ({ onApply, onClear }) => {
    const [searchText, setSearchText] = useState();
    const [selectionOption, setSelectionOption] = useState({});
    const ref = useRef(null);

    const clearAll = () => {
        ref.current.reset();
        setSearchText('');
        setSelectionOption({});
        onClear();
    }
    return (
        <div className="accordion-wrapper">
            <ul className="dropdown-list customer-filter-dialog">
                <li className='mt-3'></li>
                <li className="header">Order Count</li>
                <li className='mt-3 mb-3'>
                    <div className="form-group">
                        <div style={{ position: 'relative' }}>
                            <span className="icon"><img alt='' src={Search} /></span>
                        </div>
                        <input
                            type="text"
                            id="filter-text-box"
                            placeholder="Enter your Order Count"
                            className='form-control inputWithIcon'
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </li>
                <li className='d-flex space-between mt-2 mb-2 ml-2'>
                    <button type='button' className='btn btn-sm btns' onClick={clearAll}>Clear All</button>
                    <button type='button' disabled={JSON.stringify(selectionOption) === '{}' && searchText === ''} className='btn btn-sm apply-btn' onClick={() => onApply(selectionOption, searchText)}>Apply Filter</button>
                </li>
            </ul>
        </div>
    )
}

export default Filter