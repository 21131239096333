export const API_URL_STAGING = "https://apiqa.hypership.in:8443/";
export const API_URL_LIVE = "https://api.hypership.in:8443/";
export const SHOPIFY_TOKEN_LIVE = "3d05755217ae257a99ba173094cdf6cf";
export const SHOPIFY_TOKEN_STAGING = "3d05755217ae257a99ba173094cdf6cf";

//export const MANIFEST_DOWNLOAD_URL = 'http://15.207.88.15:8080/app/manifest/label?manifest_id=';
export const MANIFEST_DOWNLOAD_URL = `${location.origin === "https://demo.hypership.in" ? API_URL_STAGING : API_URL_LIVE}app/manifest/label?manifest_id=`;
export const STORE_ID = '63124373688';

export const SHOPIFY_SCOPE = 'read_products,write_products,read_orders,read_inventory,read_product_listings,read_customers,write_orders,read_fulfillments,write_fulfillments,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders';
export const SHOPIFY_CALLBACK_URL = 'https://apiqa.hypership.in:8443/generate_token';
export const SHOPIFY_PLANS_URL = 'https://admin.shopify.com/charges/hypership-2/pricing_plans';
export const AllOrderTabDetails = [

    {
        moduleId: 3,
        value: 'New',
        label: 'New',
        "order": 1,
        url: "/orders/new"
    },
    {
        moduleId: 4,
        value: 'Ready to Ship',
        label: 'Ready to Ship',
        "order": 2,
        url: "/orders/ready-to-ship"
    },
    {
        moduleId: 5,
        value: 'Manifest',
        label: 'Manifest',
        "order": 3,
        url: "/orders/manifest"
    },
    {
        moduleId: 6,
        value: 'In transit',
        label: 'In transit',
        "order": 4,
        url: "/orders/in-transit"
    },
    {
        moduleId: 7,
        value: 'Delivered',
        label: 'Delivered',
        "order": 5,
        url: "/orders/delivery"
    },
    {
        moduleId: 8,
        value: 'NDR',
        label: 'NDR',
        "order": 7,
        url: "/orders/ndr"
    },
    {
        moduleId: 2,
        value: 'All',
        label: 'All',
        "order": 6,
        url: "/orders/all-orders"
    },
    // {
    //     moduleId: 8,
    //     value: 'Cancel',
    //     label: 'Cancel',
    //     "order": 7,
    //     url: "/orders/cancel"
    // },
]
export const ReturnsTabDetails = [
    {
        moduleId: 0,
        value: 'Return Requests',
        label: 'Return Requests',
        "order": 1,
        url: "/returns/return-requests"
    },
    {
        moduleId: 0,
        value: 'New Returns',
        label: 'New Returns',
        "order": 2,
        url: "/returns/new-return"
    },
    {
        moduleId: 0,
        value: 'Return pickup & Scheduled',
        label: 'Return pickup & Scheduled',
        "order": 3,
        url: "/returns/return-pickup-schedule"
    },
    {
        moduleId: 0,
        value: 'In transit',
        label: 'In transit',
        "order": 4,
        url: "/returns/in-transit"
    },
    {
        moduleId: 0,
        value: 'Return Received',
        label: 'Return Received',
        "order": 5,
        url: "/returns/return-received"
    },
    
    {
        moduleId: 0,
        value: 'All',
        label: 'All',
        "order": 6,
        url: "/returns/all-return"
    },
]
export const SideBarMenu = [

    {
        "id": 1,
        "moduleId": 1,
        "menu": 'Dashboard',
        "url": "/dashboard",
        "order": 1,
        "label": "OVERVIEW",
    },
    // {
    //     "id": 2,
    //     "menu": 'Orders',
    //     "moduleId": 0,
    //     "url": "/orders/all-orders",
    //     "order": 2,
    //     "label": "ORDERS",
    // },
    {
        "id": 3,
        "moduleId": 8,
        "menu": 'Tracking',
        "url": "/tracking",
        "order": 3,
        "label": "ORDERS",
    },
    {
        "id": 4,
        "moduleId": 9,
        "menu": 'Returns',
        "url": "/returns/return-requests",
        "order": 4,
        "label": "ORDERS",
    },
    {
        "id": 5,
        "moduleId": 18,
        "menu": 'Products',
        "url": "/products",
        "order": 5,
        "label": "SETTINGS",
    },
    {
        "id": 6,
        "moduleId": 17,
        "menu": 'Customers',
        "url": "/customers",
        "order": 6,
        "label": "SETTINGS",
    },
    {
        "id": 7,
        "moduleId": 0,
        "menu": 'Settings',
        "url": "/settings",
        "order": 7,
        "label": "SETTINGS",
    }
]