import React from 'react';
import PageLayout from '../../../global/pageLayout';
import ManageBilling from './body';

const index = () => {
    return (
        <>
            <PageLayout tabMenu={false} body={<ManageBilling />} />
        </>
    )
}

export default index
