import React from 'react'
import backArrow from '../../../assets/backArrow.svg';
import { Link } from 'react-router-dom';

const Body = () => {
    return (
        <>
        <div className="parent-div">
            <div className='container mt-3 mb-3' >
                <div className='row'>
                    <div className='col-md-9'>
                        <Link to={'/settings'}>
                            <img src={backArrow} /> Back
                        </Link>

                    </div>
                    <div className='col-md-3 d-flex justify-content-end'>

                    </div>
                </div>
                <div className='row mt-3 mb-3'>
                    <div className='col-md-8'>
                        <p className='page-header-text mb-2'>Manage Serviceable Pincodes</p>
                        <p className='details'>Shipping provides are used when you purchase postage and print labels. Fulfillments provides are used when you have 3rd party services who packages and ships your orders for you connect and manage your provides for however you ship</p>

                        <div className='col-md-6'>

                            <label className='setting-courier-model-label'>Select Courier</label>
                            <select className='call-status form-select mb-3'>
                                <option value="select">Select Courier</option>
                            </select>

                            <label className='setting-courier-model-label'>Select Payment Mode</label>
                            <div className='row mb-3'>
                                <div className='col-md-5'>
                                    <label className='setting-courier-model-label'>
                                        <input type='radio' checked='checked' />&nbsp;Cash On Delivery
                                    </label>
                                </div>
                                <div className='col-md-4'>
                                    <label className='setting-courier-model-label'>
                                        <input type='radio' />&nbsp;Pre-Paid
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-9 mb-3'>
                                <label className='setting-courier-model-label mb-1'>Upload Pincode Sheet</label>
                                <div className="file-input-container">
                                    <button className="browse-button" >
                                        Browse
                                    </button>
                                    <input
                                        type="file"
                                        accept="" // Specify accepted file types
                                        className="file-input btn-sm btn"
                                    />
                                </div>
                                <label className='userLabel mt-2'>
                                    The following file extensions are supported: CSV<br />
                                    <Link to={''}>Download Sample File</Link>
                                </label>
                            </div>

                            <div className='col-md-6 mb-3'>
                                <button type='button' className='btn btn-primary'>Upload Pincodes</button>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            </div>
        </>
    )
}

export default Body