import React from 'react';
import PageLayout from '../../global/pageLayout';
import ReadyToShipBody from './body';

const ReadyToShip = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<ReadyToShipBody />} selectedItem = "Ready to Ship" />
        </div>
    )
}

export default ReadyToShip;