import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useIdleTimer, workerTimers } from 'react-idle-timer/legacy'


function CheckIdel() {

    const navigate = useNavigate();
    const [remaining, setRemaining] = useState(0)
    const [open, setOpen] = useState(false);

    const timeout = 15 * 60 * 1000;
    const promptTimeout = 5 * 60 * 1000;

    const onIdle = () => {
        setOpen(false);
        setRemaining(0);
        //console.log(remaining);
        if (remaining === 1) {
            console.log('timeout');
            logOut();
        }
    }

    const onActive = () => {
        setOpen(false);
        setRemaining(0);
    }

    const onPrompt = () => {
        setOpen(true);
        setRemaining(promptTimeout);
    }

    const logOut = () => {
        setOpen(false);
        localStorage.clear();
        navigate('/')

    };

    const { getRemainingTime, isPrompted, reset } = useIdleTimer({
        timeout,
        promptTimeout,
        onPrompt,
        onIdle,
        onActive,
        crossTab: true,
        timers: workerTimers
    });

    const handleStillHere = () => {
        setOpen(false);
        reset();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }

        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [getRemainingTime, isPrompted]);

    return (
        <>

            <Modal
                size='md'
                show={open}

                centered
                scrollable
            >
                <div className='modal-content modal-lg'>
                    <div className="modal-header border-bottom-0 justify-content-between">
                        <h5 className="modal-title">You&#39;ve been idle for {timeout / 1000 / 60} minutes</h5>
                    </div>
                    <div className="modal-body modal-lg">
                        Logging you out in You&#39;ve been inactive for a while. To keep your session active,
                        please click the button below. You have{`${Math.floor(remaining / 60)}`.padStart(2, 0)}:
                        {`${remaining % 60}`.padStart(2, 0)}  left.
                    </div>
                    <div className="modal-footer">
                        <button onClick={handleStillHere} className="btn btn-sm btn-primary">Keep Session Active</button>
                    </div>
                </div>
            </Modal>

        </>
    );
}

export default CheckIdel;

