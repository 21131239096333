import React from 'react';
import PageLayout from '../../global/pageLayout';
import NdrOrdersBody from './body';

const NdrOrders = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<NdrOrdersBody />} selectedItem = "NDR" />
        </div>
    )
}

export default NdrOrders;