import React, { useRef, useState, useEffect } from 'react';
// import BillingIcon from '../../../../assets/billingIcon.svg';
import { Link } from 'react-router-dom';
import backArrow from '../../../../assets/backArrow.svg';
import BillingAdvanced from '../../../../assets/billing/billing-advanced.png';
import BillingIocn from '../../../../assets/billing/billing.png';
import './style.scss'
import DataGridPagination from '../../../global/dataGridPagination';
import Moment from 'react-moment';
import Loader from '../../../global/loader';
import { useSelector } from 'react-redux';
import { commonServiceMethod } from '../../../../helpers/helper';
import moment from 'moment';

const Billing = () => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const columnData = [
        {
            headerName: 'Invoice ID',
            field: 'invoiceId',
            width: 120,
        },
        {
            field: 'invoiceDate',
            width: 200,
            wrapText: true,
            autoHeight: true,
            headerName: 'Invoice Generation Date',
            cellRenderer: (params) => {
                return (<Moment format={"DD MMM YYYY"}>{params.value}</Moment>)
            }
        },
        {
            width: 150,
            field: 'dueDate',
            wrapText: true,
            autoHeight: true,
            headerName: 'Invoice Due Date',
            cellRenderer: (params) => {
                return (<Moment format={"DD MMM YYYY"}>{moment(params.value).add(7, 'days')}</Moment>)
            }
        },
        {
            headerName: 'Status',
            field: 'status',
            width: 150,
            cellClass: "app-order-status",
            cellRenderer(params) {
                return (
                    params.value === "Paid" ? <span className='delivered'>{params.value}</span> : <span className='cancel'>{params.value}</span>
                )
            }
        },
        {
            width: 150,
            field: 'invoiceAmount',
            wrapText: true,
            autoHeight: true,
            headerName: 'Invoice Amount',
            cellClass: '',
            // cellRenderer(param) {
            //     return (<Moment format={"DD MMM YYYY hh:mm A"}>{param.value}</Moment>)
            // }
        },
        {
            width: 130,
            field: 'amountDue',
            wrapText: true,
            autoHeight: true,
            headerName: 'AmountDue',
            // cellRenderer(param) {
            //     return (<Moment format={"DD MMM YYYY hh:mm A"}>{param.value}</Moment>)
            // }
        },
        {
            width: 170,
            field: '',
            wrapText: true,
            autoHeight: true,
            headerName: 'Action',
            cellRenderer(params) {
                return (
                    params.data.status === "paid" ? <span className='download'><Link>Download Invoice</Link></span> : '-'
                )
            }
        },
    ];

    const [loader, setLoader] = useState(false);
    const userClaim = useSelector((state) => state.user);
    const [currentPlan, setCurrentPlan] = useState({});
    useEffect(() => {
        if (userClaim.token !== "") {
            getTransaction();
            getCurrentPlan();

        }
    }, [userClaim]);
    const getTransaction = () => {
        commonServiceMethod(`app/getTransaction  `, "get", {}, {}, setLoader, (res) => {
            console.log(res);
            if (res?.status === 200) {

                const data = res?.data?.data?.map(i => {
                    return {
                        invoiceId: i.id,
                        invoiceDate: moment(i.initDate).format("DD MMM YYYY"),
                        dueDate: moment(i.initDate).format("DD MMM YYYY"),
                        status: i.status,
                        invoiceAmount: (i.currency === 'USD' ? '$' : '') + i.amount,
                        amountDue: (i.currency === 'USD' ? '$' : '') + i.amount
                    }
                });
                setOriginalData(data);
                setRowData(data.slice(0, 10));
            }
        })
    }
    const getCurrentPlan = () => {
        commonServiceMethod(`app/getPlanByStore  `, "get", {}, {}, setLoader, (res) => {
            console.log(res);
            if (res?.status === 200) {

                setCurrentPlan(res?.data?.data || {})
            }
        })
    }
    return (
        <>
            <Loader showHide={loader} />
            {/* <img src={BillingIcon} /> */}
            <div className="parent-div billing-page">
                <div className='container mt-3 mb-3' >
                    <div className='row'>
                        <div className='col-md-9'>
                            <Link to={'/settings'}>
                                <img src={backArrow} /> Back
                            </Link>
                        </div>
                        <div className='col-md-9 '>
                            <p className='bill-titles'>Billing</p>
                        </div>
                        <div className='billin-card'>
                            <div className='card mb-0'>
                                <div className='card-header'>
                                    <div className='displayFlex'>
                                        <span className='card-icons'>
                                            <img src={BillingIocn} />
                                        </span>
                                        <h5 className='setting-card-title'>Billing details</h5>
                                    </div>
                                </div>
                                {
                                    currentPlan.hasOwnProperty("name") &&
                                    <div className='card-body'>
                                        <div className='left-container'>
                                            <p className='advanced-section'><img src={BillingAdvanced} /> {currentPlan?.name}</p>
                                            <p>
                                                <span className='billing-cyle'>Current Billing cycle</span>
                                                <span className='billing-date'>{currentPlan?.planEndDate && moment(currentPlan?.planEndDate).subtract(1, 'months').format("DD MMM YYYY")} {currentPlan?.planEndDate && '-'} {currentPlan?.planEndDate && moment(currentPlan?.planEndDate).format("DD MMM YYYY")}</span>
                                            </p>
                                        </div>
                                        <div className='right-container'>
                                            <p className='total-text'>Current total <span className='amount'>{currentPlan?.amount}</span> {currentPlan?.currency}/month</p>
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>

                        <div className='col-md-9'>
                            <p className='bill-titles'>Past Bills</p>
                        </div>
                        <div className='billing-table'>
                            <DataGridPagination
                                rowData={rowData}
                                columnDefs={columnData}
                                onSelectionChanged={() => { }}
                                gridref={gridRef}
                                rowSelection={"multiple"}
                                domLayout="autoHeight"
                                onPageChange={(e) => {
                                    setRowData(originalData.slice(e.selected * 10, (e.selected * 10) + 10))
                                }}
                                pageRangeDisplayed={10}
                                pageCount={originalData.length}
                                topPagination={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Billing
