import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SuccessToast from '../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import CallStatusPopup from '../../global/popupModal/CallStatus';
import Loader from '../../global/loader';
import { commonServiceMethod } from '../../../helpers/helper';
import redPhone from '../../../assets/red-phone.svg';
import greenPhone from '../../../assets/green-phone.svg';
import blackPhone from '../../../assets/black-phone.svg';
import yellowPhone from '../../../assets/yellow-phone.svg';
import callIcon from '../../../assets/callIcon.svg'

const CallStatus = ({ orderDetail, setRefreshData }) => {
    const [orderDetailData, setOrderDetail] = useState({});
    const [loader, setLoader] = useState(false);
    const [img, setImg] = useState("");
    const [text, setText] = useState("");
    useEffect(() => {
        setOrderDetail(orderDetail?.order || {});

        switch (orderDetail?.order?.callStatus) {
            case "accepted":
                setImg(greenPhone);
                setText("");
                break;
            case "declined":
                setImg(redPhone);
                setText(orderDetail?.order?.reason_text || "");
                break;
            case "other":
                setImg(yellowPhone);
                setText(orderDetail?.order?.reason_text || "");
                break;
            default:
                setImg(blackPhone);
                setText("");
                break;
        }
    }, [orderDetail])

    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })
    const setCallStatus = async (status) => {
        let obj = {};
        obj.order_id = orderDetailData.order_id;
        obj.status = status.status;
        obj.reason_text = status.other;
        try {
            setLoader(true);
            await commonServiceMethod(`app/order/update/callStatus`, "put", obj, {}, setLoader, (res) => {
                if (res.status === 200 && res?.data?.message === "Update order sucessfully") {
                    closePopup();
                    setRefreshData(refreshData => refreshData + 1);
                    const updatedRecord = rowData.map(i => {
                        if (i.order_id === orderDetailData.order_id) {
                            i.callStatus = status.status;
                            i.reason_text = status.other;
                        }
                        return i
                    });
                    setRowData([...updatedRecord]);
                    toast(<SuccessToast body={'Call status updated for Order ' + item.order_name} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }

            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            // setRowData([]);
        }
    }
    const setPopup = () => {
        setPopupData({ popupName: 'CallUpdate', data: orderDetailData, callBackMethod: setCallStatus })
    }
    const closePopup = () => {
        setPopupData({ popupName: "", data: "", callBackMethod: null })
    }
    return (
        <>
            <Loader showHide={loader} />
            <div className="card order-detail-callstatus">
                <div className='card-header'>
                    <div className='displayFlex'>
                        <span className='card-icons'>
                            <img src={callIcon} alt='callIcon' />
                        </span>
                        <h5 className='orderDetailTitle'>Call Status</h5>
                    </div>
                </div>

                <div className='card-body'>
                    <div className="d-flex mb-3">
                        <button className="btn btn-sm filter-btn"><img src={img} /> &nbsp;
                            <span className={`${orderDetailData.callStatus} call-status`} style={{ fontSize: '15.53px' }}>{orderDetailData.callStatus}</span>
                        </button>
                        &nbsp;
                        <Link to={''} onClick={setPopup} className="linkUpdate" >Update Status</Link>
                    </div>
                    <label className="textLabel">Description</label>
                    <p>{text}</p>
                </div>
            </div>
            {console.log("popupData", popupData)}
            {popupData.popupName !== "" &&
                <CallStatusPopup
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closePopup}
                />
            }
        </>
    )
}

export default CallStatus