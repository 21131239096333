import React, { useEffect, useState } from "react";
// import Loader from "../../global/loader";
// import axios from "axios";
// import { useSelector } from 'react-redux';
// import SuccessToast from "../../customToast/SuccessToast";
// import { Slide, toast } from "react-toastify";
// import { commonServiceMethod } from "../../../helpers/helper";
import SelfInstallIcon from '../../../assets/selfInstall.svg';
import { SHOPIFY_TOKEN_LIVE, SHOPIFY_TOKEN_STAGING, SHOPIFY_SCOPE, API_URL_STAGING, API_URL_LIVE } from '../../../constants/index';

function Body() {
    // const userClaim = useSelector((state) => state.user);
    // const [loader, setLoader] = useState(false);

    const [errors, ] = useState({});
    const [formData, setFormData] = useState({
        store_name: '',
    })
    // const authUrl = `https://${formData?.store_name}/admin/oauth/authorize?client_id=${SHOPIFY_KEY}&scope=${encodeURIComponent(SHOPIFY_SCOPE)}&redirect_uri=${encodeURIComponent(SHOPIFY_CALLBACK_UR)}`;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData?.store_name);

        //http://orderlytics.myshopify.com

        let KEY = '';
        let SHOPIFY_CALLBACK_URL = '';
        if (window.location.href.search("demo.hypership.in") > -1) {
            KEY = SHOPIFY_TOKEN_STAGING;
            //https://apiqa.hypership.in:8443/generate_token
            SHOPIFY_CALLBACK_URL = API_URL_STAGING + 'generate_token';
        }
        else if (window.location.href.search("app.hypership.in") > -1) {
            KEY = SHOPIFY_TOKEN_LIVE;
            SHOPIFY_CALLBACK_URL = API_URL_LIVE + 'generate_token';
        }
        else {
            KEY = SHOPIFY_TOKEN_STAGING;
            SHOPIFY_CALLBACK_URL = API_URL_STAGING + 'generate_token';
        }
        console.log(KEY, SHOPIFY_CALLBACK_URL, 'key');
        let shop = formData?.store_name + '.myshopify.com';
        const authUrl = `https://${shop}/admin/oauth/authorize?client_id=${KEY}&scope=${encodeURIComponent(SHOPIFY_SCOPE)}&redirect_uri=${encodeURIComponent(SHOPIFY_CALLBACK_URL)}`;
        console.log(authUrl);
        //window.location.href = authUrl;
        window.open(authUrl, '_blank');
        // setLoader(true)
        // setErrors('');

    }
    useEffect(() => {
        console.log('submited')
    }, []);

    return (
        <>
            {/* <Loader showHide={loader} />  */}
            <div className="parent-div">
                <div className="row">
                    <div className="col-md-9">
                        <div className="topview mb-4">
                            <div className="col-md-6">
                                <h4 className='fw-600'>Self Installation</h4>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit}>

                            <div className="card">
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-md-11 d-flex'>
                                            <span className='card-icons'>
                                                <img src={SelfInstallIcon} alt="SelfInstallIcon" />
                                            </span>
                                            <h5 className='orderDetailTitle'>Default - OAuth Parameters </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>

                                        <div className='col-md-12 mb-1'>
                                            <label className='setting-courier-model-label'>Please enter your Shopify store name <span style={{ color: 'red' }}>*</span></label>
                                            <input type='text' className='form-control' name='store_name' value={formData.store_name} onChange={(e) => handleChange(e)} />
                                            {errors.store_name && <p className="error">{errors.store_name}</p>}
                                            <p style={{ color: 'grey' }}>
                                                If your Shopify store URL  is acme.myshopify.com, please enter acme
                                            </p>
                                        </div>
                                        {/* <p style={{ color: 'red' }}>The Store is already connected</p> */}
                                    </div>

                                </div>

                            </div>

                            <div className="row" style={{ justifyContent: 'end' }}>
                                <div className="col-md-2">
                                    <button type="reset" className='btn btn-md filter-btn'>Cancel</button>
                                </div>
                                <div className="col-md-1 me-5">
                                    <button type="submit" className='btn btn-md btn-primary' disabled={formData.store_name === ''}>Submit</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Body