import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import FeatureIcon from "../../../assets/FeaturedIcon.svg";

const CourierIntegration = ({ item, show, handleClose, handleSave, cType }) => {
    console.log(cType, item);
    // const [enableSaveBtn,] = useState(false);
    const [formData, setFormData] = useState(item);

    const summeryChange = (parent, key, value) => {
        let data = formData;
        data[parent][key] = value;
        console.log("xpressbees form data", data);
        setFormData(data);
    }
    return (<>

        <Modal
            size='sm'
            show={show === 'editCourier'}
            onHide={handleClose}
            centered
            scrollable
            className='courier-modal'
            backdrop="static"
        >
            <div className='modal-content modal-lg'>
                <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="setting-courier-model-title ">Edit Courier</h5>
                </div>
                {cType === 'delhivery' ?
                    <div className="modal-body modal-lg">
                        <label className='setting-courier-model-label'>Account Name</label>
                        <input type='text' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} name='display_name' className='form-control mb-3' defaultValue={formData.display_name} />

                        <label className='setting-courier-model-label'>Client/Warehouse name</label>
                        <input type='text' readOnly onChange={(e) => setFormData({ ...formData, shipping_carrier_name: e.target.value })} name='shipping_carrier_name' className='form-control mb-3' defaultValue={formData?.shipping_carrier_name} />

                        <label className='setting-courier-model-label'>API Key</label>
                        <input type='text' onChange={(e) => summeryChange("delhiveryShippingCarriersRequest", "key", e.target.value)} name='key' className='form-control mb-3' defaultValue={formData?.delhiveryShippingCarriersRequest?.key} />

                    </div>
                    : cType === 'bluedart' ?
                        <div className="modal-body modal-lg">
                            <label className='setting-courier-model-label'>Account Name</label>
                            <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} defaultValue={formData.display_name} />

                            <label className='setting-courier-model-label'>Client/Warehouse name</label>
                            <input type='text' readOnly onChange={(e) => setFormData({ ...formData, shipping_carrier_name: e.target.value })} name='shipping_carrier_name' className='form-control mb-3' defaultValue={formData?.shipping_carrier_name} />

                            <label className='setting-courier-model-label'>Account Number</label>
                            <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => summeryChange("blueDartShippingCarriersRequest", "accountnumber", e.target.value)} defaultValue={formData?.blueDartShippingCarriersRequest?.accountnumber} />

                            <label className='setting-courier-model-label'>Tracking Licence Key</label>
                            <input type='text' name='trackingKeyLoc' className='form-control mb-3' onChange={(e) => summeryChange("blueDartShippingCarriersRequest", "traking_licence_key", e.target.value)} defaultValue={formData?.blueDartShippingCarriersRequest?.traking_licence_key} />

                            <label className='setting-courier-model-label'>Login Id</label>
                            <input type='text' name='loginId' className='form-control mb-3' onChange={(e) => summeryChange("blueDartShippingCarriersRequest", "traking_login_id", e.target.value)} defaultValue={formData?.blueDartShippingCarriersRequest?.traking_login_id} />

                            <label className='setting-courier-model-label'>Customer Code</label>
                            <input type='text' name='customerCode' className='form-control mb-3' onChange={(e) => summeryChange("blueDartShippingCarriersRequest", "customer_email_id", e.target.value)} defaultValue={formData?.blueDartShippingCarriersRequest?.customer_email_id} />

                            <label className='setting-courier-model-label'>Customer Mobile</label>
                            <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("blueDartShippingCarriersRequest", "customer_mobile", e.target.value)} defaultValue={formData?.blueDartShippingCarriersRequest?.customer_mobile} />

                            <label className='setting-courier-model-label'>API Key</label>
                            <input type='text' name='customerPincode' className='form-control mb-3' onChange={(e) => summeryChange("blueDartShippingCarriersRequest", "key", e.target.value)} defaultValue={formData?.blueDartShippingCarriersRequest?.key} />

                            <label className='setting-courier-model-label'>Origin Area</label>
                            <input type='text' name='originArea' className='form-control mb-3' onChange={(e) => summeryChange("blueDartShippingCarriersRequest", "origin_area", e.target.value)} defaultValue={formData?.blueDartShippingCarriersRequest?.origin_area} />

                            <label className='setting-courier-model-label'>Password</label>
                            <input type='text' name='productCode' className='form-control mb-3' onChange={(e) => summeryChange("blueDartShippingCarriersRequest", "password", e.target.value)} defaultValue={formData?.blueDartShippingCarriersRequest?.password} />
                        </div>
                        : cType === 'other' ?
                            <div className="modal-body modal-lg">
                                <label className='setting-courier-model-label'>Account Name</label>
                                <input type='text' name='accountName' className='form-control mb-3' defaultValue={formData.display_name} />

                                <label className='setting-courier-model-label'>Client/Warehouse name</label>
                                <input type='text' name='clientName' className='form-control mb-3' defaultValue={formData.display_name} />

                                <label className='setting-courier-model-label'>API Key</label>
                                <input type='text' name='apiKey' className='form-control mb-3' defaultValue={settingData?.key} />

                            </div>
                            : cType === 'dtdc' ?
                                <div className="modal-body modal-lg">

                                    <label className='setting-courier-model-label'>Account Name</label>
                                    <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} defaultValue={formData.display_name} />

                                    <label className='setting-courier-model-label'>Cod Customer Code</label>
                                    <input type='text' name='apiKey' className='form-control mb-3' onChange={(e) => summeryChange("dtdcShippingCarriersRequest", "cod_customer_code", e.target.value)} defaultValue={formData.dtdcShippingCarriersRequest?.cod_customer_code} />

                                    <label className='setting-courier-model-label'>COD Token</label>
                                    <input type='text' name='trackingToken' className='form-control mb-3' onChange={(e) => summeryChange("dtdcShippingCarriersRequest", "cod_key", e.target.value)} defaultValue={formData.dtdcShippingCarriersRequest?.cod_key} />

                                    <label className='setting-courier-model-label'>Customer ID</label>
                                    <input type='text' name='customerID' className='form-control mb-3' onChange={(e) => summeryChange("dtdcShippingCarriersRequest", "customer_code", e.target.value)} defaultValue={formData.dtdcShippingCarriersRequest?.customer_code} />

                                    <label className='setting-courier-model-label'>API Key</label>
                                    <input type='text' name='apiKey' className='form-control mb-3' onChange={(e) => summeryChange("dtdcShippingCarriersRequest", "key", e.target.value)} defaultValue={formData.dtdcShippingCarriersRequest?.key} />

                                    <label className='setting-courier-model-label'>Document Type</label>
                                    <input type='text' name='docType' className='form-control mb-3' onChange={(e) => summeryChange("dtdcShippingCarriersRequest", "load_type", e.target.value)} defaultValue={formData.dtdcShippingCarriersRequest.load_type} />

                                    <label className='setting-courier-model-label'>Reference number</label>
                                    <input type='text' name='docType' className='form-control mb-3' onChange={(e) => summeryChange("dtdcShippingCarriersRequest", "reference_number", e.target.value)} defaultValue={formData.dtdcShippingCarriersRequest.reference_number} />

                                    <label className='setting-courier-model-label'>Service Type</label>
                                    <input type='text' name='serviceType' className='form-control mb-3' onChange={(e) => summeryChange("dtdcShippingCarriersRequest", "service_type_id", e.target.value)} defaultValue={formData.dtdcShippingCarriersRequest?.service_type_id} />

                                    <label className='setting-courier-model-label'>Tracking Token</label>
                                    <input type='text' name='commodityName' className='form-control mb-3' onChange={(e) => summeryChange("dtdcShippingCarriersRequest", "tracking_key", e.target.value)} defaultValue={formData.dtdcShippingCarriersRequest?.tracking_key} />

                                </div>
                                : cType === 'ekart' ?
                                    <div className="modal-body modal-lg">
                                        <label className='setting-courier-model-label'>Account Name</label>
                                        <input type='text' name='accountName' className='form-control mb-3' onChange={(e) => setFormData({ ...formData, display_name: e.target.value })} defaultValue={formData.display_name} />

                                        <label className='setting-courier-model-label'>Token</label>
                                        <input type='text' name='token' className='form-control mb-3' onChange={(e) => summeryChange("ecartShippingCarriersRequest", "key", e.target.value)} defaultValue={formData?.blueDartShippingCarriersRequest?.key} />

                                        <label className='setting-courier-model-label'>Merchant Code</label>
                                        <input type='text' name='merchantCode' className='form-control mb-3' onChange={(e) => summeryChange("ecartShippingCarriersRequest", "merchant_code", e.target.value)} defaultValue={formData?.blueDartShippingCarriersRequest?.merchant_code} />

                                        {/* <label className='setting-courier-model-label'>Location Code</label>
                                        <input type='text' name='locationCode' className='form-control mb-3' defaultValue={formData.display_name} />

                                        <label className='setting-courier-model-label'>Goods Category</label>
                                        <input type='text' name='goodsCategort' className='form-control mb-3' defaultValue={formData.display_name} />

                                        <label className='setting-courier-model-label'>Service Code</label>
                                        <input type='text' name='serviceCode' className='form-control mb-3' defaultValue={formData.display_name} />

                                        <label className='setting-courier-model-label'>Service Types</label>
                                        <input type='text' name='srviceType' className='form-control mb-3' defaultValue={formData.display_name} />

                                        <label className='setting-courier-model-label'>Delivery Types</label>
                                        <input type='text' name='deliveryType' className='form-control mb-3' defaultValue={formData.display_name} /> */}

                                    </div>
                                    : cType === 'pickrr' ?
                                        <div className="modal-body modal-lg">
                                            <label className='setting-courier-model-label'>Account Name</label>
                                            <input type='text' name='accountName' className='form-control mb-3' defaultValue={formData.display_name} />

                                            <label className='setting-courier-model-label'>API Key</label>
                                            <input type='text' name='apiKey' className='form-control mb-3' defaultValue={formData.display_name} />

                                        </div>
                                        : cType === 'xpressbees' ?
                                            <div className="modal-body modal-lg">
                                                <label className='setting-courier-model-label'>Account Name</label>
                                                <input type='text' name='accountName' className='form-control mb-3' defaultValue={formData.display_name}
                                                    onChange={(e) => setFormData({ ...formData, display_name: e.target.value })}
                                                />

                                                <label className='setting-courier-model-label'>Username</label>
                                                <input type='text' name='username' className='form-control mb-3' defaultValue={formData?.xpressBeesShippingCarriersRequest?.username}
                                                    onChange={(e) => summeryChange("xpressBeesShippingCarriersRequest", "username", e.target.value)}
                                                />
                                                <label className='setting-courier-model-label'>Password</label>
                                                <input type='text' name='password' className='form-control mb-3' defaultValue={formData?.xpressBeesShippingCarriersRequest?.password} 
                                                onChange={(e) => summeryChange("xpressBeesShippingCarriersRequest", "password", e.target.value)}
                                                />

                                            </div> 
                                            :cType === 'ecomexpress' ?
                                            <div className="modal-body modal-lg">
                                                <label className='setting-courier-model-label'>Account Name</label>
                                                <input type='text' name='accountName' className='form-control mb-3' defaultValue={formData.display_name}
                                                    onChange={(e) => setFormData({ ...formData, display_name: e.target.value })}
                                                />

                                                <label className='setting-courier-model-label'>Username</label>
                                                <input type='text' name='username' className='form-control mb-3' defaultValue={formData?.ecomexpressShippingCarriersRequest?.username}
                                                    onChange={(e) => summeryChange("ecomexpressShippingCarriersRequest", "username", e.target.value)}
                                                />
                                                <label className='setting-courier-model-label'>Password</label>
                                                <input type='text' name='password' className='form-control mb-3' defaultValue={formData?.ecomexpressShippingCarriersRequest?.password} 
                                                onChange={(e) => summeryChange("ecomexpressShippingCarriersRequest", "password", e.target.value)}
                                                />

                                            </div>:''                                          }

            </div>

            <div className="modal-footer">

                <div className="col-12 mb-4 d-flex justify-content-end">
                    <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm w-100 save-btn btn-primary" onClick={() => handleSave(formData)} type="submit" >Save</button>

                </div>

            </div>

        </Modal>

        <Modal
            size='md'
            show={show === 'deleteCourier'}
            onHide={handleClose}
            centered
            scrollable
            className='courier-delete-popup'
            backdrop="static"
        >
            {/* {cType === 'delhivery' ? */}
            <div className='modal-content modal-lg'>
                <div className="modal-body modal-lg">
                    <div className='row'>
                        <div className='col-md-2' style={{ paddingRight: '0px' }}>
                            <img src={FeatureIcon} />
                        </div>
                        <div className='col-md-8' style={{ paddingLeft: '0px' }}>
                            <h5 style={{ color: '#101828', fontSize: '18px', fontWeight: '600' }}>Clear Details</h5>
                            <p style={{ color: '#667085', fontSize: '14px' }}>Are you sure want to Clear all details? </p>
                        </div>
                    </div>
                </div>

            </div>
            {/* : ''} */}

            <div className="modal-footer">

                <div className="col-12 mb-4 d-flex justify-content-end">
                    <button className="btn btn-sm cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm clear-now-btn btn-danger" onClick={() => handleSave(formData)} type="submit" >Clear Now</button>

                </div>

            </div>

        </Modal>

    </>)

}

export default CourierIntegration;