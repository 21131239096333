import React, { useState, useRef } from 'react'
import backArrow from '../../../../assets/backArrow.svg';
import { Link } from 'react-router-dom';
import DataGridPagination from '../../../global/dataGridPagination';
import { Popup } from '../../../global/popupModal/hsnGstDialog';
const Body = () => {
    const [open, setOpen] = useState(false);
    const [selectedObject, setSelectedObject] = useState([]);
    const gridRef = useRef();
    const [formData, setFormData] = useState({
        hsnCode: '',
        gstno: '',
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const rowData = [
        {
            products: 'Face care cream',
            productCode: 'SKDFHGSHD123',
            hsnCode: '125345214',
            gst: '18.00',
            id: '1',

        },
        {
            products: 'Face care cream',
            productCode: 'SKDFHGSHD123',
            hsnCode: '125345214',
            gst: '18.00',
            id: '2',

        },
        {
            products: 'Face care cream',
            productCode: 'SKDFHGSHD123',
            hsnCode: '125345214',
            gst: '18.00',
            id: '3',

        }
    ]

    const columnDefs = [
        {
            field: 'id',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            headerName: '#',
            cellClass: "check-box-container",
            width: 100,
        },
        {
            field: 'products',
            wrapText: true,
            autoHeight: true,
            headerName: 'Product',
            cellClass: "check-box-container",
            width: 350,
        },
        {
            field: 'productCode',
            wrapText: true,
            autoHeight: true,
            headerName: 'SKU/Product Code',
            cellClass: "app-order-status",
            width: 250,
        },
        {
            field: 'hsnCode',
            wrapText: true,
            autoHeight: true,
            headerName: 'HSN Code',
            width: 350,
            cellRenderer(param) {
                return (
                    <>
                        <input type='text' onChange={(e) => handleChange(e)} value={param.value} className='form-control' style={{ backgroundColor: '#D0D5DD' }} />
                    </>
                )
            }
        },
        {
            field: 'gst',
            wrapText: true,
            autoHeight: true,
            headerName: 'GST(%)',
            cellClass: "d-flex-center",
            width: 200,
            cellRenderer(param) {
                return (
                    <>
                        <input type='text' onChange={(e) => handleChange(e)} value={param.value} className='form-control' style={{ backgroundColor: '#D0D5DD' }} />
                    </>
                )
            }
        },

    ]

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        console.log(selectedITem.length > 0, selectedITem.length, selectedITem);
        //selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setSelectedObject([]));
        // if (selectedITem.length > 0) {
        //     setOpen(true);
        //     setSelectedObject([...selectedITem]);
        //     console.log(selectedITem);
        // }
        // else {
        //     setOpen(false);
        //     setSelectedObject([]);
        //     console.log(selectedITem);
        // }
    }
    const exportMethod = () => {
        var objdata = {};
        const config = {};
        objdata.order_id = selectedObject.map((i) => i.order_id).join();
        commonServiceMethod("app/order/export", "post", objdata, config, setLoader, (res) => {
            console.log("res", res)
            const downloadUrl = window.URL.createObjectURL(
                new Blob([res.data])
            );
            console.log(downloadUrl)
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", "file.csv"); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            closeExportPopup();
        });
    }

    const closeExportPopup = () => {
        setOpen(false);
        setSelectedObject([])
        gridRef.current.api.deselectAll();
    }

    return (
        <>
        <div className="parent-div">
            <div className='container mt-3 mb-3' >
                <div className='row'>
                    <div className='col-md-9'>
                        <Link to={'/settings'}>
                            <img src={backArrow} /> Back
                        </Link>

                    </div>
                    <div className='col-md-3 d-flex justify-content-end'>

                    </div>
                </div>
                <div className='row mt-3 mb-3'>
                    <div className='col-md-12'>
                        <div className='row  mb-1'>
                            <div className='col-md-11'>
                                <p className='page-header-text' style={{ marginTop: '5px', marginBottom: '5px' }}>HSN Code and GST %</p>
                                <p className='details' style={{ marginBottom: '5px' }}>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu</p>
                            </div>
                            <div className='col-md-1'>
                                <button className='btn btn-primary'>Save</button>
                            </div>
                        </div>
                        {open ? <Popup exportMethod={exportMethod} selecteObject={selectedObject} closePopup={() => { closeExportPopup(); }} /> : null}

                        <DataGridPagination
                            rowData={rowData}
                            columnDefs={columnDefs}
                            onSelectionChanged={onCellClick}
                            gridref={gridRef}
                            rowSelection={"multiple"}
                            domLayout="autoHeight"
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Body
