import React, { useRef, useState } from 'react'
import Search from '../../../assets/Iconsearch.svg';

const Filter = ({ inventoryCount, className }) => {
    const ref = useRef(null);
    const [searchText, setSearchText] = useState();
    const [, setSearchOther] = useState();
    const [, setSelectionOption] = useState({});

    const optionSelect = (e, option) => {
        setSelectionOption(option.key, option.value);
    };

    const clearAll = () => {
        ref.current.reset();
        setSearchText('');
        setSearchOther('')
        setSelectionOption({});
    }
    return (
        <div className={`accordion-wrapper ${className}`}>
            <ul className="dropdown-list customer-filter-dialog">
                <li className='mt-3'></li>
                <li className="header">{inventoryCount[0]?.title}</li>
                <li className='mt-3 mb-3'>
                    <div className="form-group">
                        <div style={{ position: 'relative' }}>
                            <span className="icon"><img alt='' src={Search} /></span>
                        </div>
                        <input
                            type="text"
                            id="filter-text-box"
                            placeholder="Search"
                            className='form-control inputWithIcon'
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </li>
                <li>
                    <form ref={ref}>
                        {
                            Object.values(inventoryCount[0]?.label).map((option) => {
                                return (<>
                                    <p className="menuLink" key={option?.id}>
                                        <label className="form-check-label  checkboxLabel">
                                            <input type="radio" className="form-check-input" name={option.key} value={option?.menu} onChange={(e) => optionSelect(e, option)} />
                                            &nbsp; &nbsp;{option?.menu}</label>
                                    </p>
                                </>)
                            })
                        }
                    </form>
                </li>
                <li>
                    <label className='userLabel ms-2'>Other Inventory</label>
                </li>
                <li className='mt-1 mb-3'>

                    <div className="form-group">
                        <input
                            type="text"
                            id="filter-text-box"
                            placeholder="Enter Inventory Value"
                            className='form-control other-value'
                            value={searchText}
                            onChange={(e) => setSearchOther(e.target.value)}
                        />
                    </div>
                </li>
                <li className='d-flex space-between mt-2 mb-2 ml-2'>
                    <button type='button' className='btn btn-sm btns' onClick={clearAll}>Clear All</button>
                    <button type='button' className='btn btn-sm apply-btn'>Apply Filter</button>
                </li>
            </ul>
        </div>
    )
}

export default Filter