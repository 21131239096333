import React from 'react';
import PageLayout from '../../global/pageLayout';
import InTransitBody from './body';

const InTransit = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<InTransitBody />} selectedItem = "In transit" />
        </div>
    )
}

export default InTransit;