import React from 'react'

const Informations = () => {
    const rowData = [
        {
            label: 'Amount spent',
            data: '75,423.20'
        },
        {
            label: 'Orders',
            data: '5'
        },
        {
            label: 'COD Orders',
            data: '04'
        },
        {
            label: 'Prepaid Orders',
            data: '01'
        },
    ]
    return (
        <>
            <div className="card">
                <div className='card-body customer-info'>
                    <div className='row'>

                        {rowData && rowData.map((items) => {

                            return (<>
                                <div className='col-md-3 right-border'>
                                    <h5 className='info-header'>{items.label}</h5>
                                    <span className='info-content'>{items.data}</span>
                                </div>

                            </>)
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Informations
