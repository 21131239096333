/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import instance from '../../../helpers/axiosInstance';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import ChannelIntegration from '../../global/popupModal/channelIntegration';
import Dot from '../../../assets/dot.svg'
import RefreshIcon from '../../../assets/RefreshIcon.svg'
import InfoIcon from '../../../assets/InfoIcon.svg'
import CloseIcon from '../../../assets/CloseIcon.svg'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import SuccessToast from '../../customToast/SuccessToast';
import { Slide, toast } from "react-toastify";
import Loader from '../../global/loader';
const DivBox = ({ imageUrl, title }) => {

    const [id, setId] = useState("");
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const onOpenModal = () => { setOpen(true) };
    const onCloseModal = () => setOpen(false);
    const [startDate, setStartDate] = useState(moment().subtract(90, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate())

    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })

    const setPopup = (funData) => {
        setPopupData({ popupName: funData, data: "", callBackMethod: "" })
        console.log(funData);
    }
    const closePopup = () => {
        setPopupData({ popupName: "", data: "", callBackMethod: null })
    }

    const getStoreid = async () => {
        console.log("ogetStoreidbject");
        try {
            await instance.get(`app/get/tracking/setting`).then((res) => {
                if (res.status === 200 && res?.data?.statusCode === 200) {
                    console.log("getStoreId", res?.data?.data?.storeId);
                    let id = res?.data?.data?.storeId
                    setId(id)
                }

            })

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{ getStoreid()},[id])

    const handleCallback = async (startsDate, endsDate) => {
        
         console.log(id);

        let syncOrderData = {
            startDate: startsDate?.format('YYYY-MM-DD HH:mm:ss.S'),
            endDate: endsDate?.format('YYYY-MM-DD HH:mm:ss.S'),
            storeId: id.toString()
        };
       
        console.log("============");

        console.log(syncOrderData);
        setLoader(true);
        try {

            await instance.post(`app/order/syncOrderData`, syncOrderData).then((res) => {
                console.log("=========sync======",res?.data.message);
                if (res?.data?.status_code === "200" ) {             
                    toast(<SuccessToast body={res?.data.message} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }
                setLoader(false);
            })
        } catch (error) {
            console.log(error);
            setLoader(false);
        }
        setOpen(false);
    }
    return (
        <>
            <div className="chennalBox">
                <div className='box-status' >
                    {title === 'Add New channel' ? '' : <Badge className='blueLight'> <Link to={''} onClick={() => { /*setPopup('disconnect') */ }}><img src={Dot} />&nbsp; Connected</Link> </Badge>}

                </div>
                <div style={{ height: title === 'Shopify' ? '25px' : '55px' }}>&nbsp;</div>
                <div className="box-content" >


                    <div>
                        <img src={imageUrl} alt="Image" className='mb-2' />
                        <p className="title">{title}</p>
                    </div>


                    <div>

                        {title !== 'Add New channel' ? <Link to={''} ><img src={RefreshIcon} />&nbsp; <span style={{ fontSize: "12px", fontWeight: "bold", color: "#6941C6" }} onClick={onOpenModal}>Sync Order
                        </span></Link> : null}
                    </div>
                    <div>

                        <Modal
                            show={open} onHide={onCloseModal} centered
                            dialogClassName="modal-dialog-Custom"
                        >
                            <div >
                                <p style={{ background: "#101828", color: "#fff", fontSize: "12px", padding: "15px" }}><img src={InfoIcon} /> &nbsp;You can sync orders from the past 3 months only <span onClick={onCloseModal} style={{ float: "right" }}><img src={CloseIcon} /></span></p>

                            </div>
                            <div style={{ height: "330px", }} >

                                <DateRangePicker onCallback={handleCallback}
                                    initialSettings={
                                        {
                                            startDate: startDate,
                                            endDate: endDate,
                                            maxDate: moment().toDate(),
                                            minDate: moment().subtract(90, 'days').toDate(),
                                            showDropdowns: false,
                                            locale: {
                                                format: 'MMM DD, YYYY',
                                            },
                                            ranges: {
                                                Today: [moment().toDate(), moment().toDate()],
                                                Yesterday: [
                                                    moment().subtract(1, 'days').toDate(),
                                                    moment().subtract(1, 'days').toDate(),
                                                ],
                                                'Last 7 Days': [
                                                    moment().subtract(6, 'days').toDate(),
                                                    moment().toDate(),
                                                ],
                                                'Last 30 Days': [
                                                    moment().subtract(30, 'days').toDate(),
                                                    moment().toDate(),
                                                ],
                                                'This Month': [
                                                    moment().startOf('month').toDate(),
                                                    moment().endOf('month').toDate(),
                                                ],
                                                'Last Month': [
                                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                                ],
                                            },
                                        }}

                                >
                                    <input type="text" className="form-control datepicker-range" style={{ width: "630px", marginLeft: "3px", marginRight: "3px" }} />

                                </DateRangePicker>
                            </div>
                        </Modal>

                    </div>
                </div>


            </div>
            {
                popupData.popupName !== "" &&
                <ChannelIntegration
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closePopup}
                />
            }
        </>

    );
}

export default DivBox;
