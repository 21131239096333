import axios from "axios";
//import jwt_decode from "jwt-decode";
import { API_URL_LIVE, API_URL_STAGING } from './../constants';
let search = window.location.search;
let params = new URLSearchParams(search);
if (params.get("token")) {
  let awt_token = params.get("token");
  let name = params.get("name");
  localStorage.setItem("token", awt_token);
  localStorage.setItem("name", name);
  localStorage.setItem("isShow", "no");
}

localStorage.setItem('status', true)
let API_URL = ''
if (window.location.href.search("demo.hypership.in") > -1) {
 // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_STAGING;
}
else if (window.location.href.search("app.hypership.in") > -1) {
 // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_LIVE;
}
else {
 // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_STAGING;
}

const instance = axios.create({
  //baseURL: "http://localhost:5000/app",
  //baseURL: 'http://staging.deyga.in/app/',
  // baseURL: 'https://shipping.deyga.in/app/',
  // baseURL: 'http://ordermanager.us-east-2.elasticbeanstalk.com/app/',
  //http://15.207.88.15/
  // baseURL: 'http://15.207.88.15:8443/',
  baseURL: API_URL,
  // baseURL: 'http://staging.deyga.in/',
  headers: { "Content-Type": "application/json" },
});
// Alter defaults after instance has been created
//instance.baseURL = 'http://localhost:8090';

//instance.defaults.headers.common['Authorization'] = `Bearer ` + 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZXlnYS10ZXN0aW5nLXN0b3JlLm15c2hvcGlmeS5jb20iLCJleHAiOjE2NTMyMTE5MDAsImlhdCI6MTY1MzE5MzkwMH0.EXdXIlb0TqxhcPZThBUkBLqKQgGBFngG0b4u7MdWrwEfdaKUB6T4rPBtD6rPnyF7sD1-2P9VVTI_tINh32A0dw';
//console.log(localStorage.getItem("token"))
//instance.defaults.headers.common["Authorization"] =
// `Bearer ` + localStorage.getItem("token");


// const refreshToken = async () => {
//   const response = await instance.post('/refresh-token');
//   const newToken = response.data.token;
//   localStorage.setItem('token', newToken);
//   return newToken;
// };

// const REFERSH_TOKEN = async (refreshTokens) => {
//   let data = {
//     "refreshToken": refreshTokens
//   }
//   const response = await instance.post('/refreshtoken', data);
//   const newToken = response.data.accessToken;

//   const expirationTime = 1 * 60 * 1000; // 30 minutes in milliseconds
//   const tokenExpiration = new Date().getTime() + expirationTime;
//   localStorage.setItem("tokenExpiration", tokenExpiration);
//   localStorage.setItem('token', newToken);
//   localStorage.setItem('refreshToken', response.data.refreshToken);

//   return newToken;

// };

instance.interceptors.request.use(
  config => {
    // Get token from localStorage and set it in the request header
    const token = localStorage.getItem('token');


    if (token) {
      // const decodedToken = jwt_decode(token);
      // const tokenExpiration = localStorage.getItem("tokenExpiration");

      // const currentTimen = new Date().getTime();

      config.headers.Authorization = `Bearer ${token}`;

      // if (token && tokenExpiration && localStorage.getItem('status') === 'true') {

      //   const currentTime = new Date().getTime();

      //   console.log("Expiry TIme: ", currentTimen, tokenExpiration, decodedToken.exp)

      //   if (parseInt(currentTime) > parseInt(tokenExpiration)) {

      //     console.log("Expiry TIme: ", currentTimen, tokenExpiration, decodedToken.exp);
      //     const refreshToken = localStorage.getItem('refreshToken');
      //     const newToken = REFERSH_TOKEN(refreshToken);

      //     if (newToken) {
      //       console.log('New Token: ', newToken);
      //       config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      //     }
      //   } else {

      //     config.headers.Authorization = `Bearer ${token}`;
      //   }

      // } else {

      //   config.headers.Authorization = `Bearer ${token}`;

      // }

    }
    localStorage.setItem('status', false);
    // console.log(localStorage.getItem('status'));
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


// instance.interceptors.response.use(
//   (response) => response, async (error) => {

//     if (error.response !== undefined) {

//       if (error.response.data !== 'error') {

//         if (error.response.status === 401 && !originalRequest._retry) {
//           originalRequest._retry = true;

//           try {
//             // Refresh the token using your refresh token logic
//             const refreshToken = localStorage.getItem('refreshToken');
//             const newToken = await REFERSH_TOKEN(refreshToken);

//             // Update the Authorization header with the new token
//             originalRequest.headers.Authorization = `Bearer ${newToken}`;

//             // Retry the original request
//             return axiosInstance(originalRequest);
//           } catch (refreshError) {
//             // Handle refresh token error
//             console.log(refreshError);
//             localStorage.removeItem("token");
//             localStorage.removeItem("name");
//             localStorage.removeItem('isShow');
//             localStorage.clear();
//             window.location.href = "/login";
//           }
//           return Promise.reject(error);
//         }
//       }
//       else {
//         localStorage.clear();
//         window.location.href = "/login";
//       }

//     } else {
//       console.log('Error');
//       localStorage.clear();
//       window.location.href = "/login";
//     }
//   }

// );

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('response', response);
    localStorage.setItem('status', true)
    // console.log(localStorage.getItem('status'));
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log(error.response.status)
    //401 or 302 -> remove token
    if (error?.response?.status === 401 || error?.response?.status === 302) {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem('isShow');
      localStorage.clear();
      document.getElementById("logout-id").click();
      // window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default instance;
