import React from 'react';
import PageLayout from '../../global/pageLayout';
import ManifestBody from './body';

const Manifest = () => {
    return (
        <div>
            <PageLayout tabMenu={true} body={<ManifestBody />} selectedItem = "Manifest" />
        </div>
    )
}

export default Manifest;