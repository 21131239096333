import React, { useState, useEffect } from 'react'
import backArrow from '../../../../assets/backArrow.svg';
import { Link } from 'react-router-dom';
import DataGridPagination from '../../../global/dataGridPagination';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import actionButton from '../../../../assets/action-button.png';
import CreateWareHouse from '../../../global/popupModal/createWareHouse'
import { useSelector } from 'react-redux';
import instance from '../../../../helpers/axiosInstance';
import './style.scss';


const Body = () => {

    const userClaim = useSelector((state) => state.user);
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null, addOrEdit:"" })

    const showModal = (popupName, data, callBackMethod , addOrEdit) => {
        setPopupData({ popupName, data, callBackMethod , addOrEdit})
    }

    const closeModal = () => {
        setPopupData({ popupName: "", data: "", callBackMethod: null, addOrEdit: "" });
    }
    const [werehouseList, setWerehouseList] = useState([]);
    const [courierList, setCourierList] = useState([]);
  
    useEffect(() => {
        if (userClaim.token !== "") {
            getWerehouseList();
            getCourierList();
        }
    }, [userClaim]);

    // const rowData = [{
    //     id: '2342',
    //     warehouse: 'Chennai Warehouse',
    //     pincode: '123456',
    //     assignedUser: 'all',
    //     contact: 'Albert Flores, albert@domain.com+91 98765 43210',
    //     default: 'yes',

    // }]

    function toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    const columnDefs = [
        {
            width: 140,
            field: 'id',
            wrapText: true,
            autoHeight: true,
            headerName: 'Warehouse ID',
            cellClass: "check-box-container order-number",

        },
        {
            width: 200,
            field: 'name',
            wrapText: true,
            autoHeight: true,
            headerName: 'Warehouse',
            cellClass: "d-flex-center",
        },
        {
            width: 100,
            field: 'pincode',
            wrapText: true,
            autoHeight: true,
            headerName: 'Pincode',
            cellClass: "d-flex-center",
        },
        {
            width: 140,
            field: 'shipping_carrier_name',
            wrapText: true,
            autoHeight: true,
            headerName: 'Assigned Courier',
            cellClass: "d-flex-center",
            cellRenderer(param) {
                console.log(param);
                let courierName = param.value?.toString().replaceAll(",", ", ") || ''
                let courier = toTitleCase(courierName.replace(/_/g, ' '))
                return (

                    <>{courier}</>
                )
            },
            cellClass: 'custom-cell-line-height'

        },
        {
            width: 300,
            field: 'address1',
            wrapText: true,
            autoHeight: true,
            headerName: 'Contact Details',
            cellClass: "d-flex-center",
            cellClass: 'custom-cell-line-height'
        },
        {
            width: 100,
            field: 'is_default',
            wrapText: true,
            autoHeight: true,
            headerName: 'Default',
            cellClass: "d-flex-center",
            cellRenderer(param) {
                return (
                    <>{param.value === 'Y' ? 'Yes' : 'No'}</>
                )
            }
        },
        {
            width: 100,
            field: '',
            wrapText: true,
            autoHeight: true,
            headerName: 'Actions',
            cellClass: '',
            cellRenderer(param) {
                return (
                    <div className="more-action">
                      
                        <OverlayTrigger className="action-button" rootClose={true} trigger="click" placement="bottom" overlay={
                            <Popover id="popover-basic" className='z-index-2000 action-buttons'>
                                <Popover.Body>
                                    <button className='view-details d-block'  onClick={() => showModal('createWarehouse', param.data, "" , "edit" )}>Edit</button>
                                    {/* <Link className='view-details' to={``}>Edit</Link><br /> */}
                                    <button className='cancel-order' onClick={() => showModal('deleteLocation', param.data, "" , "delete" )}>Delete</button>
                                </Popover.Body>
                            </Popover>
                        }>
                            <img alt='Action' src={actionButton} />
                        </OverlayTrigger>

                    </div>
                )
            }
        },

    ]

    
    const getWerehouseList = () => {
        try {
            instance.get("app/location").then((res) => {

                if (res.status === 200 && Array.isArray(res.data?.data)) {
                    setWerehouseList(res.data?.data || []);
                }

            }).catch(e => {
                console.error(e);
                setWerehouseList([]);
            })
        }
        catch (e) {
            console.error(e);
            setWerehouseList([]);
        }
    }
    const getCourierList = async () => {
        try {
            instance.get("app/shipping_carriers/getshipping_carriers?per_page=25&page=1&search=").then((res) => {

                if (res?.data?.statusCode === 200) {
                   setCourierList(res?.data?.data)
                }

            }).catch(e => {
                console.error(e);
                setCourierList([])
            })
        }
        catch (e) {
            console.error(e);
            setWerehouseList([]);
        }
     
       
   }
    return (
        <>
            <div className="parent-div">
                <div className='container mt-3 mb-3' >
                    <div className='row'>
                        <div className='col-md-9'>
                            <Link to={'/settings'}>
                                <img src={backArrow} /> Back
                            </Link>

                        </div>
                        <div className='col-md-3 d-flex justify-content-end'>
                            <button className='btn btn-sm btn-primary' onClick={() => showModal('createWarehouse', 'createWarehouse', '', "add")}>
                                <i className="bi bi-plus"></i>
                                Create Warehouse
                            </button>
                        </div>
                    </div>
                    <div className='row mt-3 mb-3'>
                        <div className='col-md-12'>
                            <p className='page-header-text mb-3'>Manage Warehouse</p>

                            <DataGridPagination
                                rowData={werehouseList}
                                columnDefs={columnDefs}
                                rowSelection={"multiple"}
                                domLayout="autoHeight"
                            />

                        </div>
                    </div>
                </div>
            </div>
            {popupData.popupName !== "" &&
                <CreateWareHouse
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closeModal}
                    handleGetData={getWerehouseList}
                    courierData={courierList}
                    addOrEdit = {popupData.addOrEdit}
                />
            }
        </>
    )
}

export default Body
