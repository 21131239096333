import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Integration from '../../../assets/settingsIntegration.svg';
import WareHouse from '../../../assets/settingsWharehouse.svg';
import Label from '../../../assets/settingsLabel.svg';
import Staff from '../../../assets/settingsStaff.svg';
import Invoice from '../../../assets/settingsInvoice.svg';
import Billing from '../../../assets/settingsBill.svg';
import {SHOPIFY_PLANS_URL} from '../../../constants/index'
const SettingBody = () => {
    const [settingsItem, setSettingsItem] = useState([]);
    const settingsItems = [
        {
            "id": 1,
            "menu": 'Courier Integration',
            "url": "/settings/courier-integration",
            "label": "Integration",
            "module_id": "12",
            "groupBy": 1,
            "order": 1,
            "icon": Integration,
            "live":true
        },
        {
            "id": 2,
            "menu": 'Channel Integration',
            "url": "/settings/channel-integration",
            "label": "Integration",
            "module_id": "13",
            "groupBy": 1,
            "order": 2,
            "live":true
        },
        // {
        //     "id": 3,
        //     "menu": 'Exotel Integrations',
        //     "url": "/settings/exotel-ntegrations",
        //     "label": "Integration",
        //     "module_id": "0",
        //     "groupBy": 1,
        //     "order": 3
        // },
        // {
        //     "id": 3,
        //     "menu": 'Manage Serviceable Pincodes',
        //     "url": "/settings/manage-serviceable-pincodes",
        //     "label": "Carrier",
        //     "module_id": "0",
        //     "groupBy":2,
        //     "order": 1
        // },
        // {
        //     "id": 4,
        //     "menu": 'Return',
        //     "url": "/settings/",
        //     "label": "Return",
        //     "module_id": "0",
        //     "groupBy":3,
        //     "order": 1
        // },
        // {
        //     "id": 5,
        //     "menu": 'Manage Serviceable Pincodes',
        //     "url": "/settings/",
        //     "label": "Return",
        //     "module_id": "0",
        //     "groupBy":3,
        //     "order": 1
        // },
        // {
        //     "id": 6,
        //     "menu": 'Upload Tracking Number',
        //     "url": "/settings/",
        //     "label": "Return",
        //     "module_id": "0",
        //     "groupBy":3,
        //     "order": 1
        // },
        // {
        //     "id": 7,
        //     "menu": 'Auto Assign Rule',
        //     "url": "/settings/",
        //     "label": "Return",
        //     "module_id": "0",
        //     "groupBy":3,
        //     "order": 1
        // },
        {
            "id": 8,
            "menu": 'Warehouse Address',
            "url": "/settings/warehouse/address",
            "label": "Manage Warehouse",
            "module_id": "0",
            "groupBy": 4,
            "order": 1,
            "icon": WareHouse,
            "live":true
        },
        // {
        //     "id": 10,
        //     "menu": 'HSN Code and GST %',
        //     "url": "/settings/invoice/hsn-gst",
        //     "label": "Invoice",
        //     "module_id": "0",
        //     "groupBy": 5,
        //     "order": 2
        // },
        {
            "id": 11,
            "menu": 'Manage Invoice Template',
            "url": "/settings/invoice-template",
            "label": "Label, Invoice",
            "module_id": "0",
            "groupBy": 6,
            "order": 2,
            "icon": Label,
        },
        {
            "id": 12,
            "menu": 'Manage Labels',
            "url": "/settings/label-template",
            "label": "Label, Invoice",
            "module_id": "0",
            "groupBy": 6,
            "order": 1,
            
        },
        // {
        //     "id": 11,
        //     "menu": 'Manage Invoice Template',
        //     "url": "/settings/",
        //     "label": "Label, Invoice",
        //     "module_id": "0",
        //     "groupBy": 6,
        //     "order": 2,
        //     "icon": Label,
        //     "live":false
        // },
        // {
        //     "id": 12,
        //     "menu": 'Manage Labels',
        //     "url": "/settings/",
        //     "label": "Label, Invoice",
        //     "module_id": "0",
        //     "groupBy": 6,
        //     "order": 1,
        //     "live":false
        // },
        {
            "id": 13,
            "menu": 'User Management',
            "url": "/settings/staff-managemanet",
            "label": "Staff Management",
            "module_id": "15",
            "groupBy": 1,
            "groupBy": 7,
            "order": 2,
            "icon": Staff,
            "live":true
        },
        // {
        //     "id": 14,
        //     "menu": 'Billing',
        //     "url": "/settings/billing",
        //     "label": "Billing",
        //     "module_id": "14",
        //     "groupBy": 8,
        //     "order": 1,
        //     "icon": Billing,
        //     "live":false
        // },
        {
            "id": 14,
            "menu": 'Manage Plan',
            "url": SHOPIFY_PLANS_URL,
            "label": "Billing",
            "module_id": "14",
            "groupBy": 8,
            "order": 2,
            "icon": Billing,
            "live":true
        },
        {
            "id": 14,
            "menu": 'Store Details',
            "url": "/settings/store-details",
            "label": "Store Details",
            "module_id": "0",
            "groupBy": 9,
            "order": 1,
            "icon": Invoice,
            "live":true
        }
    ]

    useEffect(() => {
        let settings = [...settingsItems];
        if(window.location.href.search("app.hypership.in") > -1){
            // settings = settings.filter((item) => {
            //     return item.live
            // });
        }
        const permission = localStorage.userPermission?.split(",") || [];
        if (permission.length > 0) {
            settings = settings.filter((item) => {
                return permission.includes(item.module_id?.toString())
            });
        }
        setSettingsItem(_.groupBy(settings, "groupBy"));
    }, []);

    return (
        <>
            <div className="parent-div">
                <div className='container mt-3 mb-3 vh-93-8' >
                    <div className='row'>
                        <div className='col-md-9'>
                            <h4>Settings</h4>
                        </div>
                        <div className='col-md-3 d-flex justify-content-end'>

                            {/* <div className="form-group">
                                <div style={{ position: 'relative' }}>
                                    <span className="icon">
                                        <i className='bi bi-search' />
                                    </span>
                                </div>

                                <input type="text" className={`form-control inputWithIcon `} name='username' id="username" placeholder="Search" />


                            </div> */}

                        </div>
                    </div>

                    <div className='row mt-4'>
                        {Object.keys(settingsItem).map((value, index) => (
                            <div className='col-md-3' key={index}>
                                <div className='card' style={{
                                    height: '200px',
                                    width: '269px',
                                }}>
                                    <div className='card-header'>

                                        <div className='displayFlex'>
                                            <span className='card-icons'>
                                                <img src={settingsItem[value][0]?.icon} alt={settingsItem[value][0]?.icon} />
                                            </span>
                                            <h5 className='setting-card-title'>{settingsItem[value][0]?.label}</h5>
                                        </div>
                                    </div>
                                    <div className='card-body' style={{ overflowY: 'auto' }}>
                                        <ul className='card-link'>
                                            {settingsItem[value]?.map((item, index) => (
                                                <li key={index}>
                                                    <Link to={item.url}>{item.menu} </Link>
                                                </li>
                                            ))}


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingBody;