import React, { useEffect, useState } from "react";
import Loader from "../../global/loader";
import { useSelector } from 'react-redux';
import SuccessToast from "../../customToast/SuccessToast";
import { Slide, toast } from "react-toastify";
import { commonServiceMethod } from "../../../helpers/helper";
import { Link } from "react-router-dom";
import backArrow from '../../../assets/backArrow.svg';

const ExotelIntegrationBody = () => {
  const userClaim = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  useEffect(() =>{
    console.log(userClaim)
  },[])
  const toggleSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
    submitEvent();
  };

  const submitEvent = async () => {
    return false;
    let data = { ...formData };
    const config = {};
   await commonServiceMethod(`app/update/setting`, "put", data, config, setLoader, (res) => {
      console.log(res?.data)
      if (res?.data?.statusCode === 200) {
        toast(<SuccessToast body={'Storage Details updated'} />, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 2000,
        });
      }
      else {

      }
    });

  }

  return (
    <>
      <Loader showHide={loader} />
      <div className='container mt-3 mb-3' >
        <div className='row'>
          <div className='col-md-9'>
            <Link to={'/settings'}>
              <img src={backArrow} /> Back
            </Link>

          </div>
          <div className='col-md-3 d-flex justify-content-end'>

          </div>
        </div>
        <div className='row mt-3 mb-3'>
          <div className='col-md-8'>
            <span className='page-header-text mb-2'>Exotel Integrations</span>
            <p className='details'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p>

            <h5 className='courier-head mt-3'>Enable/Disable Column for Call</h5>
            <div className='card'>
              <div className='card-body' style={{ minHeight: '100%' }}>
                <div className='row'>
                  <div className='col-md-11 d-flex'>
                    <h5 className='courier-card-head'>Exotel Column</h5>
                  </div>

                  <div className='col-md-1 no-padding'>
                    <label className="switch" >
                      <input type="checkbox" onChange={toggleSwitch} checked={isSwitchOn} />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default ExotelIntegrationBody
