import React from 'react';
import PageLayout from '../../global/pageLayout';
import InvoiceTemplateBody from './body'

function index() {
    return (
        <>
            <PageLayout tabMenu={false} body={<InvoiceTemplateBody />} />
        </>
    )
}

export default index
