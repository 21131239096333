import {configureStore} from '@reduxjs/toolkit';

import initialState from './initialState';
import user from './users';
const store = configureStore({
  reducer: {
    user
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState: initialState,
});

export default store;
