import React, { useEffect } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.min.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import RootApp from './routes';
import './styles/common.scss';
import { Provider } from "react-redux";
import store from "./store";
import instance from './helpers/axiosInstance';


const App = () => {
  useEffect(() => {
    if (localStorage.getItem('token') !== "" && localStorage.getItem('token') !== null) {
      getPermissions()
    }

  }, []);
  const getPermissions = async () => {
    try {
      instance.get("app/getPermission").then((res) => {

        if (res.status === 200) {
          let permissions = [...res.data] || [];
          try {
            permissions.push("0");
          }
          catch (e) {
            permissions = [...permissions]
          }
          localStorage.setItem("userPermission", permissions || [])
        }
        else {
          localStorage.setItem("userPermission", [])
        }

      }).catch(e => {
        console.error(e);
      })
    }
    catch (e) {
      console.error(e);
    }

  }


  return (
    <Provider store={store}>
      <ToastContainer />
      <RootApp />
    </Provider>
  );
}

export default App;
