import React from "react";
import { Link } from "react-router-dom";
import HeaderLogo from '../../assets/logo/logo.svg';
// import MainLogo from '../../assets/login/mainLogo.svg'
const AllDone = () => {

    return (
        <>
            <div className='container-fluid'>
                <div className='row vh-100'>
                    <div className='col-md-6 loginLeftside  mt-3'>
                        <img alt="logo" className="width-" src={HeaderLogo} />
                        <div className="all-done-page">
                            <div className='container'>
                                <div className="text-center">
                                    {/* <img alt="logo" className="mb-2" src={MainLogo} /> */}
                                    <h1>All Done!</h1>
                                    <p>Your password has been rest. You can use your new password to login.</p>
                                </div>
                                <div className='mt-5'>
                                    <Link to={'/login'} className=' btn btn-primary w-100 mb-5'> Login Now</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-6 loginRightside'>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllDone;