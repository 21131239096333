import React, { useState, useEffect, useMemo } from 'react';
import './orderDetails.scss';
import { Link } from 'react-router-dom';
import ProductDetailUpdate from '../../../global/popupModal/productModal'
import ProductIcon from '../../../../assets/productIcon.svg'

const ProductDetails = ({ productDetail }) => {
    const [productDetailData, setProductDetail] = useState(null);
    const containerStyle = useMemo(() => ({ width: '100%', height: 'auto' }), []);
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null });

    const showModal = (popupName, data, callBackMethod) => {
        setPopupData({ popupName, data, callBackMethod });
        console.log(popupName, data, callBackMethod);
    }
    const closeModal = () => {
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }

    useEffect(() => {
        setProductDetail(productDetail || [])
        console.log(productDetail);
    }, [productDetail])

    return (
        <>
            <div className="card">
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-md-11 d-flex'>
                            <span className='card-icons'>
                                <img src={ProductIcon} alt='ProductIcon' />
                            </span>
                            <h5 className='orderDetailTitle'>Product Details</h5>
                        </div>

                        <div className='col-md-1 no-padding'>
                            <Link to={''} onClick={() => showModal('productEdit', productDetailData, '')}><i className="bi bi-pencil-square" style={{ fontSize: '20px' }} ></i></Link>

                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div style={containerStyle}>
                        {productDetailData?.map((item) => {
                            return (<>
                                <div className='row' key={item?.id}>
                                    <div className='col-md-4'>
                                        <label className='userLabel'>Weight (in kg)</label>
                                        <p>{item?.weight}</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='userLabel'>GST</label>
                                        <p>{item?.weight}</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='userLabel'>HSN</label>
                                        <p>{item?.weight}</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='userLabel'>Barcode</label>
                                        <p>{item?.barcode}</p>
                                    </div>
                                </div>

                            </>)
                        })}

                    </div>
                </div>

            </div>

            {popupData.popupName !== "" &&
                <ProductDetailUpdate
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closeModal}
                />
            }
        </>
    )
}

export default ProductDetails