import React, { useState, useEffect } from 'react';
import confirmed from '../../../assets/confirmed.svg';
import delivery from '../../../assets/delivery.svg';
import outForDelivery from '../../../assets/outForDelivery.svg';
import onItsWay from '../../../assets/onItsWay.svg';

import confirmed_active from '../../../assets/confirmed-active.svg';
import delivery_active from '../../../assets/delivery-active.svg';
import outForDelivery_active from '../../../assets/outForDelivery-active.svg';
import onItsWay_active from '../../../assets/onItsWay-active.svg';

import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import moment from 'moment';

const TrackingDetailsPage = ({ data }) => {
    const shippingAddress = JSON.parse(data.shippingAddress ||  '{}');
    const [viewport] = useState({
        latitude: shippingAddress?.latitude,
        longitude: shippingAddress?.longitude,
        zoom: 30
    });
    const [statusData, setStatusData] = useState([
        {
            status: "Confirmed",
            text: "Confirmed",
            img: confirmed,
            activeImg:confirmed_active,
            active: false,
            subData: null
        },
        {
            status: "In Transit",
            text: "In Transit",
            img: onItsWay,
            activeImg:onItsWay_active,
            active: false,
            subData: null
        },
        {
            status: "Out for delivery",
            text: "Out for delivery",
            img: outForDelivery,
            activeImg:outForDelivery_active,
            active: false,
            subData: null
        },
        {
            status: "Delivered",
            text: "Delivered",
            img: delivery,
            activeImg:delivery_active,
            active: false,
            subData: null
        }]);

    useEffect(() => {
        const statusIndex =  statusData.findIndex((a) => a.status === data.status);

        const statusArray = statusData.map((item, index) => {
            if (statusIndex >= index) {
                item.active = true;
                if (index === 0)
                    item.subData = moment(data.orderDate).format("DD MMM YYYY");
                if (statusIndex === 1 && index === 1)
                    item.subData = data.location;
                if (3 === statusIndex && index === 3)
                    item.subData = moment(data.statusDate).format("DD MMM YYYY");;
            }
            return item;
        });
        console.log("statusArray", statusArray);
        setStatusData([...statusArray]);
    }, []);

    // const [selectWay, setSelectWay] = useState("ONUM");
    // const [trackForm, setTrackForm] = useState({ OrderNo: "", emailPhone: "", awbNo: "" });


    const token = `pk.eyJ1IjoicGF2aXRocmFucWQiLCJhIjoiY2x0ZTFkd3NrMGJwdjJpcXVhcThxcGcyaSJ9.k4wE94K6KlGFGnejMWCuEA`;
    return (
        <>
            <section className='order-or-tracking-detail-container'>
                <h1 className='title'>Track Your Order</h1>
                <div className='d-flex'>

                    <div className='container-left-item'>
                        <h2 className='title'>Order Details</h2>
                        <p className='content'>Order No  <span className='value'>{data.orderNumber}</span></p>
                        <p className='content'>Order Placed On <span className='value'>{data.orderDate ? moment(data.orderDate).format("DD MMM YYYY") : data.orderDate}</span></p>
                    </div>
                    <div className='container-right-item'>
                        {data.status !== "Cancel" ? 
                        <><div className='container-1'>
                            {
                                statusData.map((item, key) => {
                                    return <><div key={key} className={`status ${item.status} ${item.active && 'active'}`}><img src={!item.active ? item.img : item.activeImg} /><span className='status-text'>{item.text}</span> {item.subData !== null && <span className='status-date'>{item.subData}</span>}</div><div className='link-line'></div></>
                                })
                            }

                            {/* <div className='status onItsWay'><img src={onItsWay} /> <span className='status-text'>On its way</span> <span>1 Dec 2023</span></div>
                            <div className='status outForDelivery'><img src={outForDelivery} /> <span className='status-text'>Out for Delivery</span></div>
                            <div className='status delivery'><img src={delivery} /> <span className='status-text'>Delivered</span></div> */}
                        </div>
                        <div className='container-2'>
                            {/* <p className='head'>Order is under processing</p>
                            <p className='status-content'>We will update the Estimated Delivery Date once order is processed</p> */}
                            <p className='head'>Status</p>
                            <p className={`status ${data.status === "Delivered" && 'success'}`}>{data.status}</p>
                            <div>
                                <Map
                                    mapboxAccessToken={token}
                                    initialViewState={{
                                        longitude: viewport.longitude,
                                        latitude: viewport.latitude,
                                        zoom: 10
                                    }}
                                    // zoom={5}
                                    mapStyle="mapbox://styles/mapbox/streets-v11"
                                >
                                    <Marker
                                        longitude={viewport.longitude}
                                        latitude={viewport.latitude}
                                    />
                                </Map>
                            </div>
                            <div className='today-value'><span className='today-date'>{moment().format("DD MMM")}</span><br/> <span className='today-time'>{moment().format("hh:mm A")}</span></div>
                        </div>
                        </> 
                        :
                        <div className='container-1'>
                            <p className='canceled'>Canceled</p>
                            <div><span className='today-date'>{moment().format("DD MMM")}</span><br/> <span className='today-time'>{moment().format("hh:mm A")}</span></div>
                        </div>}
                        <div></div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default TrackingDetailsPage
