
/* eslint-disable */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import initialState from './initialState';

import { API_URL_LIVE, API_URL_STAGING } from './../constants';
import instance from '../helpers/axiosInstance';

let API_URL = ''
if (window.location.href.search("demo.hypership.in") > -1) {
 // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_STAGING;
}
else if (window.location.href.search("app.hypership.in") > -1) {
 // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_LIVE;
}
else {
 // API_URL = 'http://15.207.88.15:8080/';
  API_URL = API_URL_STAGING;
}

const loginCheck = createAsyncThunk(
  'user/loginCheck',
  async (dispatch, ThunkAPI) => {
    let response = await instance.post(`${API_URL}authenticate`, dispatch);
    return response;
  },
);
const logoutCheck = createAsyncThunk(
  'user/logoutCheck',
  async (dispatch, ThunkAPI) => {
    let response = await instance.post(`${API_URL}app/logout`, dispatch);
    return response;
  },
);

const getPermission = createAsyncThunk(
  'user/getPermission',
  async (dispatch, ThunkAPI) => {
    let response = await instance.get(`${API_URL}app/getPermission`);
    return response;
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: initialState.user,
  reducers: {
    setloader(state, action) {
      state.loader = action?.payload;
    },
    updateUserData(state, action) {
      state.loader = action?.payload.loader;
      state.data = action?.payload.data
      state.token = action?.payload.token
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginCheck.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(loginCheck.fulfilled, (state, action) => {
      state.loader = false;
      state.user = action?.payload?.data || [];
    })
    builder.addCase(loginCheck.rejected, (state, action) => {
      state.loader = false;
      state.user = [];
    })
    builder.addCase(logoutCheck.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(logoutCheck.fulfilled, (state, action) => {
      state.loader = false;
      state.user = action?.payload?.data || [];
    })
    builder.addCase(logoutCheck.rejected, (state, action) => {
      state.loader = false;
      state.user = [];
    })
    builder.addCase(getPermission.pending, (state, action) => {
      state.loader = true;
    })
    builder.addCase(getPermission.fulfilled, (state, action) => {
      state.loader = false;
      state.permission = action?.payload?.data || [];
    })
    builder.addCase(getPermission.rejected, (state, action) => {
      state.loader = false;
      state.permission = [];
    })
  },
});

export const userThunks = {
  loginCheck,
  logoutCheck,
  getPermission
};

export const userActions = userSlice.actions;
export default userSlice.reducer;
