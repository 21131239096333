import React, { useEffect, useState } from 'react'
import backArrow from '../../../assets/backArrow.svg';
import { Link } from 'react-router-dom';
import Template from '../../../assets/template1.png';
import { commonServiceMethod } from '../../../helpers/helper';
import Loader from '../../global/loader';
import SuccessToast from '../../customToast/SuccessToast'
import { Slide, toast } from 'react-toastify';
import instance from '../../../helpers/axiosInstance';
const Body = () => {

    const [selectedTemplate, setSelectedTemplate] = useState('GST');
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    
    useEffect(() => {
        getTemplate()
    }, [selectedTemplate])

    const getTemplate = async () => {
           await commonServiceMethod(`app/get/invoicetemplate`, 'get', {}, {}, setLoader,
            (res) => {
                if (res?.data?.statusCode === 200) {
                    let data = res?.data?.data;
                    let templateName=data.templateName;
                    setData(data)
                    setSelectedTemplate(templateName)

                }
            })

    }
  

    const handleTemplateChange = async (event) => {
        setLoader(true);
        let id = data.id;
        let templateName = event.target.value;
       
        try {
            let res = await instance.post(`app/update/invoicetemplate?id=${id}&templateName=${templateName}`)
            if (res?.data?.statusCode === 200) {
                    toast(<SuccessToast body={`Template updated successfully` } />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                setSelectedTemplate(templateName);
            }
            setLoader(false);

        } catch (error) {
            console.log(error)
            setLoader(false);
        }

    }

        return (
            <>
                <Loader showHide={loader} />
                <div className="parent-div">
                    <div className='container mt-3 mb-3' >
                        <div className='row'>
                            <div className='col-md-9'>
                                <Link to={'/settings'}>
                                    <img src={backArrow} /> Back
                                </Link>

                            </div>
                            <div className='col-md-3 d-flex justify-content-end'>

                            </div>
                        </div>
                        <div className='row mt-3 mb-3'>
                            <div className='col-md-12'>
                                <div className='row  mb-1'>
                                    <div className='col-md-11'>
                                        <p className='page-header-text' style={{ marginTop: '5px', marginBottom: '5px' }}>Manage Invoices</p>
                                        <p className='details' style={{ marginBottom: '5px' }}></p>

                                    </div>

                                </div>
                                <hr></hr>

                                <div className='row  mb-1'>
                                    <div className='col-md-11'>
                                        <p className='page-header-text' style={{ marginTop: '5px', marginBottom: '5px', fontSize: '15px' }}>With GST</p>


                                    </div>
                                    <div className='col-md-4'>
                                        <div className="card rounded-1" style={{ borderColor:selectedTemplate === 'GST'? "#7F56D9":"#85929E", width: "200px" }}>
                                            <img src={Template} className="card-img-top " />

                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" style={{ borderColor: "#7F56D9", color: "#7F56D9", backgroundColor: "#7F56D9" }}
                                                onClick={handleTemplateChange}
                                                checked={selectedTemplate === 'GST'}
                                                value={'"GST"'}
                                            />
                                            <label className="form-check-label"  >
                                                Template 1 
                                            </label>
                                        </div>

                                    </div>

                                </div>
                                <div className='row  mb-1'>
                                    <div className='col-md-11'>
                                        <p className='page-header-text' style={{ marginTop: '5px', marginBottom: '5px', fontSize: '15px' }}>Without GST</p>
                                        <p className='details' style={{ marginBottom: '5px' }}></p>

                                    </div>
                                    <div className='col-md-4'>
                                        <div className="card rounded-1"
                                            style={{ borderColor: selectedTemplate === 'NOGST'? "#7F56D9":"#85929E", width: "200px" }} >
                                            <img src={Template} className="card-img-top" />

                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" style={{ borderColor:selectedTemplate === 'NOGST'? "#7F56D9":"#85929E", color: "#7F56D9", backgroundColor: "#7F56D9" }}
                                                onClick={handleTemplateChange}
                                                checked={selectedTemplate === 'NOGST'}
                                                value={'"NOGST"'}
                                            />
                                            <label className="form-check-label" >
                                                Template 2 
                                            </label>
                                        </div>

                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    export default Body
