import React, { useEffect, useState } from "react";
// import Drawer from '@mui/material/Drawer';
import HomelineIcon from './../../../assets/sidebar/home-line.png';
import HomelineActiveIcon from './../../../assets/sidebar/home-line-active.png';
import ShoppingCardIcon from './../../../assets/sidebar/logo.png';
import "./Sidebar.scss";
import { Link, useNavigate } from 'react-router-dom';
import SuccessToast from "../../customToast/SuccessToast";
import { SideBarMenu, AllOrderTabDetails } from "../../../constants";
import LogoutIcon from '../../../assets/logoutIcon.svg';
import LogOut from '../../../assets/logout.svg';
import Dashboard from '../../../assets/A-Dashboard.png';
import OrdersIcon from '../../../assets/A-Orders.png';
import Tracking from '../../../assets/A-Tracking.png';
import Returns from '../../../assets/A-Return.png';
import Settings from '../../../assets/A-Settings.png';
import Customer from '../../../assets/A-Customers.png';
import Products from '../../../assets/A-Products.png';

const Sidebar = () => {

    const navigate = useNavigate();
    const [sideMenuData, setSideMenuData] = useState([]);
    // const drawerWidth = 280;
    const [sideMenuItem, setSideMenuItem] = useState([...SideBarMenu]);
    const [username, setUsername] = useState('');
    const [logoutShow, setLogoutShow] = useState(false);
    useEffect(() => {
        const permission = localStorage.userPermission?.split(",") || [];
        if (sideMenuItem.length > 0) {
            let finalData = sideMenuItem.filter((item) => {
                return permission.includes(item.moduleId.toString())
            });
            if (AllOrderTabDetails.length > 0) {
                let filterData = AllOrderTabDetails.filter((item) => {
                    return permission.includes(item.moduleId.toString())
                });
                filterData = _.orderBy(filterData, ["order"], ['asc']);
                if (filterData.length > 0) {
                    finalData.push({
                        "id": 2,
                        "menu": 'Orders',
                        "moduleId": 0,
                        "url": filterData[0]?.url,
                        "order": 2,
                        "label": "ORDERS",
                    });
                    // finalData = finalData.map(i => {
                    //     if (i.id === 2)
                    //         i.url = filterData[0].url;
                    //     return i;
                    // })
                }
                else {

                }

            }
            finalData = _.orderBy(finalData, ["order"], ['asc']);
            setSideMenuItem(finalData);
            setSideMenuData(_.groupBy(finalData, "label"));
        }

        var usernames = String(localStorage.getItem('username')).split('@');
        setUsername(usernames[0]);

    }, []);

    const Logout = () => {
        localStorage.clear();


        toast(<SuccessToast body={'Successfully logged out'} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
        });

        setTimeout(() => {
            navigate('/')
        }, 3000);
    }

    const getMenuIcon = (menuName) => {
        // Define your mapping of menu names to image sources here
        // import Tracking from '../../../assets/tracking.svg';
        // import Returns from '../../../assets/returns.svg';
        // import Settings from '../../../assets/settings.svg';
        const menuIcons = {
            'dashboard': Dashboard,
            'orders': OrdersIcon,
            'tracking': Tracking,
            'returns': Returns,
            'settings': Settings,
            'customers': Customer,
            'products': Products
        };

        return menuIcons[menuName.toLowerCase()] || HomelineIcon;
    };

    const getActiveIcon = (menuName) => {

        const activeMenuIcons = {
            'dashboard': Dashboard,
            'orders': OrdersIcon,
            'tracking': Tracking,
            'returns': Returns,
            'settings': Settings,
            'customers': Customer,
            'products': Products
        };
        return activeMenuIcons[menuName.toLowerCase()] || HomelineActiveIcon;
    };

    return (
        <>

            <div className="side-container">
                <div className="side-inner-container p-20">
                    <div className="d-flex">
                        <img src={ShoppingCardIcon} className="shopping-card"></img>
                        {/* <h4 className="orderlytics">Orderlytics</h4> */}
                    </div>
                    {
                        Object.keys(sideMenuData).map((key, index) =>
                            <div key={index} className="menulist">
                                <p className="menu-label">{sideMenuData[key][0]?.label || ''}</p>
                                {sideMenuData[key].map((item, index) => (
                                    // <Link key={index} className={`menuItem-parent ${location.pathname.includes(`/${item.menu.toLowerCase()}`) && 'active'}`} to={item.url}>
                                    //     <img src={location.pathname.includes(`/${item.menu.toLowerCase()}/`) ? HomelineActiveIcon : HomelineIcon} className="menu-logo"></img>
                                    //     <span className={`menu-item ${location.pathname.includes(`/${item.menu.toLowerCase()}`) && 'active'}`}>{item.menu}</span>
                                    // </Link>
                                    <Link key={index} className={`menuItem-parent ${location.pathname.includes(`/${item.menu.toLowerCase()}`) && 'active'}`} to={item.url}>
                                        <img src={location.pathname.includes(`/${item.menu.toLowerCase()}/`) ? getActiveIcon(item.menu) : getMenuIcon(item.menu.toLowerCase())} className="menu-logo" alt={item.menu.toLowerCase()}></img>
                                        <span className={`menu-item ${location.pathname.includes(`/${item.menu.toLowerCase()}`) && 'active'}`}>{item.menu}</span>
                                    </Link>
                                ))}
                            </div>

                        )}
                    
                </div>
                <div className={`profile-container-logout ${!logoutShow && 'd-none'}`} >
                        <Link to={''} className="menu-item" id="logout-id" onClick={Logout}>
                            <img src={LogOut} /> Log out
                        </Link>
                    </div>
                    <div className="profile-container" onClick={() => setLogoutShow(!logoutShow)}>
                        <div className="d-flex">
                            <div className="avathar">
                                <span className="user-name avathar-text">{username[0]}</span>
                            </div>
                            <div style={{ marginLeft: '10px', width: '140px' }}>
                                <p className="menu-username">{username}</p>
                                <span className="menu-username-role">Adminstrator</span>
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <img src={LogoutIcon} />
                            </div>
                        </div>
                    </div>

            </div>

        </>
    )
}

export default Sidebar;