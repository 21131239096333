import React from 'react';
import PageLayout from '../../../global/pageLayout'
import AddressBody from './body'

const index = () => {
    return (
        <>
            <PageLayout tabMenu={false} body={<AddressBody />} />
        </>
    )
}

export default index
