import React from 'react';
import { Link } from 'react-router-dom';
import backArrow from '../../../assets/backArrow.svg';
import Shopfiy from '../../../assets/shopfy.svg';
import DivBox from './data';
// import AddNew from '../../../assets/addChannel.svg'

const body = () => {

    const chennalData = [
        {
            imageUrl: Shopfiy,
            title: 'Shopify',
        },
        // {
        //     imageUrl: AddNew,
        //     title: 'Add New channel',
        // },
        // Add more data items...
    ];
    return (
        <>
        <div className="parent-div">
            <div className='container mt-3 mb-3' >
                <div className='row'>
                    <div className='col-md-9'>
                        <Link to={'/settings'}>
                            <img src={backArrow} /> Back
                        </Link>

                    </div>
                    <div className='col-md-3 d-flex justify-content-end'>

                    </div>
                </div>
                <div className='row mt-3 mb-3'>
                    <div className='col-md-8'>
                        <span className='page-header-text'>Channel Integration</span>
                        <div className='mb-3'></div>
                        <div className='row'>
                            {/* <div className='chennalBox'>
                                <div className="box-content">
                                    <img src={Shopfiy} alt="Centered Image" className="centered-image" />
                                    <p className='title'>Shopify</p>
                                </div>
                            </div>

                            <div className='chennalBox'>
                                <div className="box-content">
                                    <img src={Shopfiy} alt="Centered Image" className="centered-image" />
                                    <p className='title'>Shopify</p>
                                </div>
                            </div> */}
                            {chennalData.map((item, index) => (
                                <DivBox key={index} imageUrl={item.imageUrl} title={item.title} />
                            ))}

                        </div>
                    </div>

                </div>


            </div>
            </div>
        </>
    )
}

export default body
