import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
    const tokens = localStorage.getItem('token');
    var auth = {};

    if (tokens) {
        auth = { 'isToken': true }
    }
    return (

        auth.isToken === true ? <Outlet /> : <Navigate to={'/'} />
    )
}

export default PrivateRoute