import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import backArrow from '../../../assets/backArrow.svg';
import './staff.scss';
import RightArrow from '../../../assets/rightArrow.svg'
import instance from '../../../helpers/axiosInstance';
import { useSelector } from "react-redux";
import _ from 'lodash';


const Body = () => {
    const userClaim = useSelector((state) => state.user);
    const [usrRoles, setUserRoles] = useState([]);
    const [userList, setUserList] = useState({});
    const [userListOriginal, setUserListOriginal] = useState({});

    useEffect(() => {
        console.log(userClaim)
        if (userClaim.token !== "") {
            getUserRoleLsit();
        }
    }, [userClaim]);

    const getUserRoleLsit = () => {
        try {
            instance.get("app/getRoleList").then((res) => {

                if (res.status === 200 && Array.isArray(res.data)) {
                    setUserRoles(res.data);
                    getUserList(res.data);
                }

            }).catch(e => {
                console.error(e)
            })
        }
        catch (e) {
            console.error(e);
        }
    }
    const getUserList = () => {
        try {
            instance.get("app/getUserList").then((res) => {
                if (res.status === 200 && Array.isArray(res.data)) {
                    setUserListOriginal(res.data);
                    setUserList(_.groupBy(res.data, "role_id"));
                }
            }).catch(e => {
                console.error(e)
            })
        }
        catch (e) {
            console.error(e);
        }
    }
    const setUSer = (data) =>{
        sessionStorage.setItem("userDetail", JSON.stringify(data))
    }
    return (
        <>
            <div className="parent-div">
            <div className='container mt-3 mb-3' >
                <div className='row'>
                    <div className='col-md-9'>
                        <Link to={'/settings'}>
                            <img src={backArrow} /> Back
                        </Link>

                    </div>
                    <div className='col-md-3 d-flex justify-content-end'>
                        <Link className='btn btn-primary' to={'/settings/staff-managemanet/create-staff'}><i className="bi bi-plus"></i> Create Staff</Link>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-9'>
                        <span className='page-header-text mb-2'>Users</span>
                        {/* <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p> */}
                    </div>

                </div>

                {
                    usrRoles?.map((role, roleIndex) => <React.Fragment key={roleIndex}>
                        <div className='col-md-9 mb-3' >
                            <div className='card'>
                                <div className='card-header'>

                                    <div className='displayFlex'>
                                        <span className='card-icons'></span>
                                        <h5 className='title'>{role.name}</h5>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <ul className='userList'>
                                        {
                                            userList[role.id]?.map((user, userIndex) => <React.Fragment key={userIndex}>
                                                <li>
                                                    <Link to={`/settings/staff-managemanet/view-staff/${user.id}`} onClick={()=>setUSer(user)} state={{"userDetail":user,"userList":userListOriginal}}>
                                                        <div className='row user'>
                                                            <div className='col-md-1'>
                                                                <div className='avathar'>
                                                                    <span className='avathar-text'>{`${user.first_name[0]}${user.last_name[0]}`}</span>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-10 no-padding'>
                                                                <h4 className='user-title'>{`${user.first_name} ${user.last_name}`}</h4>
                                                                {/* <p className='details'>Last login was Monday, Feb 27, 2023 12:11 PM</p> */}
                                                            </div>

                                                            <div className='col-md-1 no-padding'>
                                                                <img src={RightArrow} className='arrow' />
                                                            </div>
                                                        </div>


                                                    </Link>
                                                </li>
                                            </React.Fragment>)
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>)
                }
            </div >
            </div>
        </>
    )
}

export default Body;