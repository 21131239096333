import React, { useState, useEffect } from 'react';
import moment from 'moment';
import activityIcon from '../../../assets/activityIcon.svg'

const ActivityLogs = ({ activityDetail }) => {
    const [activity, setActivity] = useState([]);
    useEffect(() => {
        setActivity(activityDetail || []);
    }, [activityDetail]);

    // const [events, setEvents] = useState([
    //     { id: 1, title: 'Order Manifested by John', date: '11 Aug 2024', time: '13:01:32' },
    //     { id: 2, title: 'Order Printed by John', date: '11 Aug 2024', time: '13:01:32' },
    //     { id: 3, title: 'Order labelled by John', date: '11 Aug 2024', time: '13:01:32' },
    //     { id: 3, title: 'Order confirmation email sent', date: '11 Aug 2024', time: '13:01:32' },
    // ]);

    return (
        <>
            <div className="card activity-card">
                <div className='card-header'>
                    <div className='displayFlex'>
                        <span className='card-icons'>
                            <img src={activityIcon} alt='activityIcon' />
                        </span>
                        <h5 className='orderDetailTitle'>Activity logs</h5>
                    </div>
                </div>
                <div className='card-body'>
                    <div className="timeline">
                        {activity?.map((event) => (
                            <div key={event.id} className="timeline-event">
                                <div className="timeline-circle"></div>
                                <div className="timeline-content">
                                    <div className="timeline-title">{event.activity}</div>
                                    <div className="timeline-date">{moment(event.date).format('DD MMM YYYY')} {moment(event.time).format('hh:mm:ss')}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivityLogs
