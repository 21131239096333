export const filterOptions = [
    {
        "title": "Payment Type",
        "label": [
            {
                "id": 1,
                "menu": 'COD',
                "value": "COD",
                "key": "payment_type"

            },
            {
                "id": 2,
                "menu": 'Prepaid',
                "value": "Pre-paid",
                "key": "payment_type"
            },
        ]
    },
    {
        "title": "Not Serviceable Orders",
        "label": [
            {
                "id": 1,
                "menu": 'Not Serviceable',
                value: "NotServiceable",
                key: "notServiceable",

            }
        ]
    }
]

export const sortBy = [
    {
        "title": "Sort By",
        "label": [
            {
                "id": 1,
                "menu": 'New to Old',
                "value": "N",
                "key": "sortBy"

            },
            {
                "id": 2,
                "menu": 'Old to New',
                "value": "O",
                "key": "sortBy"
            },
            {
                "id": 1,
                "menu": 'Ascending',
                "value": "A",
                "key": "sortBy"

            },
            {
                "id": 2,
                "menu": 'Descending',
                "value": "D",
                "key": "sortBy"
            },
        ]
    },
]

export const inventoryCount = [
    {
        "title": "Inventory Count",
        "label": [
            {
                "id": 1,
                "menu": 'Less than 5',
                "value": "5",
                "key": "inventoryCount"

            },
            {
                "id": 2,
                "menu": 'Less than 10',
                "value": "10",
                "key": "inventoryCount"
            },
            {
                "id": 1,
                "menu": 'Less than 15',
                "value": "15",
                "key": "inventoryCount"

            },
            {
                "id": 2,
                "menu": 'Less than 20',
                "value": "20",
                "key": "inventoryCount"
            },
        ]
    },
]