import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from "react-bootstrap";

const OrderDetailsModal = ({ item, show, handleClose, handleSave, options }) => {   
    const [customerData, setCustomerData] = useState({});
    const [selectedAddress, setSelectedAddress] = useState("");

    useEffect(()=>{
        if(show === 'customerEdit'){
            const shipping_address =JSON.parse(item.shipping_address);
            setCustomerData({
                first_name: shipping_address?.first_name,
                last_name: shipping_address?.last_name,
                phone: shipping_address?.phone,
                email:item?.customer_email,
                address1: shipping_address?.address1,
                address2: shipping_address?.address2,
                city: shipping_address?.city,
                province: shipping_address?.province,
                country: shipping_address?.country,
                zip: shipping_address?.zip
            })
        }  
    },[])
    return (<>

        <Modal
            size='sm'
            show={show === 'orderEdit'}
            onHide={handleClose}
            centered
            scrollable
        >

            <div className='modal-content modal-md'>
                <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="modal-title">Edit Order details</h5>
                </div>
                <div className="modal-body modal-md">
                    <label className='userLabel mb-4'>Pickup Address</label>

                    <div className='row'>
                        <div className='col-md-12'>
                            <select className='call-status form-select mb-3'
                            onChange={(e)=>setSelectedAddress(e.target.value)}
                            >
                                <option value="">Select</option>
                                {
                                    options?.map((i,k)=>{
                                        return <option value={JSON.stringify(i)} key ={k}>{i.name}</option>
                                    })
                                }
                            </select>
                        </div>

                    </div>


                </div>

            </div>

            <div className="modal-footer">

                <div className="col-12 mb-4 d-flex">
                    <button className="btn btn-sm w-100 cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm w-100 btn-primary" disabled={selectedAddress === ""} type="submit" onClick={()=>handleSave(selectedAddress)} >Apply</button>

                </div>

            </div>

        </Modal>

        <Modal
            size='md'
            show={show === 'customerEdit'}
            onHide={handleClose}
            centered
            scrollable
        >

            <div className='modal-content modal-md'>
                <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="modal-title">Edit Customer details</h5>
                </div>
                <div className="modal-body modal-md">


                    <div className='row'>
                        <div className='col-md-6'>
                            <label className='userLabel'>Name<sup color='red'>*</sup></label>
                            <input type='text' name="first_name" value={customerData.first_name} onChange={(e) => setCustomerData({ ...customerData, first_name: e.target.value })} className='form-control' />
                        </div>
                        <div className='col-md-6'>
                            <label className='userLabel'>Last Name<sup color='red'>*</sup></label>
                            <input type='text' name="last_name" value={customerData.last_name} onChange={(e) => setCustomerData({ ...customerData, last_name: e.target.value })} className='form-control' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label className='userLabel'>Contact No.<sup color='red'>*</sup></label>
                            <input type='text' name="phone" value={customerData.phone} onChange={(e) => setCustomerData({ ...customerData, phone: e.target.value })} className='form-control' />
                        </div>
                        <div className='col-md-6'>
                            <label className='userLabel'>Email<sup color='red'>*</sup></label>
                            <input type='text' name="email" value={customerData.email} onChange={(e) => setCustomerData({ ...customerData, email: e.target.value })} className='form-control' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label className='userLabel'>Address 1<sup color='red'>*</sup></label>
                            <input type='text' name="address1" value={customerData.address1} onChange={(e) => setCustomerData({ ...customerData, address1: e.target.value })} className='form-control' />
                        </div>
                        <div className='col-md-6'>
                            <label className='userLabel'>Address 2</label>
                            <input type='text' name="address2" value={customerData.address2} onChange={(e) => setCustomerData({ ...customerData, address2: e.target.value })} className='form-control' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label className='userLabel'>city<sup color='red'>*</sup></label>
                            <input type='text' name="city" value={customerData.city} onChange={(e) => setCustomerData({ ...customerData, city: e.target.value })} className='form-control' />
                        </div>
                        <div className='col-md-6'>
                            <label className='userLabel'>State<sup color='red'>*</sup></label>
                            <input type='text' name="province" value={customerData.province} onChange={(e) => setCustomerData({ ...customerData, province: e.target.value })} className='form-control' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label className='userLabel'>country<sup color='red'>*</sup></label>
                            <input type='text' name="phone" value={customerData.country} onChange={(e) => setCustomerData({ ...customerData, country: e.target.value })} className='form-control' />
                        </div>
                        <div className='col-md-6'>
                            <label className='userLabel'>Zip<sup color='red'>*</sup></label>
                            <input type='text' name="phone" value={customerData.zip} onChange={(e) => setCustomerData({ ...customerData, zip: e.target.value })} className='form-control' />
                        </div>
                    </div>
                </div>

            </div>

            <div className="modal-footer">

                <div className="col-12 mb-4 d-flex">
                    <button className="btn btn-sm w-100 cancel-btn" type="button" onClick={handleClose}>Cancel</button> &nbsp;&nbsp;
                    <button className="btn btn-sm w-100 btn-primary" disabled={customerData.first_name === "" || customerData.last_name === "" || customerData.phone === "" || customerData.email === "" || customerData.address1 === "" || customerData.city === "" || customerData.province === "" || customerData.country === "" || customerData.zip === ""} type="submit" onClick={()=>handleSave(customerData)} >Apply</button>

                </div>

            </div>

        </Modal>
    </>)

}

export default OrderDetailsModal;