import React, { useEffect, useState } from 'react';
import backArrow from '../../../../assets/backArrow.svg';
import { Link, useNavigate } from 'react-router-dom';
import '../staff.scss'
import { useSelector } from 'react-redux';
import instance from '../../../../helpers/axiosInstance';
import { commonServiceMethod } from '../../../../helpers/helper';
import SuccessToast from '../../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import Loader from '../../../global/loader';

export default function Body() {
  const navigate = useNavigate();
  const userClaim = useSelector((state) => state.user);
  const [moduleList, setModuleList] = useState([]);
  const [userDetails, setUserDetails] = useState({
    "userName": "",
    "phoneNumber": "",
    "firstName": "",
    "lastName": "",
    "roleId": "",
    "modules": []
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (userClaim.token !== "") {
      getUserRoleLsit();
    }
  }, [userClaim]);

  const updateVal = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
  }
  const updateCheckVal = (e) => {
    e.target.checked ? setUserDetails({ ...userDetails, "modules": _.uniq([...userDetails.modules, ...e.target.value]) }) : setUserDetails({ ...userDetails, "modules": _.without([...userDetails.modules], e.target.value) })
  }
  const getUserRoleLsit = () => {
    try {
      instance.get("app/getModuleList").then((res) => {

        if (res.status === 200 && Array.isArray(res.data)) {
          setModuleList(res.data);
        }

      }).catch(e => {
        console.error(e);
        setModuleList([]);
      })
    }
    catch (e) {
      console.error(e);
      setModuleList([]);
    }
  }

  const save = async (e) => {
    e.preventDefault();
    const config = {};
    console.log(userDetails);
    userDetails.modules = userDetails.modules.map(i=>parseInt(i));
    try {
      commonServiceMethod(`app/createuser`, "post", userDetails, config, setLoader, (res) => {
        if (res.status === 200) {
          toast(<SuccessToast body={'user Created successfully'} />, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
          });          
          navigate('/settings/staff-managemanet');
        }
      });
    }
    catch (e) {
      console.error(e);
      setModuleList([]);
    }
  }
  return (
    <>
      <Loader showHide={loader} />
      <div className="parent-div">
      <div className='container mt-3 mb-3' >

        <div className='row'>
          <div className='col-md-9'>
            <Link to={'/settings/staff-managemanet'}>
              <img src={backArrow} /> Back
            </Link>

          </div>
          <div className='col-md-3 d-flex justify-content-end'>

          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-8'>
            <span className='page-header-text mb-2'>Create Staff</span>
            {/* <p className='details'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p> */}
          </div>

        </div>

        <div className='mt-2'>
          <form onSubmit={save}>
            <div className='col-8 mb-3' >
              <div className='card'>
                <div className='card-body'>
                  <div className='row'>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01" className="form-label">First name</label>
                      <input value={setUserDetails.firstName} name="firstName" onChange={updateVal} type="text" className="form-control" id="validationCustom01" required />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01" className="form-label">Last name</label>
                      <input value={setUserDetails.lastName} name="lastName" onChange={updateVal} type="text" className="form-control" id="validationCustom01" required />
                    </div>
                    {/* <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom02" className="form-label">User ID</label>
                      <input value={setUserDetails.phoneNumber} name="phoneNumber" onChange={updateVal} type="text" className="form-control" id="validationCustom02" readOnly />
                    </div> */}

                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom02" className="form-label">Email Address</label>
                      <input value={setUserDetails.userName} name="userName" onChange={updateVal} type="text" className="form-control" id="validationCustom02" required />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom02" className="form-label">Phone Number</label>
                      <input value={setUserDetails.phoneNumber} name="phoneNumber" onChange={updateVal} type="text" className="form-control" id="validationCustom02" required />
                    </div>

                    <div className='col-md-9 mb-3'>
                      <label htmlFor="validationCustom02" className="form-label">Role</label>
                      <div className='row'>
                        <div className='col-md-3'>
                          <div className="form-check">
                            <input name="roleId" onChange={updateVal} value={1} type="radio" className="form-check-input" id="administratorCheck" required />
                            <label className="form-check-label  checkboxLabel" htmlFor="administratorCheck">Administrator</label>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className="form-check mb-3">
                            <input name="roleId" onChange={updateVal} value={2} type="radio" className="form-check-input" id="stafCheck" required />
                            <label className="form-check-label  checkboxLabel" htmlFor="stafCheck">Staff</label>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-8  mb-3' >
              <div className='card'>
                <div className='card-header'>

                  <div className='displayFlex'>
                    <span className='card-icons'></span>
                    <h5 className='cardTitle'>Permissions</h5>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='cardBodyInner'>
                    <div className='row'>
                      {
                        moduleList.map((i, index) => (
                          <div key={index} className='col-md-5'>
                            <div className="form-check mb-3">
                              <input onChange={updateCheckVal} value={i.id} type="checkbox" className="form-check-input" id={'validationFormCheck' + index} />
                              <label className="form-check-label  checkboxLabel" htmlFor={'validationFormCheck' + index}>{i.module_name}</label>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-4">
              <button className="btn btn-lg btn-primary saveButton" type="submit">Save</button>
              <button className="btn btn-lg btn-secondary cancelButton" type="button">Cancel</button>
            </div>
          </form>
        </div>

      </div>
      </div>
    </>
  )
}
