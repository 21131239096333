import React from "react";
import Sidebar from "./sideBar/Sidebar";
import OrdersTabMenu from "./ordersTabMenu/OrdersTabMenu";
import Body from './body';
import Mycomponent from '../../CheckIdel'
import ReturnsTabMenu from "./returnsTabMenu/returnsTabMenu";

const PageLayout = ({ tabMenu, body, selectedItem , quickActionClick, isQuick, returnTabMenu}) => {
console.log("home page")
    return (
        <>
            <Mycomponent />
            <div className="main-container" style={{ display: "flex", backgroundColor: '#F9FAFB' }}>
                <Sidebar />
                {tabMenu && <OrdersTabMenu body={body} isQuick={isQuick} quickActionClick={quickActionClick} selectedItem={selectedItem} />}
                {returnTabMenu && <ReturnsTabMenu body={body} isQuick={isQuick} quickActionClick={quickActionClick} selectedItem={selectedItem} />}
                {!tabMenu && !returnTabMenu && <Body body={body} />}
            </div>

        </>
    )
}

export default PageLayout;