import React, {
    useState,
    useEffect,
    useRef,
    useCallback
} from 'react';
import './style.scss';
import { useSelector } from 'react-redux';
import instance from '../../../../helpers/axiosInstance';
import { Link } from 'react-router-dom';
import Search from '../../../../assets/Iconsearch.svg';
import Calandar from '../../../../assets/Icondate.svg';
import ExportIcon from '../../../../assets/Iconexport.svg';
import AllFilter from '../../../../assets/Iconall-filter.svg'
import copy from '../../../../assets/copy.png';
import { Popup } from "../../../global/popupModal/BottomDialog";
import DataGridPagination from '../../../global/dataGridPagination';
import Moment from 'react-moment';
import moment from 'moment';
// import shopify from '../../../../assets/shopify.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import actionButton from '../../../../assets/action-button.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import FilterMenu from "../../../global/filterComponent/FilterMenu";
import CallStatus from '../../../global/popupModal/CallStatus';
import { filterOptions } from './filterObj';
import { Slide, toast } from "react-toastify";
import SuccessToast from '../../../customToast/SuccessToast';
import Loader from '../../../global/loader';
import { commonServiceMethod } from '../../../../helpers/helper';
import ErrorToast from '../../../customToast/ErrorToast';
// import { async } from 'q';

const ReturnPickupScheduledBody = () => {
    const userClaim = useSelector((state) => state.user);
    const gridRef = useRef();
    const [rowData, setRowData] = useState();
    const [originalData, setOriginalData] = useState({});
    const [selectedObject, setSelectedObject] = useState([]);
    const [open, setOpen] = useState(false);

    // pagination and Filtr variables 
    const per_page = 50;
    const [pageNo, setPageNo] = useState(1);
    // moment().subtract(30, 'days').toDate(), moment().toDate()
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchText, setSearchText] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [loader, setLoader] = useState(false);
    // const [callRowID, setCallRowID] = useState('');
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })
    const [searchParam, setSearchParam] = useState("");
    const [columnData, setComlumnData] = useState([]);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    };


    let columnDefsWithCall = [
        {
            headerName: 'Request ID & Date',
            field: 'order_name',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            wrapText: true,
            autoHeight: true,
            width: 200,
            cellClass: "check-box-container order-number",
            cellRenderer: (param) => {
                let productNames = param.data.orderProducts;
                return (<div>
                    <Link className='grid-link' to={`/returns/all-order/order-details/${btoa(param.data?.order_id)}`}>{param.value}</Link>
                    <p className='font-insetMedium order-date'><Moment format={"DD MMM YYYY hh:mm A"}>{param.data?.order_created_at}</Moment> </p>
                    {/* <p className='logo'><img alt='' src={shopify} /></p> */}
                    <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={
                        <Popover id="popover-basic" className='z-index-2000 produc-list'>
                            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
                            <Popover.Body>
                                {productNames.map((i, j) => <div key={j} className='product-list-container'>
                                    <p className='p-name bold'><b>{i.product_name}</b></p>
                                    <p className='p-sku'>SKU <b>{i.sku}</b></p>
                                    <p className='p-qty'>QTY <b>{i.quantity}</b></p>
                                </div>)}
                            </Popover.Body>
                        </Popover>
                    }>
                        <p className='viewMore'>
                            View Products
                        </p>
                    </OverlayTrigger>
                </div>)
            }
        },
        {
            headerName: 'Customer',
            width: 200,
            field: 'shipping_address',
            wrapText: true,
            autoHeight: true,
            cellClass: "d-flex-center customers",
            cellRenderer(params) {
                let address = params.data?.shipping_address;
                return (
                    <div className="address-wrap">
                        <p className="customer-name">
                            {params.data?.customer_name}
                        </p>
                        <p className="address">
                            {params.data?.customer_email}
                        </p>
                        <p className="address">
                            {address.phone ? address.phone : ''}
                        </p>
                        <p className="address">
                            {address.city ? address.city : ''} - {address.zip ? address.zip : ''}
                        </p>
                    </div>
                )
            }
        },
        {
            headerName: 'Product Details',
            field: 'total_price',
            width: 160,
            cellClass: "d-flex-center",
            cellRenderer(params) {
                let payment_mode = "";
                let payment_status = "";
                if (params.data?.gateway === "Cash on Delivery (COD)") {
                    payment_mode = "COD";
                    payment_status = "info";
                } else {
                    payment_mode = "Pre-paid";
                    payment_status = "success";
                }
                return (
                    <div className='address-wrap'>
                        <p className="price_tag">{"Rs. " + params.value}</p>
                        <p className="price_tag">
                            <span className={payment_status}>{payment_mode}</span>
                        </p>
                    </div>
                )
            }
        },
        {
            headerName: 'Return Reason',
            field: 'return_reason',
            width: 155,
            wrapText: true,
            autoHeight: true,
            cellClass: "return-reason",
            cellRenderer(params) {
                return (<p>{params.value}</p>)
            }
        },
        {
            headerName: 'Carrier',
            field: 'manageShipments',
            width: 155,
            wrapText: true,
            autoHeight: true,
            cellClass: "carrier-data",
            cellRenderer(param) {
                return (<>
                    {param.value.length === 0 || (param.value[0].tracking_number === null || param.value[0].tracking_number?.trim() === "") ? "-" : <>
                        <p className='carrier'>{param.value[0].ecom_name}</p><span className={`delivery-id`}>#{param.value[0].tracking_number || ""} <img onClick={() => {
                            navigator.clipboard.writeText(param.value[0].tracking_number);
                            toast(<SuccessToast body={'Coppied'} />, {
                                transition: Slide,
                                hideProgressBar: true,
                                autoClose: 2000,
                            });
                        }}
                            alt='' src={copy} /></span>
                    </>}
                </>)
            }
        },
        {
            headerName: 'Status',
            field: 'app_order_status',
            width: 160,
            wrapText: true,
            autoHeight: true,
            cellClass: "app-order-status",
            cellRenderer: (params) => {
                let order_status_class = "";
                if (params.value.toLowerCase() === "cancel") {
                    order_status_class = "cancel";
                } else if (params.value.toLowerCase() === "refunded") {
                    order_status_class = "refund";
                } else if (params.value.toLowerCase() === "delivered") {
                    order_status_class = "delivered";
                } else {
                    order_status_class = "";
                }
                return (<span className={`default-status ${order_status_class}`}>
                    {params.value}
                </span>)
            }
        },
        {
            width: 100,
            field: '',
            wrapText: true,
            autoHeight: true,
            headerName: 'Actions',
            cellRenderer(param) {
                return (
                    <div className="more-action">
                        <OverlayTrigger className="action-button" rootClose={true} trigger="click" placement="bottom" overlay={
                            <Popover id="popover-basic" className='z-index-2000 action-buttons'>
                                <Popover.Body>
                                    {/* <button className='print-invoice' onClick={() => showModal('courierPartner', [param?.data], assignCourier)}>Assign Courier</button> */}
                                    <button className='print-invoice' onClick={() => printInvoiceMethod([param?.data])}>Print Invoice</button>
                                </Popover.Body>
                            </Popover>
                        }>
                            <img alt='Action' src={actionButton} />
                        </OverlayTrigger>

                    </div>
                )
            }
        },
    ];
    useEffect(() => {
        if (userClaim.token !== "") {
            getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
            setComlumnData([...columnDefsWithCall]);
        }
    }, [userClaim]);

    const onClearFilter = () => {
        getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), "");
    }
    // const showModal = (popupName, data, callBackMethod) => {

    //     // console.log(string, value);
    //     // setCallDetails(string);
    //     // setCallRowID(value)
    //     setPopupData({ popupName, data, callBackMethod })
    // }

    const closeModal = () => {
        // setCallDetails('');
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }

    const getData = async (
        page = 1,
        startDate,
        endDate,
        searchText,
        perams = "&payment_type=&fulfillment=&order_status=return_scheduled&carrier=&type=&tag="
    ) => {
        var rowData = [];
        if(searchText !== ""){
            if(/^\d{10}$/.test(searchText)){
                searchText+='&orderDetails=m'
            }
            else if(searchText.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )){
                searchText+='&orderDetails=e'
            }
            else{
                searchText+='&orderDetails='
            }
        }
        setLoader(true);
        try {
            await instance.get(`app/order/getOrder?per_page=${per_page}&page=${page}&order_date=${startDate}&end_order_date=${endDate}&search=${searchText}${perams}`).then((res) => {

                console.log(res);
                if (res.status === 200 && Array.isArray(res?.data?.orders)) {
                    setRowData(res?.data?.orders || []);
                    setOriginalData(res?.data)
                }
                setLoader(false);
            }).catch(e => {
                setLoader(false);
                console.error(e);
                setRowData([]);
            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            setRowData([]);
        }
        return rowData;
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
        getData(
            event.selected + 1,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onCellClick = (e) => {
        let selectedITem = e.api.getSelectedRows();
        console.log(selectedITem.length > 0)
        // setSelectedObject((selectedObject) => [...selectedObject]);
        selectedITem.length > 0 ? (setOpen(true), setSelectedObject([...selectedITem])) : (setOpen(false), setSelectedObject([]));
    }
    const dateChangeEvent = (date) => {
        setStartDate(date.startDate.format('YYYY-MM-DD'));
        setEndDate(date.endDate.format('YYYY-MM-DD'));
        getData(
            pageNo,
            date.startDate.format('YYYY-MM-DD'),
            date.endDate.format('YYYY-MM-DD'),
            searchText
        )
    }
    const searchTextChangeEvent = (e) => {
        e.preventDefault();
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchText
        );
    }

    const onBtExport = useCallback((
        page = 1,
        startDate,
        endDate,
        searchText,
        perams = "&orderDetails=&payment_type=&fulfillment=&order_status=return_scheduled&carrier=&type=&tag=") => {
        var objdata = {};
        const config = {};
        objdata.order_id = selectedObject.map((i) => i.order_id).join();
        commonServiceMethod(`app/order/export?per_page=${per_page}&page=${page}&order_date=${startDate}&end_order_date=${endDate}&search=${searchText}${perams}`, "get", objdata, config, setLoader, (res) => {
            console.log("res", res)
            const downloadUrl = window.URL.createObjectURL(
                new Blob([res.data])
            );
            console.log(downloadUrl)
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", "file.csv"); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            closeExportPopup();
        }, () => {
            toast(<ErrorToast body={'Something went wrong, please try again'} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        });
    }, []);

    const onApplyFilter = (selectionOption, searchTextval = "") => {
        console.log(selectionOption)
        let url = "";
        Object.keys(selectionOption).map(i => {

            url += `&${i}=${selectionOption[i]}`
        })
        setSearchParam(url);
        getData(
            pageNo,
            moment(startDate).format('YYYY-MM-DD'),
            moment(endDate).format('YYYY-MM-DD'),
            searchTextval || searchText,
            url
        );
        toggleDropdown();
    }

    const closeExportPopup = () => {
        setOpen(false);
        setSelectedObject([])
        gridRef.current.api.deselectAll();
    }
    const exportMethod = () => {
        var objdata = {};
        const config = {};
        objdata.order_id = selectedObject.map((i) => i.order_id).join();
        commonServiceMethod("app/order/export", "post", objdata, config, setLoader, (res) => {
            console.log("res", res)
            const downloadUrl = window.URL.createObjectURL(
                new Blob([res.data])
            );
            console.log(downloadUrl)
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", "file.csv"); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            closeExportPopup();
        }, () => {
            toast(<ErrorToast body={'Something went wrong, please try again'} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        });
    }
    const printInvoiceMethod = (obj = []) => {
        obj = typeof obj.length === 'undefined' ? selectedObject : obj;
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        var objdata = {};
        objdata.order_id = obj.map((i) => i.order_id).join();
        commonServiceMethod("app/pritnlabel", "post", objdata, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
            closeExportPopup();
        }, () => {
            toast(<ErrorToast body={'Something went wrong, please try again'} />, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 2000,
            });
        });
    }

    // const assignCourier = (item, selectedItem, selectedService) => {
    //     console.log(item, selectedItem, selectedService)
    //     // let orderids=[];
    //     // let ids = item.map(i => {
    //     //     orderids.push(i.order_id)
    //     //     return {
    //     //         "order_id": i.order_id.toString(),
    //     //         "weight": "100",
    //     //         "width": "10",
    //     //         "height": "10",
    //     //         "length": "10"
    //     //     }
    //     // })
    //     // let obj = {
    //     //     "service": selectedService,
    //     //     "location_id": selectedItem.id.toString(),
    //     //     "order_ids":orderids.toString(),
    //     //     "orderdata": ids
    //     // }
    //     let ob = {
    //         "courier": selectedService,
    //         "orderId": item.order_id
    //     }
    //     const config = {};
    //     commonServiceMethod("app/assign/order", "post", ob, config, setLoader, (res) => {
    //         if (res.data.status_code === 200) {
    //             toast(<SuccessToast body={res.data?.message} />, {
    //                 transition: Slide,
    //                 hideProgressBar: true,
    //                 autoClose: 2000,
    //             });
    //             getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
    //             closeModal();
    //             setOpen(false);
    //         }
    //         else {
    //             toast(<ErrorToast body={'Something went wrong try again'} />, {
    //                 transition: Slide,
    //                 hideProgressBar: true,
    //                 autoClose: 2000,
    //             });
    //         }
    //     }, () => {
    //         toast(<ErrorToast body={'Something went wrong, please try again'} />, {
    //             transition: Slide,
    //             hideProgressBar: true,
    //             autoClose: 2000,
    //         });
    //     });
    // }
    // const gridReadyEvent = () =>{
    //     let allColumnIds = [];
    //     gridRef.current.columnApi.getColumns().forEach((column) => {
    //         allColumnIds.push(column.getId());
    //     });
    //     gridRef.current.columnApi.autoSizeColumns(allColumnIds, true);
    // }
    return (
        <>
            <Loader showHide={loader} />
            {open ? <Popup exportMethod={exportMethod} printInvoiceMethod={printInvoiceMethod} selecteObject={selectedObject} closePopup={() => { closeExportPopup(); }} /> : null}
            <div className='row mb-2 mt-4 filter-section'>
                <div className="filter-left-container">
                    <div className="form-group">
                        <form onSubmit={searchTextChangeEvent}>
                            <div style={{ position: 'relative' }}>
                                <span className="icon"><img alt='' src={Search} /></span>
                            </div>
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search"
                                className='form-control inputWithIcon'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </form>
                    </div>
                </div>
                <div className='filter-right-container'>
                    <div className="form-group datepicker-class">
                        <div style={{ position: 'relative' }}>
                            <span className="icon"><img alt='' src={Calandar} /></span>
                        </div>
                        <DateRangePicker
                        className="datepicker-custom"
                            initialSettings={
                                {
                                    startDate: startDate,
                                    endDate: endDate,
                                    maxDate: moment().toDate(),
                                    showDropdowns: true,
                                    locale: {
                                        format: 'MMM DD, YYYY',
                                    },
                                    ranges: {
                                        Today: [moment().toDate(), moment().toDate()],
                                        Yesterday: [
                                            moment().subtract(1, 'days').toDate(),
                                            moment().subtract(1, 'days').toDate(),
                                        ],
                                        'Last 7 Days': [
                                            moment().subtract(6, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Last 30 Days': [
                                            moment().subtract(30, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'This Month': [
                                            moment().startOf('month').toDate(),
                                            moment().endOf('month').toDate(),
                                        ],
                                        'Last Month': [
                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                        ],
                                    },
                                }}
                            onApply={(e, val) => dateChangeEvent(val, e)}
                        >
                            <input type="text" className="form-control datepicker-range" />
                        </DateRangePicker>
                    </div>
                    <div className='export-btn'>
                        <button type='button' onClick={() => onBtExport(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText, searchParam)} className='btn btn-sm w-100 filter-btn' style={{ color: '#7F56D9' }}><img alt='' style={{ width: 15 }} src={ExportIcon} /> Export</button>
                    </div>
                    <div className='dropdown-dialog-parent'>
                        <div className={` dropdown-dialog ${isOpen ? 'open' : ''}`}>
                            <button type='button' className='btn btn-sm  filter-btn' onClick={toggleDropdown} ><img alt='' style={{ width: 15 }} src={AllFilter} /> All Filters</button>
                            {isOpen && (
                                <>
                                    <FilterMenu filterOptions={filterOptions} onClear={onClearFilter} onApply={onApplyFilter}></FilterMenu>
                                </>
                            )}
                        </div>
                    </div>
                </div>                
            </div >
            <DataGridPagination
                rowData={rowData}
                columnDefs={columnData}
                onSelectionChanged={onCellClick}
                gridref={gridRef}
                rowSelection={"multiple"}
                domLayout="autoHeight"
                onPageChange={handlePageClick}
                pageRangeDisplayed={originalData.per_page}
                pageCount={originalData.count}
            />
            {popupData.popupName !== "" &&
                <CallStatus
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closeModal}
                />
            }

        </>
    );
}

export default ReturnPickupScheduledBody;

