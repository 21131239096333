import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Truck from '../../../assets/truck.svg';
import ProductDetails from "./productDetails";
import OrderDetail from "./orderDetails";
import CustomerDetails from "./customerDetails";
import PackageDetails from "./packageDetails";
import Notes from "./notes";
import CallStatus from "./callStatus";
import Tags from "./tags";
import ActivityLogs from "./activityLogs";
import { useSelector } from 'react-redux';
import { checkAccess, commonServiceMethod } from "../../../helpers/helper";
import Loader from "../../global/loader";
import { OverlayTrigger, Popover } from "react-bootstrap";
import SuccessToast from "../../customToast/SuccessToast";
import { Slide, toast } from "react-toastify";
import Cancelpopup from '../../global/popupModal/CallStatus';
import ErrorToast from "../../customToast/ErrorToast";
import { Link, useNavigate } from "react-router-dom";
import backArrow from '../../../assets/backArrow.svg';

const OrderDetails = () => {
    const navigate = useNavigate();
    const userClaim = useSelector((state) => state.user);
    let { id, page } = useParams();
    id = atob(id);
    console.log(id);
    const [loader, setLoader] = useState(false);
    const [orderDetail, setOrderDetail] = useState({});
    const [activityDetail, setActivityDetail] = useState([]);
    const [refreshData, setRefreshData] = useState(0);
    const [popupData, setPopupData] = useState({ popupName: "", data: "", callBackMethod: null })

    useEffect(() => {
        // setRefreshData(0);
        if (userClaim.token !== "") {
            getDetailPage();
            getActivityDetail();
        }
        console.log(refreshData)
    }, [userClaim, refreshData]);

    const getDetailPage = () => {
        commonServiceMethod(`app/order/${id}`, "get", {}, {}, setLoader, (res) => {
            if (res.data?.status_code === 200) {
                setOrderDetail(res.data?.orders)
            }
        })
    }
    const getActivityDetail = () => {
        commonServiceMethod(`app/order/${id}/activity`, "get", {}, {}, setLoader, (res) => {
            if (res.status === 200) {
                setActivityDetail(res.data);
            }
        })
    }
    const printInvoiceMethod = () => {
        const config = {
            headers: { Accept: "application/pdf" },
            responseType: "blob",
        };
        var objdata = {};
        objdata.order_id = id;
        commonServiceMethod("app/pritnlabel", "post", objdata, config, setLoader, (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
            closeExportPopup();
        });
    }

    const cancelOrder = (obj, item) => {
        const config = {};
        commonServiceMethod("app/orders/cancel", "post", obj, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={'This order is ' + item.order_name + ' cancelled'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                getData(pageNo, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchText);
                closeModal();
            }
        });
    }
    const showModal = (popupName, data, callBackMethod) => {
        setPopupData({ popupName, data, callBackMethod })
    }
    const closeModal = () => {
        setPopupData({ popupName: "", data: "", callBackMethod: null });
    }
    const assignCourier = (item, selectedItem, selectedService) => {
        console.log(item, selectedItem, selectedService);
        let obj = {
            "service": selectedService,
            "location_id": selectedItem.id.toString(),
            "order_ids": item.order_id.toString(),
            "orderdata": [{
                "order_id": item.order_id.toString(),
                "weight": "100",
                "width": "10",
                "height": "10",
                "length": "10"
            }]
        }
        console.log(obj)
        const config = {};
        commonServiceMethod("app/createLable/bulk", "post", obj, config, setLoader, (res) => {
            if (res.data.status_code === 200) {
                toast(<SuccessToast body={res.data?.message} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                closeModal();
            }
            else {
                toast(<ErrorToast body={'Something went wrong try again'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            }
        },
            () => {
                toast(<ErrorToast body={'Something went wrong try again'} />, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            });
    }
    return (
        <>
            <Loader showHide={loader} />
            <div className="parent-div order-detail-page">
                <div className="row">
                    <div className="col-md-8">
                        <div className="topview mb-4">
                            <div className="col-md-3">
                            <Link className="back-button" onClick={()=>navigate(-1)}>
                                <img src={backArrow} /> Back
                            </Link>
                                <h4 className='fw-600' >{orderDetail?.order?.order_name}</h4>
                            </div>
                            <div className="col-md-9">
                                <div className="d-flex justify-content-end space-between">
                                    {(page === "all-order" || page === "new-order") && <button className="btn btn-sm filter-btn" onClick={() => showModal('courierPartner', orderDetail?.order, assignCourier)}><img src={Truck} className="btnWithImg" />&nbsp;Ship Now</button>}
                                    {(page === "all-order" || page === "new-order" || page === 'ready-to-ship') && <OverlayTrigger className="action-button" rootClose={true} trigger="click" placement="bottom" overlay={
                                        <Popover id="popover-basic" className='z-index-2000 action-buttons'>
                                            <Popover.Body>
                                                {(page === 'ready-to-ship') && <button className='print-invoice' onClick={() => printInvoiceMethod([orderDetail])}>Print Invoice</button>}
                                                {(page === 'ready-to-ship') && <button className='print-invoice' onClick={() => printInvoiceMethod([orderDetail])}>Print Label</button>}
                                                {(page === "all-order" || page === "new-order" || page === 'ready-to-ship') && <button className='cancel-order' onClick={() => showModal('cancelPopup', orderDetail?.order, cancelOrder)}>Cancel Order</button>}
                                            </Popover.Body>
                                        </Popover>
                                    }>
                                        <button className="btn btn-sm filter-btn">...</button>
                                    </OverlayTrigger>
                                    }
                                </div>
                            </div>
                        </div>
                        <ProductDetails orderDetail={orderDetail} />
                        <OrderDetail orderDetail={orderDetail} setRefreshData={setRefreshData} refreshData={refreshData} />
                        <CustomerDetails orderDetail={orderDetail} setRefreshData={setRefreshData} refreshData={refreshData} />
                        <PackageDetails orderDetail={orderDetail} setRefreshData={setRefreshData} refreshData={refreshData} />
                    </div>
                    <div className="col-md-4" style={{ marginTop: '101px' }}>
                        <Notes orderDetail={orderDetail} setRefreshData={setRefreshData} refreshData={refreshData} />
                        {checkAccess(11) && <CallStatus orderDetail={orderDetail} setRefreshData={setRefreshData} refreshData={refreshData} />}
                        <Tags orderDetail={orderDetail} setRefreshData={setRefreshData} refreshData={refreshData} />
                        <ActivityLogs activityDetail={activityDetail} />
                    </div>
                </div>

            </div>
            {popupData.popupName !== "" &&
                <Cancelpopup
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closeModal}
                />
            }

            {popupData.popupName !== "" &&
                <CallStatus
                    item={popupData.data}
                    show={popupData.popupName}
                    handleSave={popupData.callBackMethod}
                    handleClose={closeModal}
                />
            }
        </>
    )
}
export default OrderDetails;