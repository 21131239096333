import React from 'react';
import PageLayout from '../../../global/pageLayout';
import ReturnsRequestBody from './body';

const ReturnREquest = () => {
    return (
        <div>
            <PageLayout tabMenu ={false} returnTabMenu={true} selectedItem="Return Requests" body={<ReturnsRequestBody />} />
        </div>
    )
}

export default ReturnREquest;