import React from 'react'
import PageLayout from '../../global/pageLayout';
import StaffBody from './Body';

export default function Index() {
    return (
        <>
            <PageLayout tabMenu={false} body={<StaffBody />} />
        </>
    )
}
