import React from 'react';
import PageLayout from '../../../global/pageLayout';
import HsnGstBody from './body'

function index() {
    return (
        <>
            <PageLayout tabMenu={false} body={<HsnGstBody />} />
        </>
    )
}

export default index
