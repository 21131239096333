import React, { useState, useEffect } from 'react';
import Loader from '../../global/loader';
import { commonServiceMethod } from '../../../helpers/helper';
import SuccessToast from '../../customToast/SuccessToast';
import { Slide, toast } from 'react-toastify';
import noteIcon from '../../../assets/notesIcon.svg'

const Notes = ({ orderDetail }) => {
    const [note, setNote] = useState('');
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setNote(orderDetail?.order?.note || '');
    }, [orderDetail]);

    const setCallStatus = async () => {
        let obj = { note: note };
        try {
            await commonServiceMethod(`app/order/${orderDetail?.order.order_id}/add_note`, "post", obj, {}, setLoader, (res) => {
                if (res.status === 200 && res?.data?.message === "Update order sucessfully") {
                    toast(<SuccessToast body={'Order Notes are saved'} />, {
                        transition: Slide,
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                }

            })
        }
        catch (e) {
            setLoader(false);
            console.error(e);
            // setRowData([]);
        }
    }
    return (
        <>
            <Loader showHide={loader} />
            <div className="card">
                <div className='card-header'>
                    <div className='displayFlex'>
                        <span className='card-icons'>
                            <img src={noteIcon} alt='noteIcon' />
                        </span>
                        <h5 className='orderDetailTitle'>Notes</h5>
                    </div>
                </div>
                <div className='card-body'>
                    <textarea className="form-control" value={note} onChange={(e) => setNote(e.target.value)} style={{ height: '120px' }}>
                    </textarea>
                </div>
                <div className='save-button'>
                    <button className='btn btn-primary' onClick={setCallStatus}>Save</button>
                </div>
            </div>
        </>
    )
}

export default Notes