import React from "react";
import { Link } from "react-router-dom";
import CloseIcon from '../../../assets/close -Icon.png';

export const Popup = ({ selecteObject, closePopup, exportMethod, printInvoiceMethod, cancelMethod }) => {
    console.log(selecteObject);
    return (
        <div className={`popup-container grid-checkbox-popuop`}>
            <div className="popup-body">
                <div className="left-container">
                    <span className="selected-item"> {selecteObject.length} items Seleted</span>
                </div>
                <div className="right-container">
                    <button type='button' onClick={exportMethod} className='btn btn-sm filter-btn'>Export</button>
                    <button type='button' onClick={printInvoiceMethod} className='btn btn-sm  filter-btn'>Print Invoice</button>
                    <button type='button' onClick={cancelMethod} className='btn btn-sm cancel-button filter-btn'>Cancel</button>
                    <Link to={''} className="close-icon" onClick={closePopup} > <img src={CloseIcon} /> </Link>
                </div>
            </div>
        </div>
    );
};