import React from 'react'
import { useParams } from "react-router";
import ActivityLogs from './activityLogs';
import Informations from './informations';
import LastPlacedOrder from './lastPlacedOrder';
import AllOrders from './allOrders';
import Tags from './tags';
import CustomerInformations from './customerInformations';

const Body = () => {
    let { id } = useParams();
    id = atob(id);
    console.log(id);
    return (
        <>
            <div className="parent-div">
                <div className="row">
                    <div className="col-md-9">
                        <div className="topview mb-4">
                            <div className="col-md-3">
                                <h4 className='fw-600' >{id}</h4>
                            </div>
                            <div className="col-md-9">
                                <div className="d-flex justify-content-end space-between">
                                    {/* <OverlayTrigger className="action-button" rootClose={true} trigger="click" placement="bottom" overlay={
                                        <Popover id="popover-basic" className='z-index-2000 action-buttons'>
                                            <Popover.Body>
                                                <button className='print-invoice' onClick={() => printInvoiceMethod([orderDetail])}>Print Invoice</button>
                                                <button className='cancel-order' onClick={() => showModal('cancelPopup', orderDetail?.order, cancelOrder)}>Cancel Order</button>
                                            </Popover.Body>
                                        </Popover>
                                    }>
                                        <button className="btn btn-sm filter-btn">...</button>
                                    </OverlayTrigger> */}
                                </div>
                            </div>
                        </div>
                        <Informations />
                        <LastPlacedOrder />
                        <AllOrders />
                    </div>
                    <div className="col-md-3" style={{ marginTop: '61px' }}>
                        <CustomerInformations />
                        <Tags />
                        <ActivityLogs />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Body
