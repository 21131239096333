import React, { useState, useEffect } from 'react'
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import Tag from '../../../../assets/tagIcon.svg'

const Tags = ({ tagsAll }) => {
    const [tags, setTags] = useState([]);
    // const [inputValue, setInputValue] = useState('');
    // const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    // const [showSuggestions, setShowSuggestions] = useState(false);
    // const inputRef = React.createRef();

    // const suggestions = [
    //     'Lable1',
    //     'Lable2',
    //     'Lable3',
    //     'Lable4',
    // ];
    useEffect(() => {
        setTags(tagsAll?.split(",") || [])
    }, [tagsAll])
    // const handleInputChange = (e) => {
    //     const value = e.target.value;
    //     setInputValue(value);

    //     const filtered = suggestions.filter((suggestion) =>
    //         suggestion.toLowerCase().includes(value.toLowerCase())
    //     );
    //     setFilteredSuggestions(filtered);
    //     setShowSuggestions(true);
    // };

    // const handleSuggestionClick = (suggestion) => {
    //     setShowSuggestions(false);
    //     setTags([...tags, suggestion.trim()]);
    //     setInputValue('');
    // };

    // const handleTagRemove = (tag) => {
    //     const updatedTags = tags.filter((t) => t !== tag);
    //     setTags(updatedTags);
    // };
    return (
        <>
            <div className="card">
                <div className='card-header'>
                    <div className='displayFlex'>
                        <span className='card-icons'>
                            <img src={Tag} alt='Tag' />
                        </span>
                        <h5 className='orderDetailTitle'>Tags</h5>
                    </div>
                </div>

                <div className='card-body'>
                    {/* <input
                        type="text"
                        placeholder="Add tags"
                        className='form-control mb-3'
                        value={inputValue}
                        onChange={handleInputChange}
                        ref={inputRef}
                    />
                    {showSuggestions && (
                        <ul className="suggestions">
                            {filteredSuggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )} */}
                    <div className='row'>

                        <div>
                            {tags.map((tag, index) => (
                                <>
                                    <Badge className='tagLabels' key={index}>
                                        <span>{tag}</span>&nbsp;&nbsp;
                                        <Link to={''} onClick={() => handleTagRemove(tag)}><i className='bi bi-x-lg'></i></Link>
                                    </Badge>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tags